import styled from 'styled-components/macro';
import { FormContainer } from 'components/Form/FormContainer.styled';
import { InputField } from 'components/InputField/InputField.styled';

export const PersonalSettings = styled.div`
  form {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    max-width: 1200px;

    .desktop-settings,
    .mobile-settings {
      ${InputField} .select__control {
        width: 130px;
      }
    }

    .desktop-settings {
      grid-column: 1;
      grid-row: 2;
    }

    .mobile-settings {
      grid-column: 2;
      grid-row: 2;
    }

    ${FormContainer} {
      max-width: unset;
    }

    @media screen and (max-width: 1400px) {
      .mobile-settings {
        grid-column: 1;
        grid-row: 3;
      }
    }

    @media screen and (max-width: 960px) {
      display: flex;
      flex-direction: column;
    }
  }
`;
