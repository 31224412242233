// style
import * as S from './FlashMessage.styled';

// icons
import { Info } from 'common/icons';

//hooks
import { FLASH_MESSAGE_TYPE } from 'common/constants';

export const FlashMessage = ({ children, type = FLASH_MESSAGE_TYPE.INFO }) => {
  return (
    <S.FlashMessage type={type}>
      <Info />
      <span>{children}</span>
    </S.FlashMessage>
  );
};
