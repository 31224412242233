import { forwardRef, useContext, useRef } from 'react';
import { components } from 'react-select';

// style
import * as S from './BaseInput.styled';

// components
import { ReactNumeric } from 'components/ReactNumeric/ReactNumeric';

// context
import { UserContext } from 'context/UserContext';

// hooks
import useTranslate from 'hooks/useTranslate';
import { useSystemGetCultureById } from 'services/systemService';
import { StyledInputErrorMessage } from '../InputsCommon.styled';

export default ({
  id,
  label,
  value,
  disabled,
  required,
  type,
  onChange,
  onBlur,
  errorMessage,
  variation = 'primary',
  className,
  placeholder,
  ...other
}) => {
  const tr = useTranslate().use().global;
  const { locale } = useContext(UserContext);
  const inputRef = useRef();

  const { isSuccess: isCultureSuccess, data: culture } =
    useSystemGetCultureById({
      id: locale,
      queryParams: {
        enabled: !!type,
      },
    });

  const InputElement = type ? ReactNumeric : RegularInput;

  let inputProps = {
    id,
    disabled,
    value,
    placeholder: disabled ? '-' : placeholder ? placeholder : tr['input'],
  };

  const handleEvent = (action, e, val) => {
    if (e.target.value === '') {
      action(null);
    } else {
      action(val);
    }
  };

  if (type) {
    inputProps = {
      ...inputProps,
      ...(onChange && {
        onChange: (e, val) => handleEvent(onChange, e, val),
      }),
      ...(onBlur && {
        onBlur: (e, val) => handleEvent(onBlur, e, val),
        onKeyUp: (e, val) =>
          ['Enter', 'NumpadEnter'].includes(e.code) &&
          handleEvent(onBlur, e, val),
      }),
      ...(isCultureSuccess &&
        culture?.numberFormatInfo && {
          decimalPlaces: +culture.numberFormatInfo.numberDecimalDigits,
          decimalCharacter: culture.numberFormatInfo.numberDecimalSeparator,
          decimalCharacterAlternative:
            culture.numberFormatInfo.numberDecimalSeparator === ',' ? '.' : ',',
          digitGroupSeparator: culture.numberFormatInfo.numberGroupSeparator,
          digitalGroupSpacing:
            culture.numberFormatInfo.numberGroupSizes[0].toString(),
        }),
      modifyValueOnWheel: false,
      value: (isFinite(inputProps.value) ? inputProps.value : 0) ?? '',
    };
  } else {
    inputProps = {
      ...inputProps,
      onChange: (e) => onChange(e.target.value),
      value: value ?? '',
    };
  }

  const handleClear = () => {
    onChange(type ? null : '');

    if (inputRef.current?.input) {
      inputRef.current.input.focus();
    } else {
      inputRef.current.focus();
    }
  };

  return (
    <S.BaseInput
      disabled={disabled}
      isInvalid={!!errorMessage}
      variation={variation}
      hasValue={!!value}
      className={className}
      required={required}
    >
      {!!label && <label htmlFor={id}>{label}</label>}
      <div className="input-wrapper">
        <InputElement {...inputProps} {...other} ref={inputRef} />
        {!disabled && !!value && (
          <div className="clear-icon" onClick={(e) => handleClear(e)}>
            <components.ClearIndicator
              getStyles={() => ({ display: 'flex' })}
              getClassNames={() => null}
              cx={() => null}
            />
          </div>
        )}
      </div>
      <StyledInputErrorMessage>{errorMessage}</StyledInputErrorMessage>
    </S.BaseInput>
  );
};

const RegularInput = forwardRef((props, ref) => <input {...props} ref={ref} />);
