import { colors } from 'common/colors/colors';
import styled, { css } from 'styled-components/macro';

export const AppointmentWrapper = styled.div`
  position: absolute;
  display: flex;
  user-select: none;

  top: calc(${(props) => props.topPosition && props.topPosition}% + 1px);
  height: calc(${(props) => props.height && props.height}% - 1px);
  width: ${(props) =>
    props.moving && props.focusing
      ? 100
      : props.multiBooking?.width
        ? props.multiBooking.width
        : 100}%;

  left: ${({ left, moving, focusing, multiBooking }) =>
    left
      ? left
      : moving && focusing
        ? 0
        : multiBooking?.left
          ? multiBooking.left
          : 0}%;

  pointer-events: ${(props) => !props.moving && 'all'};

  z-index: ${(props) => props.focusing};
  transition: ${(props) => (props.isSafari ? 'left' : 'all')} 0.1s;

  border: 1px solid transparent;

  border: ${(props) =>
    (props.drag || props.contextMenuVisible) &&
    `1px solid ${colors.button_Secondary}`};
  border-radius: 5px;

  .hover-time {
    position: absolute;
    right: calc(100% - 1px);

    span {
      height: 19px;
      background-color: white;

      display: flex;
      align-items: center;
      padding: 0 7px;
      padding-bottom: 2px;

      max-width: 40px;
      display: flex;
      justify-content: center;

      border-radius: 100px;

      font-size: 12px;

      color: ${colors.bg_Primary};
      border: 1px solid ${colors.bg_Primary};
      font-weight: 900;
    }
  }
`;

export const Tooltip = styled.div`
  min-width: 290px;
`;

export const DragHandle = styled.div`
  width: 9px;
  height: 9px;
  background-color: ${colors.button_Secondary};
  border-radius: 50%;
  position: absolute;
  cursor: ns-resize;

  top: -5px;
  left: 5px;

  z-index: 1;

  ${(props) =>
    props.bottom &&
    css`
      top: unset;
      left: unset;
      bottom: -5px;
      right: 5px;
    `}
`;
