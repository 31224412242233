import useTranslate from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteAppointment,
  resetAppointment,
  updateAppointment,
} from 'redux/actions/appointmentsAction';
import { updatePendingOrder } from 'redux/actions/pendingOrdersAction';
import {
  useServiceGetOrder,
  useServicesModifyOrderExecutionDetails,
} from 'services/orderService';
import { toast } from 'common/helpers/toast';

export const useModifyOrderDetailsHandler = (cache, refetchOrders, params) => {
  const tr = useTranslate().use().global;
  const dispatch = useDispatch();
  const [orderId, setOrderId] = useState(null);

  // Functions
  const updateOrderFromAPI = (id) => {
    setOrderId(id);
    if (orderId) getOrder.refetch();
  };

  const updateOrderToOldV = () => cache && cache.undo(false);

  const deleteOrder = (id) => {
    if (id) dispatch(deleteAppointment(id));

    if (cache) {
      cache.setState(cache.state.slice(0, -1));
    }
  };

  // ----- MODIFY ORDER -----
  const modifyOrder = useServicesModifyOrderExecutionDetails(params);

  // SUCCESS
  useEffect(() => {
    if (!modifyOrder.isSuccess) return;
    const data = modifyOrder.data.data.order;

    toast.success(tr['order-updated-successfully']);

    if (data.employeeId) {
      dispatch(updateAppointment(data));
    } else {
      dispatch(updatePendingOrder(data));
    }
  }, [modifyOrder.isSuccess]);

  // ERROR
  useEffect(() => {
    if (!modifyOrder.isError) return;

    const { id } = modifyOrder.variables;

    switch (modifyOrder.error.response.status) {
      case 412:
        // Trying to update already modified entity
        updateOrderFromAPI(id);

        break;
      case 400:
        const errorObj = modifyOrder.error.response.data.errors;
        const ordersOverlap = errorObj.hasOwnProperty('Orders_Overlap');
        const orderConfirmed = errorObj.hasOwnProperty(
          'Orders_CannotModifyConfirmedOrder',
        );

        if (ordersOverlap) {
          updateOrderToOldV();
          refetchOrders();
        } else if (orderConfirmed) {
          // Trying to update Confirmed order
          updateOrderFromAPI(id);
        } else {
          // Invalid data
          updateOrderToOldV();
        }

        break;
      case 401:
        // Not authorised
        window.location.reload();
        break;

      case 404:
        // Not found
        deleteOrder(id);
        break;

      case 402:
      case 403:
      case 429:
      default:
        // Draw last known
        updateOrderToOldV();
        break;
    }
  }, [modifyOrder.isError]);

  // ----- GET ORDER -----
  const getOrder = useServiceGetOrder({
    id: orderId,
    // id: 'eb73ca47b21f4f92abaf94f13372b34e', //  for 404 testing
    queryParams: {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!orderId,
    },
    searchParams: {
      serviceListMode: 'both',
    },
  });

  // SUCCESS
  const orderData = JSON.stringify(getOrder.data);
  useEffect(() => {
    if (!getOrder.isSuccess) return;
    const order = getOrder.data.order;
    setOrderId(null);
    dispatch(resetAppointment(order));
    if (cache) {
      cache.setState(cache.state.slice(0, -1));
    }
  }, [getOrder.isSuccess, orderData]);

  // ERROR
  useEffect(() => {
    if (!getOrder.isError) return;

    switch (getOrder.error.response.status) {
      case 404:
        deleteOrder(orderId);
        break;
      default:
        updateOrderToOldV();
        break;
    }
  }, [getOrder.isError, orderId]);

  return modifyOrder;
};
