import { useDelete, useFetch, usePost } from './useApi';

export const useClientsGet = (params = {}) =>
  useFetch({
    endpoint: 'customers',
    ...params,
    // queryParams: {
    //   staleTime: 1000 * 60 * 60,
    //   ...params.queryParams,
    // },
  });
export const useClientsPost = (params = {}) =>
  usePost({
    endpoint: 'customers',
    ...params,
  });

export const useClientsDelete = (params = {}) =>
  useDelete({
    endpoint: 'customers',
    ...params,
  });

export const useClientsBrowsePost = (params = {}) =>
  usePost({
    endpoint: 'customers/browse',
    reqObjName: null,
    ...params,
  });

export const useClientsFindGet = (params = {}) =>
  useFetch({
    endpoint: 'customers/find',
    ...params,
  });
