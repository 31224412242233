import { useContext, useEffect, useRef, useState } from 'react';

// style
import * as S from './AppointmentDetails.styled';

// icons
import { Arrow, LinkUrlSVG, PhoneSVG, VerticalEllipsis } from 'common/icons';

// images

// components
import { MainContext } from 'pages/Main/Main';
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper';
import Overlay from 'components/Overlay/Overlay';
import DialogLayout from 'components/DialogLayout/DialogLayout';
import FunctionBar from 'components/FunctionBar/FunctionBar';
import { Link } from 'components/Overrides';
import ContextTree from 'components/ContextTree/ContextTree';
import Avatar from 'components/Avatar/Avatar';

// contexts
import { ScheduleColumnContext } from 'context/ScheduleColumnContext';
import { ScheduleContext } from 'context/ScheduleContext';
import { UserContext } from 'context/UserContext';

// hooks
import useTooltipWhenOverflows from 'hooks/useTooltipWhenOverflows';
import { useAppointment } from 'hooks/useAppointment';
import useMediaQuery from 'hooks/useMediaQuery';
import useTranslate from 'hooks/useTranslate';
import useStatusList from 'hooks/useStatusList';
import useNumber from 'hooks/useNumber';
import { fillImgPath } from 'common/helpers/imagesFunctions';
import {
  getDurationFromMinutes,
  getDurationString,
  getTimeString,
} from 'common/helpers/dateOperations';

const AppointmentDetails = ({
  order,
  close,
  functions,
  functionItems,
  statusItems,
  balanceItems,
}) => {
  const {
    id,
    sequenceNo,
    customer,
    employeeId,
    completionStatus,
    wantedEmployees,
    services,
    startTimeUtc,
    durationInMinutes,
    amountCovered,
    finalAmountWithVat,
    note,
  } = {
    ...order,
  };
  const isMobile = useMediaQuery('(max-width: 600px)');
  const tr = useTranslate().use().global;
  const { searchParams, numberOfDays } = useContext(ScheduleContext);
  const { sectorData } = useContext(MainContext);
  const { locale } = useContext(UserContext);
  const {
    state: { employee },
  } = useContext(ScheduleColumnContext) || {
    state: { employee: undefined },
  };
  const status = useStatusList(completionStatus.toLowerCase());
  const { formatPrice } = useNumber({ price: true });
  const { icons } = useAppointment();
  const orderTitleRef = useRef();
  const clientNameRef = useRef();
  const clientName =
    customer?.name || customer?.lastName
      ? `${customer?.name} ${customer?.lastName}`
      : `\u2013`;
  const orderId = tr['order'] + ' ' + sequenceNo;
  useTooltipWhenOverflows(clientNameRef, clientName);
  useTooltipWhenOverflows(orderTitleRef, orderId);
  const isPendingOrder = employeeId === undefined;
  const time = {
    start: startTimeUtc,
    end: new Date(
      new Date(startTimeUtc).getTime() + durationInMinutes * 60000,
    ).toISOString(),
  };
  const startTimeString = getTimeString(
    time?.start,
    sectorData?.settings?.timezoneIanaId,
    locale,
  );
  const endTimeString = getTimeString(
    time?.end,
    sectorData?.settings?.timezoneIanaId,
    locale,
  );
  const durationString =
    getDurationFromMinutes(durationInMinutes) ||
    getDurationString(
      time?.start,
      time?.end,
      sectorData?.settings?.timezoneIanaId,
    );

  const finalPrice = formatPrice(
    services.reduce((prev, curr) => prev + +curr.finalAmountWithVat, 0),
  );

  return (
    <ConditionalWrapper
      condition={isMobile}
      wrapper={(children) => (
        <Overlay isVisible={true}>
          <S.AppointmentsDetails statusColor={status?.background}>
            <DialogLayout
              modal
              getBack={close}
              headerText={tr['order']}
              // buttons={[
              //   {
              //     id: 'edit',
              //     icon: <Pencil bold />,
              //     action: () => console.log('Edit btn'),
              //     show: true,
              //   },
              // ]}
            >
              <FunctionBar
                fixed
                functions={functions}
                id="service-fab"
                elavatedFabs
              />

              {children}
            </DialogLayout>
          </S.AppointmentsDetails>
        </Overlay>
      )}
      secondaryWrapper={(children) => (
        <S.AppointmentsDetails statusColor={status?.background}>
          {children}
        </S.AppointmentsDetails>
      )}
    >
      <div className="head">
        <Link
          to={
            customer?.id &&
            `/functions/tables/clients/${customer.id}/general-information`
          }
        >
          <div className="full-name">
            <span ref={clientNameRef}>{clientName}</span>
            <div className="section__link__icon">
              <LinkUrlSVG bold />
            </div>
          </div>
        </Link>
        {customer?.phoneNumber && (
          <span className="phone">
            <PhoneSVG />
            {customer?.phonePrefix + customer?.phoneNumber}
          </span>
        )}
      </div>
      <div className="body">
        {sequenceNo && (
          <div className="section display__row appopintment-id">
            <Link
              to={id && '/functions/tables/orders/' + id}
              className="section__link"
              state={{
                toOrder: true,
                numberOfDays: numberOfDays.visible,
                searchParams: Object.fromEntries([...searchParams]),
              }}
            >
              <span ref={orderTitleRef}>{orderId}</span>
              <div className="section__link__icon">
                <LinkUrlSVG bold />
              </div>
            </Link>
            {!isMobile && (
              <div className="section__functions" id="functions-button">
                <VerticalEllipsis />
                <ContextTree
                  items={functionItems}
                  containerId="functions-button"
                  openOnClick
                  fixedWidth={200}
                />
              </div>
            )}
          </div>
        )}
        {!isPendingOrder && isMobile && employee && (
          <div className="section display__column emoloyee-and-office">
            <div className="display__row emoloyee">
              <span className="section__label">{tr['specialist']}:</span>
              <div className="display__row">
                <Avatar
                  raised
                  imagePath={fillImgPath(employee?.photoUrl)}
                  fullName={employee?.firstName + ' ' + employee.lastName}
                />
                <span className="fullname">
                  {employee?.firstName} {employee?.lastName}
                </span>
              </div>
            </div>
            <div className="display__row">
              <span className="section__label">{tr['office']}:</span>2
            </div>
          </div>
        )}
        {!!wantedEmployees?.length && (
          <div className="section display__row preferred-employees">
            <span className="section__label">{tr['prefer-to-see']}:</span>
            <div className="display__row avatars">
              {wantedEmployees.map(({ photoUrl, firstName, lastName }, i) => (
                <div
                  key={i}
                  data-tooltip-id="tooltip"
                  data-tooltip-html={
                    firstName || lastName
                      ? `${firstName} ${lastName}`
                      : `\u2013`
                  }
                >
                  <Avatar
                    imagePath={fillImgPath(photoUrl)}
                    raised
                    fullName={firstName + ' ' + lastName}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        <div
          className="section display__row appointment-status"
          id="appointment-status"
        >
          <div className="status">
            <div className="status__color" />
            <span className="status__label">{status?.title}</span>
          </div>
          {!isMobile && (
            <>
              <Arrow className="section__arrow" black />
              <ContextTree
                items={statusItems}
                containerId="appointment-status"
                openOnClick
                fixedWidth={200}
              />
            </>
          )}
        </div>
        <div className="section display__row time">
          {!isPendingOrder && (
            <div className={isMobile ? 'display__row' : 'display__column'}>
              <span className="section__label">{tr['time']}:</span>
              {startTimeString} - {endTimeString}
            </div>
          )}
          <div className={isMobile ? 'display__row' : 'display__column'}>
            <span className="section__label">{tr['duration']}:</span>
            {durationString}
          </div>
        </div>
        {!!services?.length && (
          <div className="section display__column services">
            <div className={isMobile ? 'display__column' : ''}>
              <span className="section__label">{tr['services']}:</span>
              <ul>
                {services.map(({ serviceName }, i) => (
                  <li key={i}>
                    <span>{serviceName}</span>
                  </li>
                ))}
              </ul>
            </div>
            <span className="price">{finalPrice}</span>
          </div>
        )}
        <div className="section display__row balance" id="balance">
          <div className="status display__row">
            {amountCovered !== undefined && (
              <div className="display__column">
                <span className="section__label">{tr['paid']}:</span>
                <span>{formatPrice(amountCovered)}</span>
              </div>
            )}
            {finalAmountWithVat !== undefined &&
              amountCovered !== undefined && (
                <div className="display__column">
                  <span className="section__label">{tr['pay']}:</span>
                  <span className="section__label status__debt">
                    {formatPrice(finalAmountWithVat - amountCovered)}
                  </span>
                </div>
              )}
          </div>
          {!isMobile && (
            <>
              <Arrow className="section__arrow" black />
              <ContextTree
                items={balanceItems}
                containerId="balance"
                openOnClick
                fixedWidth={200}
              />
            </>
          )}
        </div>
        <div className="section display__column note">
          <span className="section__label">{tr['note']}:</span>
          <p>{note || tr['none']} </p>
        </div>
        <div
          className={`section additional-info ${isMobile ? 'display__column' : 'display__row'}`}
        >
          {icons.map(({ icon, label }, i) => (
            <div
              key={i}
              className="icon-wrapper display__row"
              data-tooltip-id="tooltip"
              data-tooltip-html={label}
            >
              {icon}
              {isMobile && <span>{label}</span>}
            </div>
          ))}
        </div>
      </div>
    </ConditionalWrapper>
  );
};

export default AppointmentDetails;
