import { colors } from 'common/colors/colors';
import {
  dropdownRollIn,
  rotate180,
  rotate180Back,
} from 'common/styles/animations';
import styled from 'styled-components/macro';

export const Sector = styled.div`
  align-items: center;
  animation: ${dropdownRollIn} 0.3s ease-in-out;
  border-radius: 5px;
  display: flex;
  flex-shrink: 0;
  font-size: 1rem;
  gap: 10px;
  justify-content: space-between;
  padding: 10px;
  transform-origin: top;
  user-select: none;

  @media (max-width: 960px) {
    padding-inline: 10px;
  }

  &.open .icon svg {
    animation: ${rotate180} ease-in-out 0.3s both;
  }

  &.closed .icon svg {
    animation: ${rotate180Back} ease-in-out 0.3s both;
  }

  &:hover {
    background-color: lightgrey;
  }

  &:active,
  &.active {
    background-color: ${colors.bg_Primary};
    color: ${({ isNew }) =>
      isNew ? colors.disabledGrey : colors.text_Primary};

    svg {
      fill: ${({ isNew }) =>
        isNew ? colors.disabledGrey : colors.text_Primary};
    }

    > div > div:first-child {
      cursor: ${({ isNew, isSet, isEmpty }) =>
        !(isNew || isSet || isEmpty) && 'pointer'};
    }
  }

  &:not(.active) {
    > div > div:first-child {
      cursor: ${({ isEmpty, isSet }) => !(isSet || isEmpty) && 'pointer'};
    }

    > div > div:only-child,
    > div > div:nth-child(2) {
      cursor: pointer;
    }
  }

  &.inactive {
    color: ${colors.disabledGrey};
  }

  &.sector {
    font-size: 1.125rem;
    font-weight: 700;
  }

  > div {
    /* border: 1px solid green; */
  }

  .control-description-container {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .title-container {
      display: flex;
      gap: 10px;

      .icon,
      .set-icon {
        flex-shrink: 0;
        height: 16px;
        width: 16px;
      }

      .icon {
        align-items: center;
        color: ${({ isEmpty }) => isEmpty && colors.disabledGrey};
        display: flex;
        transform: rotate(90deg);
        transform-origin: center;
      }

      .icon svg,
      .set-icon svg {
        height: 16px;
        width: 16px;
      }

      .title {
      }
    }

    .title,
    .subtitle {
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${({ search }) => (search ? 1 : 2)};
    }

    .subtitle {
      font-size: 0.75rem;
      color: ${colors.bg_grey_3};
    }
  }

  &.sector .title-container .icon {
    margin-top: 1px;
  }

  .context-menu-icon-container {
    flex-shrink: 0;
    /* height: 16px; */
    width: ${({ serviceAction }) => (serviceAction ? 20 : 16)}px;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  // TODO: maybe possible to remove? refactor moving to .context-menu-icon-container
  &.action {
    /* align-items: center; */

    .functions {
      align-self: center;
      height: 20px;
      padding: 0;
      width: 20px;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
`;
