import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { shadow } from 'common/styles/shadows';
import { Container } from 'layouts/MainLayout/MainLayout.styled';
import { PageHeader } from 'components/PageHeader/PageHeader.styled';
import { TheButton } from 'components/Buttons/TheButton.styled';
export { Container };

export const SettingsTemplate = styled.section`
  ${PageHeader} > .header {
    margin-right: 0;
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 70px;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: end;
    background-color: #fbf9f7;
    box-shadow: ${shadow.footerTop};
    z-index: 1;
    padding: 20px;

    ${TheButton} {
      height: 30px;
      width: max-content;
    }
  }
`;

export const PageContent = styled.div``;

export const Links = styled.div`
  height: 100%;
  margin-right: 40px;
  position: sticky;
  top: 180px;

  @media screen and (max-width: 960px) {
    margin-right: revert;
    width: 100%;
  }

  & ~ * {
    border-left: 1px solid ${colors.table_borders_Primary};
    padding-left: 40px;

    @media screen and (max-width: 960px) {
      border-left: revert;
      padding-left: revert;
    }
  }

  a {
    align-items: center;
    border-left: 5px solid transparent;
    border-radius: 0 25px 25px 0;
    box-shadow: ${shadow.md};
    color: ${colors.text_Secondary};
    display: flex;
    height: 50px;
    font-weight: 900;
    padding: 0 20px 0 15px;
    text-decoration: none;
    white-space: nowrap;

    @media screen and (max-width: 960px) {
      border-bottom: 1px solid ${colors.table_borders_Primary};
      border-left: unset;
      border-radius: unset;
      box-shadow: unset;
      padding-left: 0;
      width: 100%;
    }

    :not(:last-child) {
      margin-bottom: 10px;

      @media screen and (max-width: 960px) {
        margin-bottom: unset;
      }
    }

    &.active {
      border-left: 5px solid ${colors.tab_borders_active};

      @media screen and (max-width: 960px) {
        border: unset;
        border-bottom: 1px solid ${colors.table_border};
      }
    }
  }
`;

export const TabContents = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 960px) {
    width: 0;
  }
`;
