import { useRef, Children, isValidElement, cloneElement } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// style
import * as S from './DialogLayout.styled';

// icons
import { Ex, BurgerMenu, ArrowBack } from 'common/icons';

// components
import { TheButton } from 'components/Buttons/TheButton';

// hooks
import { useButtonsSetter } from 'hooks/useButtonsSetter';
import { useMediaQuery } from 'hooks/useMediaQuery';

export const DialogLayout = ({
  Child,
  childProps,
  headerText,
  getBack,
  exit,
  exitFunc,
  navSticky,
  menuBurger,
  darkTheme,
  footer,
  children,
  modal,
  buttons: presetButtons,
}) => {
  const navigate = useNavigate();
  const footerRef = useRef(null);
  const isMobile = useMediaQuery('(max-width: 960px)');
  const { buttons, setButtons } = useButtonsSetter(presetButtons);

  const hydratedChildren = Children.map(children, (child) =>
    // checking isValidElement is the safe way and avoids a typescript error too
    isValidElement(child) && typeof child.type === 'function'
      ? cloneElement(
          child,
          child.type === Outlet
            ? {
                context: { ...child?.props?.context, setButtons },
              }
            : {
                setButtons,
              },
        ) // FIXME: Throws warning
      : child,
  );

  return (
    <S.DialogLayout navSticky={navSticky}>
      <S.Header navSticky={navSticky} modal={modal}>
        <div className="header__info-container">
          {menuBurger?.state && (
            <div className="header__burger-menu" onClick={menuBurger.toggle}>
              <BurgerMenu bold width={25} />
            </div>
          )}

          {getBack && (
            <TheButton
              className="header__back-button"
              icon={<ArrowBack />}
              action={getBack}
              transparent
            />
          )}

          <div
            className="header__title"
            onClick={() => isMobile && getBack && getBack()}
          >
            {headerText}
          </div>
        </div>

        <S.Buttons exit={exit}>
          {buttons?.map(
            (button, index) =>
              button.show && <TheButton key={index} {...button} />,
          )}

          {(exit || exitFunc) && (
            <TheButton
              className="header__close-button"
              action={() => (exitFunc ? exitFunc() : navigate(-1))}
              icon={<Ex black width={14} height={14} />}
              transparent={!modal}
              outline={modal}
            />
          )}
        </S.Buttons>
      </S.Header>

      <S.Main navSticky={navSticky}>
        <S.Child darkTheme={darkTheme}>
          {Child && (
            <Child
              setButtons={setButtons}
              // closeAction={() => navigate(-1)}
              closeAction={() => (exitFunc ? exitFunc() : navigate(-1))}
              {...childProps}
            />
          )}
          {hydratedChildren}
        </S.Child>

        {footer && <S.Footer ref={footerRef}>{footer && footer}</S.Footer>}
      </S.Main>
    </S.DialogLayout>
  );
};

DialogLayout.propTypes = {
  headerText: PropTypes.string,
  Child: PropTypes.func,
  closeAction: PropTypes.func,
  footer: PropTypes.element,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default DialogLayout;
