import { forwardRef, useContext, useLayoutEffect, useRef } from 'react';
import { TimePicker } from 'react-time-picker';

// style
import * as S from './InputFieldTime.styled';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

// context
import { UserContext } from 'context/UserContext';

export const InputFieldTime = forwardRef(
  (
    {
      id,
      value,
      onChange,
      disabled,
      onFocus,
      isSelected,
      className,
      disableMinutes,
      ...rest
    },
    ref,
  ) => {
    const { locale } = useContext(UserContext);

    const localRef = useRef(null);

    useLayoutEffect(() => {
      if (disableMinutes) {
        const mm = localRef?.current.querySelector(
          '.react-time-picker__inputGroup__minute',
        );

        mm.disabled = true;
      }
    }, [ref, disableMinutes]);

    const onInvalidChange = () => {
      // required to update hour value according to DOM's autocorrected value
      const hh = localRef.current.querySelector(
        '.react-time-picker__inputGroup__hour',
      ).value;
      const mm = localRef.current
        .querySelector('.react-time-picker__inputGroup__minute')
        .value.padStart(2, '0');
      console.log({ hh, mm });
      hh && mm && hh <= 23 && mm <= 59 && onChange(`${hh}:${mm}`);
    };

    return (
      <S.InputFieldTime
        ref={(element) => {
          localRef.current = element;
          if (ref) ref.current = element;
        }}
        isSelected={isSelected}
        disabled={disabled}
        hasValue={!!value}
        onFocus={onFocus}
        className={className}
        disableMinutes={disableMinutes}
      >
        <TimePicker
          id={id}
          clearIcon={null}
          disableClock
          locale={locale}
          // locale="LT"
          value={value}
          onChange={(value) => {
            console.log({ value });
            onChange(value);
          }}
          onFocus={onFocus}
          disabled={disabled}
          onInvalidChange={onInvalidChange}
        />
      </S.InputFieldTime>
    );
  },
);

InputFieldTime.displayName = 'InputFieldTime';

export default InputFieldTime;
