import { useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

// style
import * as S from './SettingsTemplate.styled';

// components
import { MainLayout } from 'layouts/MainLayout/MainLayout';
import { TabTemplate } from 'pages/Settings/TabTemplate';
import { FunctionBar } from 'components/FunctionBar/FunctionBar';
import { PageHeader } from 'components/PageHeader/PageHeader';
import TheButton from 'components/Buttons/TheButton';

// contexts
import { SettingsTemplateContext } from 'context/SettingsTemplateContext';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useSideBarMenu } from 'hooks/useSideBarMenu';
import { useButtonsSetter } from 'hooks/useButtonsSetter';

const PageContent = ({
  element,
  pageTitle,
  contentElement,
  tabs,
  buttons,
  setButtons,
}) => {
  const isMobile = useMediaQuery('(max-width: 960px)');

  return (
    <S.PageContent>
      <PageHeader
        title={element.name}
        subtitle={pageTitle}
        buttons={isMobile ? null : buttons}
      />

      {tabs && (
        <S.Links>
          {tabs.map((tab, key) => (
            <NavLink to={tab.route} key={key}>
              {tab.name}
            </NavLink>
          ))}
        </S.Links>
      )}

      {tabs ? (
        <Routes>
          {!isMobile && tabs && (
            <Route index element={<Navigate to={tabs[0].route} />} />
          )}
          {tabs.map((tab, key) => (
            <Route
              key={key}
              path={tab.route}
              element={<TabTemplate name={tab.name} view={tab.view} />}
            />
          ))}
        </Routes>
      ) : (
        <TabTemplate
          renderInParent
          name={contentElement.name}
          view={contentElement.view}
          setButtons={setButtons}
        />
      )}
    </S.PageContent>
  );
};

export const SettingsTemplate = ({ funcTabs }) => {
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width: 960px)');
  const { buttons, setButtons } = useButtonsSetter();

  const parseString = location.pathname.split('/');

  const currRootMenuItem = parseString[1];
  const currSideBarMenuCategory = parseString[2];
  const currSideBarMenuSubItem = parseString[3];
  const currSideBarMenuSubItemSubItem = parseString[4];
  // const currTab = parseString[4];

  const menu = useSideBarMenu(currRootMenuItem);
  // const element = menu.find((item) => item.id === currSideBarMenuCategory);
  const element = menu.find((item) =>
    [
      currSideBarMenuCategory,
      `/${currRootMenuItem}/${currSideBarMenuCategory}`,
    ].includes(item.route),
  );
  let contentElement = element.subItems.find(
    (item) => item.id === currSideBarMenuSubItem,
  );
  const pageTitle = contentElement.name;

  if (contentElement?.subItems?.length && currSideBarMenuSubItemSubItem) {
    contentElement = contentElement.subItems.find(
      (item) => item.id === currSideBarMenuSubItemSubItem,
    );
  }

  const tabs = contentElement.tabs;

  // Hooks
  const [functionBarFunctions, setFunctionBarFunctions] = useState(undefined);

  const contextObj = {
    functionBarFunctions: {
      state: functionBarFunctions,
      setState: setFunctionBarFunctions,
    },
  };

  // console.log(
  //   'location:',
  //   location,
  //   '\ncurrRootMenuItem:',
  //   currRootMenuItem,
  //   '\ncurrSideBarMenuCategory:',
  //   currSideBarMenuCategory,
  //   '\ncurrSideBarMenuSubItem:',
  //   currSideBarMenuSubItem,
  //   '\ncurrTab:',
  //   currTab,
  //   '\nmenu:',
  //   menu,
  //   '\nelement:',
  //   element,
  //   '\nsubElement:',
  //   subElement,
  //   '\ncurrTabs:',
  //   tabs
  // );

  const pageContentProps = {
    pageTitle,
    element,
    contentElement,
    tabs,
    buttons,
    setButtons,
  };

  const footerVisible = isMobile && buttons.find((btn) => btn.show);

  return (
    <SettingsTemplateContext.Provider value={contextObj}>
      <MainLayout>
        <S.SettingsTemplate footerVisible={footerVisible}>
          <FunctionBar functions={functionBarFunctions} tabs={funcTabs} />
          <S.Container>
            {/* {funcTabs?.length ? (
              <Routes>
                <Route index element={<Navigate to={funcTabs[0].route} />} />
                <Route
                  path=":branchId/*"
                  element={<PageContent {...pageContentProps} />}
                />
              </Routes>
            ) : ( */}
            <PageContent {...pageContentProps} />
            {/* )} */}
          </S.Container>
          {footerVisible && (
            <div className="footer">
              {buttons?.map(
                (button, index) =>
                  button.show && <TheButton key={index} {...button} />,
              )}
            </div>
          )}
        </S.SettingsTemplate>
      </MainLayout>
    </SettingsTemplateContext.Provider>
  );
};

export default SettingsTemplate;
