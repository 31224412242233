import { useMemo } from 'react';

// icons
import {
  Calendar,
  Coins,
  Ellipsis,
  InterchangeSVG,
  PersonOnTable,
  PinSVG,
  TrashCan,
} from 'common/icons';

// hooks
import { useTranslate } from './useTranslate';
import { colors } from 'common/colors/colors';

export const useContextMenuItems = (contents) => {
  const tr = useTranslate().use().global;

  const items = useMemo(
    () => [
      {
        id: 'edit',
        title: tr['edit'],
        items: [],
      },
      {
        id: 'delete-timeOff',
        icon: <TrashCan />,
        title: tr['delete-timeoff'],
        color: colors.mandyRed,
        action: () => {},
      },
      {
        id: 'change-status',
        title: tr['change-order-status'],
        icon: <InterchangeSVG bold />,
        action: () => {},
        items: [],
        acl: 1,
        scope: 3,
      },
      {
        id: 'functions',
        title: tr['functions'],
        icon: <Ellipsis inCircle bold />,
        action: () => {},
        items: [],
        acl: 1,
        scope: 3,
      },
      {
        id: 'pin',
        title: tr['pin'],
        icon: <PinSVG />,
        action: () => {},
      },
      {
        id: 'personal-calendar',
        title: tr['personal-calendar'],
        icon: <PersonOnTable />,
        action: () => {},
      },
      {
        id: 'monthly-calendar',
        title: tr['monthly-calendar'],
        icon: <Calendar />,
        action: () => {},
      },
      {
        id: 'balance-status',
        title: tr['change-balance-status'],
        icon: <Coins bold margin="2px 0 0 0" />,
        action: () => {},
        acl: 1,
        scope: 3,
      },
    ],
    [tr],
  );

  const generateArray = () =>
    contents.reduce((acc, curr) => {
      const found = items.find((func) => func.id === curr);
      if (found) return [...acc, found];

      return acc;
    }, []);

  return Array.isArray(contents)
    ? generateArray()
    : items.find((func) => func?.id === contents);
};
