import { useFetch } from './useApi';

const DB_SLICE = 'employees/schedules';

export const useGetWorkingEmployees = (params = {}) => {
  const context = useFetch({
    endpoint: DB_SLICE,
    ...params,
  });

  return context;
};
