import { useCallback, useContext, useMemo } from 'react';
import { DateTime, Info } from 'luxon';
import { UserContext } from 'context/UserContext';
import { MainContext } from 'pages/Main/Main';

export const useScheduleHelpers = () => {
  const { locale } = useContext(UserContext);
  const { sectors } = useContext(MainContext);

  const sectorSettings = useCallback(
    ({ sectorId }) =>
      sectors?.find((sector) => sector.id === Number(sectorId))?.settings,
    [sectors],
  );

  const cyclicShiftArrayRight = (data) => {
    const response = [...data];
    const lastItem = response?.[response.length - 1];
    response.pop();
    response.unshift(lastItem);
    return response;
  };

  const getFirstDayOfWeek = useCallback(
    ({ sectorId }) => sectorSettings({ sectorId })?.firstDayOfWeek,
    [sectorSettings],
  );

  const getWeekDaysArray = useCallback(
    ({ sectorId, config }) => {
      return (
        (sectorId
          ? getFirstDayOfWeek({ sectorId })
          : Info.getStartOfWeek({ locale })) === 7
          ? cyclicShiftArrayRight(
              Info.weekdays(config?.format || 'short', { locale }),
            )
          : Info.weekdays(config?.format || 'short', { locale })
      ).map((day, index) =>
        config?.return === 'object' ? { value: index, label: day } : day,
      );
    },
    [locale, getFirstDayOfWeek],
  );

  const weekendIndexArray = useMemo(
    () => Info.getWeekendWeekdays(Info.weekdays('short', { locale })),
    [locale],
  );

  // get selected period date objects {start, end}
  const getPeriodDates = (start, end) => {
    const startDay = start ? new Date(start) : new Date();
    startDay.setHours(0, 0, 0, 0);

    const endDay = end ? new Date(end) : start ? new Date(start) : new Date();
    endDay.setHours(23, 59, 59, 999);

    return { start: startDay, end: endDay };
  };

  const getFullPeriodDates = useCallback(
    (start, end, config = {}) => {
      const { sectorId } = config;
      const periodDates = getPeriodDates(start, end);

      const selectedLocale = sectorId
        ? getFirstDayOfWeek({ sectorId }) === 7
          ? 'en'
          : sectorSettings({ sectorId })?.culture
        : locale;

      let startDay = DateTime.fromJSDate(periodDates.start, {
        locale: selectedLocale,
      })
        .startOf('month')
        .startOf('week', { useLocaleWeeks: true })
        .toJSDate();

      let endDay = DateTime.fromJSDate(periodDates.end, {
        locale: selectedLocale,
      })
        .plus({ weeks: 9 })
        .endOf('week', { useLocaleWeeks: true })
        .toJSDate();

      return { start: startDay, end: endDay };
    },
    [locale, sectorSettings, getFirstDayOfWeek],
  );

  const getFullPeriodDatesForAPI = useCallback(
    (start, end, config = {}) => {
      const { sectorId } = config;
      const fullPeriodDates = getFullPeriodDates(start, end, { sectorId });
      const formatDateForAPI = ['lt-LT', { dateFormat: 'short' }]; // API accepts values in lt-LT locale format

      const localize = (dates) => {
        return {
          start: dates.start?.toLocaleDateString(...formatDateForAPI),
          end: dates.end?.toLocaleDateString(...formatDateForAPI),
        };
      };

      return localize(fullPeriodDates);
    },
    [getFullPeriodDates],
  );

  return {
    getFirstDayOfWeek,
    getWeekDaysArray,
    weekendIndexArray,
    getFullPeriodDates,
    getFullPeriodDatesForAPI,
  };
};
