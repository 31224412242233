import { useEffect } from 'react';
import classNames from 'classnames';

// style
import * as S from '../CategoryTree.styled';
import { colors } from 'common/colors/colors';

// icons
import { Plus, VerticalEllipsis, TrashCan, Cube } from 'common/icons';

// components
import { ServiceItemButton } from '../../ServiceItemButton/ServiceItemButton';
import { ServiceStats } from '../../ServiceStats/ServiceStats';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useScrollIntoView } from 'hooks/useScrollIntoView';
import { useOnHoverContextMenu } from 'hooks/useOnHoverContextMenu';
import { useSystemGetUnits } from 'services/systemService';
import { useServicesDelete } from 'services/serviceService';
import {
  getParent,
  isCurrent,
  isNew,
  isParentEmpty,
  removeObjectById,
} from 'common/helpers/serviceFunctions';

export const Service = ({
  service,
  activeItem,
  setActiveItem,
  parentInactive,
  serviceAction,
  noIndent,
  categoryName,
  groupName,
  subgroupName,
  search,
  setCategories,
  setConfirmDeleteButtons,
  confirmDialogHandler,
  parentIsOpenHandler,
  averagePrices = true,
  ...props
}) => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const { getUnitsById } = useSystemGetUnits();

  // handler for context menu, called on hover
  const {
    isHovered,
    setIsHovered,
    setIsContextMenuOpen,
    showContextMenuIcon,
    debounceHideContextMenuIcon,
  } = useOnHoverContextMenu();

  const isInactive = (context) =>
    (parentInactive || !context.isActive) && 'inactive';

  const isSet = service.type === 'set';

  // data delete handler
  const { mutate, isSuccess: isDeletionSuccess } = useServicesDelete('service');

  // delete active item from CategoryTree in case of successful removal from B/E
  useEffect(() => {
    if (isDeletionSuccess) {
      // remove current object
      removeObjectById(service.id, service.type, setCategories, setActiveItem);

      // update isEmpty/isOpen status
      setCategories((oldCategories) => {
        const newCategories = [...oldCategories];
        const parent = getParent(service, newCategories);

        if (isParentEmpty(parent)) {
          // update isEmpty status of parent
          parent.isEmpty = true;
          // update isOpen status of parent
          parentIsOpenHandler && parentIsOpenHandler(false);
        }

        return newCategories;
      });
    }
  }, [
    isDeletionSuccess,
    service.id,
    service.type,
    setActiveItem,
    setCategories,
    service,
    parentIsOpenHandler,
  ]);

  const showServiceDetails = () =>
    setActiveItem &&
    service.id !== 'new' &&
    setActiveItem({ id: service.id, type: service.type });

  const path = {
    categoryName: service.categoryName || categoryName,
    groupName: service.groupName || groupName,
    ...((service.subgroupName || subgroupName) && {
      subgroupName: service.subgroupName || subgroupName,
    }),
  };

  const parentName = path.subgroupName
    ? `${path.subgroupName} ⟵ ${path.groupName}`
    : path.groupName;

  const getFunctionList = (action) => {
    return [
      {
        id: 'delete',
        title: tr['delete'],
        icon: <TrashCan bold />,
        color: 'red',
        action: () => {
          console.log('delete service');
          handleConfirmDialog(service);
          action && action();
        },
      },
    ];
  };

  const { ref } = useScrollIntoView({ auto: true }); // scroll into view currently selected item

  // confirm dialog handler
  const handleConfirmDialog = (context) => {
    setConfirmDeleteButtons((old) => [
      ...old.filter((button) => !['cancel', 'remove'].includes(button.id)),
      {
        ...old.find((button) => button.id === 'cancel'),
        ...{
          message: `${tr['confirm-message']} ${tr['remove']} ${
            tr[context.type + '/accusative']
          }?`,
        },
      },
      {
        ...old.find((button) => button.id === 'remove'),
        ...{
          action: () => {
            console.log(context.type + ' deletion confirmed');
            mutate({ id: context?.id });
            context?.id === activeItem?.id && setActiveItem(undefined);
            confirmDialogHandler.off();
          },
        },
      },
    ]);
    confirmDialogHandler.on();
  };

  return (
    <S.CategoryTreeItem
      className={classNames(
        service.type,
        isCurrent(service, activeItem),
        isInactive(service),
        serviceAction && 'action',
      )}
      onClick={showServiceDetails}
      noIndent={noIndent}
      serviceAction={!!serviceAction}
      search={search}
      isNew={isNew(activeItem)}
      isSet={isSet}
      onMouseEnter={showContextMenuIcon}
      onMouseLeave={debounceHideContextMenuIcon}
      {...((isNew(service) || isCurrent(service, activeItem)) && { ref })} // required for scrolling into view
      {...props}
    >
      <div className="control-description-container">
        <div className="title-container">
          {isSet && (
            <div className="set-icon">
              <Cube />
            </div>
          )}

          <span className="title">{service?.name}</span>
        </div>

        {search && parentName && <div className="subtitle">{parentName}</div>}

        {(serviceAction || search) && (
          <ServiceStats
            {...('baseDurationInMinutes' in service
              ? { duration: service.baseDurationInMinutes }
              : 'unitId' in service
                ? {
                    volume: service.unitVolume,
                    units: getUnitsById(service.unitId)?.label,
                  }
                : {})}
            price={service.avgUnitPriceWithVat}
            {...('compServiceCount' in service && {
              compServiceCount: service.compServiceCount,
            })}
          />
        )}
      </div>

      {!(search && !serviceAction) && (
        <div className="context-menu-icon-container">
          {serviceAction && (
            <ServiceItemButton
              id={service.type + '-' + service.id}
              icon={<Plus inCircle bold />}
              action={() => serviceAction({ ...service, ...path })}
              parentId={'category-container'}
            />
          )}

          {((!isMobile && isHovered) ||
            (isMobile && isCurrent(service, activeItem))) &&
            !isNew(activeItem) &&
            !search &&
            !serviceAction && (
              <ServiceItemButton
                id={service.type + '-' + service.id}
                icon={
                  <VerticalEllipsis
                    {...(isCurrent(service, activeItem) && {
                      color: colors.text_Primary,
                    })}
                  />
                }
                parentId={'category-container'}
                items={getFunctionList}
                triggerCallback={setIsContextMenuOpen}
              />
            )}
        </div>
      )}
    </S.CategoryTreeItem>
  );
};
