import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// style
import * as S from './PersonalSettings.styled';

// components
import { FormContainer } from 'components/Form/FormContainer';
import { FormProvider, InputTime, Select } from 'components/HookForm';
import Loader from 'components/Loader/Loader';
import { AppointmentItemsVisibilitySettings } from './partials/AppointmentItemsVisibilitySettings/AppointmentItemsVisibilitySettings';

// hooks
import useTranslate from 'hooks/useTranslate';
import { useFormStatus } from 'hooks/useFormStatus';
import { useAccountGet } from 'services/accountService';
import { useProjectSettingsPost } from 'services/settingsService';

export const PersonalSettings = ({ setButtons }) => {
  const tr = useTranslate().use().global;
  const userContext = useAccountGet();
  const mutationContext = useProjectSettingsPost();

  const { data, refetch, isFetching } = userContext || {};
  const { user } = data || {};
  const { settings, projectSettings } = user || {};
  const {
    mutate,
    isSuccess: mutationSuccess,
    isError: mutationError,
  } = mutationContext;

  const schema = yup.object().shape({
    scrollCalendarToTime: yup
      .string()
      .required(
        `${tr['calendar-scroll-time']} ${tr['required'].toLowerCase()}`,
      ),
    homeScreen: yup
      .string()
      .required(`${tr['home-screen']} ${tr['required'].toLowerCase()}`),
    serviceListSort: yup
      .string()
      .required(
        `${tr['service-list-sorting']} ${tr['required'].toLowerCase()}`,
      ),
    pc: yup.object().shape({
      calendar: yup.object().shape({
        scaleInMinutes: yup
          .number()
          .oneOf(
            [5, 10, 15, 20, 30, 60],
            ({ resolved }) =>
              `${tr['calendar-time-interval']} ${tr['must-be-one-of-the-following-values'].toLowerCase()}: ${resolved.toString()}.`,
          )
          .required(
            `${tr['calendar-time-interval']} ${tr['required/feminine'].toLowerCase()}`,
          ),
      }),
    }),
    mobile: yup.object().shape({
      calendar: yup.object().shape({
        scaleInMinutes: yup
          .number()
          .oneOf(
            [5, 10, 15, 20, 30, 60],
            ({ resolved }) =>
              `${tr['calendar-time-interval']} ${tr['must-be-one-of-the-following-values'].toLowerCase()}: ${resolved.toString()}.`,
          )
          .required(
            `${tr['calendar-time-interval']} ${tr['required/feminine'].toLowerCase()}`,
          ),
        employeeColumns: yup
          .number()
          .oneOf(
            [1, 2, 3],
            ({ resolved }) =>
              `${tr['number-of-columns-in-calendar']} ${tr['must-be-one-of-the-following-values'].toLowerCase()}: ${resolved.toString()}.`,
          )
          .required(
            `${tr['number-of-columns-in-calendar']} ${tr['required'].toLowerCase()}`,
          ),
      }),
    }),
  });

  const defaultValues = useMemo(
    () => ({
      scrollCalendarToTime: projectSettings?.scrollCalendarToTime ?? null,
      homeScreen: projectSettings?.homeScreen ?? settings?.homeScreen ?? null,
      serviceListSort: projectSettings?.serviceListSort ?? null,
      pc: {
        calendar: {
          scaleInMinutes: projectSettings?.pc?.calendar?.scaleInMinutes ?? null,
          showCustomerName:
            projectSettings?.pc?.calendar?.showCustomerName ?? null,
          showCustomerLastName:
            projectSettings?.pc?.calendar?.showCustomerLastName ?? null,
          showCustomerPhone:
            projectSettings?.pc?.calendar?.showCustomerPhone ?? null,
          showServices: projectSettings?.pc?.calendar?.showServices ?? null,
          showIconNewCustomer:
            projectSettings?.pc?.calendar?.showIconNewCustomer ?? null,
          showIconSms: projectSettings?.pc?.calendar?.showIconSms ?? null,
          showIconNote: projectSettings?.pc?.calendar?.showIconNote ?? null,
          showIconBirthday:
            projectSettings?.pc?.calendar?.showIconBirthday ?? null,
        },
      },
      mobile: {
        calendar: {
          scaleInMinutes:
            projectSettings?.mobile?.calendar?.scaleInMinutes ?? null,
          employeeColumns:
            projectSettings?.mobile?.calendar?.employeeColumns ?? null,
          showCustomerName:
            projectSettings?.mobile?.calendar?.showCustomerName ?? null,
          showCustomerLastName:
            projectSettings?.mobile?.calendar?.showCustomerLastName ?? null,
          showCustomerPhone:
            projectSettings?.mobile?.calendar?.showCustomerPhone ?? null,
          showServices: projectSettings?.mobile?.calendar?.showServices ?? null,
          showIconNewCustomer:
            projectSettings?.mobile?.calendar?.showIconNewCustomer ?? null,
          showIconSms: projectSettings?.mobile?.calendar?.showIconSms ?? null,
          showIconNote: projectSettings?.mobile?.calendar?.showIconNote ?? null,
          showIconBirthday:
            projectSettings?.mobile?.calendar?.showIconBirthday ?? null,
        },
      },
    }),
    [projectSettings, settings],
  );

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { reset, handleSubmit } = methods;

  const { isLoading } = useFormStatus({
    resourceContext: userContext,
    mutationContext: mutationContext,
    setButtons: setButtons,
    methods: methods,
    resetForm: () => reset(defaultValues),
    submit: handleSubmit(mutate),
    modal: false,
    formCanBeDisabled: false,
    isMobileButtonsOnlyIcons: false,
  });

  useEffect(() => {
    if (!isFetching) {
      reset(defaultValues);
    }
  }, [defaultValues, reset, isFetching]);

  useEffect(() => {
    if (mutationSuccess) {
      refetch();
    }
  }, [mutationSuccess, mutationError, refetch]);

  // console.log(
  //   projectSettings?.scrollCalendarToTime
  //     ? DateTime.fromFormat(
  //         projectSettings?.scrollCalendarToTime,
  //         'hh:mm',
  //       )?.toJSDate()
  //     : null,
  // );

  return (
    <S.PersonalSettings>
      {isLoading ? (
        <Loader />
      ) : (
        <FormProvider methods={methods}>
          <FormContainer title={tr['general']}>
            <div className="form">
              <div className="row">
                <div className="input-container">
                  <InputTime
                    name="scrollCalendarToTime"
                    label={tr['scroll-calendar-to']}
                    disableMinutes
                    // onInvalidChange={() => {}}
                  />
                </div>
              </div>
              <div className="row">
                <div className="input-container">
                  <Select
                    name="homeScreen"
                    label={tr['home-screen']}
                    options={[
                      { value: 'd', label: tr['view-day'] },
                      { value: 'w', label: tr['view-week'] },
                      { value: 'm', label: tr['view-month'] },
                      { value: 'i', label: tr['view-personal'] },
                    ]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="input-container">
                  <Select
                    name="serviceListSort"
                    label={tr['service-list-sorting']}
                    options={[
                      { value: 'Alphabetical', label: tr['alphabetical'] },
                      {
                        value: 'CompositeFirstThenAlphabetical',
                        label: tr['set-first-then-alphabetical'],
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </FormContainer>
          <FormContainer
            title={tr['desktop-view-settings']}
            className="desktop-settings"
          >
            <div className="form">
              <div className="row">
                <div className="input-container">
                  <Select
                    name="pc.calendar.scaleInMinutes"
                    label={tr['calendar-time-interval']}
                    options={[
                      { label: '00:05', value: 5 },
                      { label: '00:10', value: 10 },
                      { label: '00:15', value: 15 },
                      { label: '00:20', value: 20 },
                      { label: '00:30', value: 30 },
                      { label: '01:00', value: 60 },
                    ]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="input-container">
                  <AppointmentItemsVisibilitySettings />
                </div>
              </div>
            </div>
          </FormContainer>
          <FormContainer
            title={tr['mobile-view-settings']}
            className="mobile-settings"
          >
            <div className="form">
              <div className="row">
                <div className="input-container">
                  <Select
                    name="mobile.calendar.scaleInMinutes"
                    label={tr['calendar-time-interval']}
                    options={[
                      { label: '00:05', value: 5 },
                      { label: '00:10', value: 10 },
                      { label: '00:15', value: 15 },
                      { label: '00:20', value: 20 },
                      { label: '00:30', value: 30 },
                      { label: '01:00', value: 60 },
                    ]}
                  />
                </div>
                <div className="input-container">
                  <Select
                    name="mobile.calendar.employeeColumns"
                    label={tr['number-of-columns-in-calendar']}
                    options={[
                      { label: '1', value: 1 },
                      { label: '2', value: 2 },
                      { label: '3', value: 3 },
                    ]}
                  />
                </div>
              </div>

              <div className="row">
                <div className="input-container">
                  <AppointmentItemsVisibilitySettings postDataPrefix="mobile" />
                </div>
              </div>
            </div>
          </FormContainer>
        </FormProvider>
      )}
    </S.PersonalSettings>
  );
};

export default PersonalSettings;
