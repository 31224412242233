import { useCallback } from 'react';

// hooks
import { useTranslate } from 'hooks/useTranslate';

const useListExcusesWithAction = () => {
  const tr = useTranslate().use().global;

  return useCallback((close, listOfExcuses, createNewExcuse, activeCell) => {
    const arr = listOfExcuses.map((item) => {
      return {
        ...item,
        title: tr[item.label],
        action: () => {
          createNewExcuse(
            {
              id: item.id,
              label: tr[item.label],
              category: item.category,
            },
            activeCell,
          );
        },
      };
    });

    arr.push({
      id: 22,
      title: tr['other'] + '...',
      items: [
        {
          id: 222,
          input: true,
          onKeyPress: (e) => {
            if (e.key === 'Enter') {
              createNewExcuse(
                {
                  id: 22,
                  label: e.target.value,
                  category: 'excuse',
                },
                activeCell,
              );
            }
          },
        },
      ],
    });

    return arr;
  }, []);
};

export default useListExcusesWithAction;
