import styled, { css } from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import StyledSVG from 'common/icons/SVG.styled';

export const StyledInputErrorMessage = styled.span`
  color: ${colors.mandyRed};
  font-size: 0.75rem;
  font-family: ${fontFamilies.family_Secondary};
  position: absolute;
  bottom: -2px;
  left: 10px;
  transform: translate3d(0, 100%, 0);
  line-height: 10px;
`;

export const StyledRadioButtonAndCheckbox = css`
  align-items: center;
  display: flex;
  gap: 10px;
  user-select: none;
  width: max-content;

  label {
    cursor: ${({ excludeLabel, disabled }) =>
      excludeLabel || disabled ? 'default' : 'pointer'};
    display: flex;
  }

  ${StyledSVG} {
    flex: none;
    color: ${({ disabled }) => (disabled ? colors.disabledGrey : '')};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }

  .label-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
  }
`;
