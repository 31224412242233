import {
  useContext,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react';
import { DateTime } from 'luxon';
import { useTranslate } from 'hooks/useTranslate';
import { useSelector, useDispatch } from 'react-redux';
import { addAppointment } from 'redux/actions/appointmentsAction';
import { deletePendingOrder } from 'redux/actions/pendingOrdersAction';

// style
import * as S from './PendingOrders.styled';

// icons
import { Ex, FilterClearSVG } from 'common/icons';

// components
import PendingOrderCard from './PendingOrderCard/PendingOrderCard';
import TheButton from 'components/Buttons/TheButton';

// contexts
import { ScheduleContext } from 'context/ScheduleContext';
import { MainContext } from 'pages/Main/Main';

// hooks
import useMediaQuery from 'hooks/useMediaQuery';
import { usePendingOrders } from 'hooks/schedule/usePendingOrders';
import { useModifyOrderDetailsHandler } from 'hooks/schedule/useModifyOrderDetailsHandler';
import { SCHEDULE_CELL_HEIGHT } from 'common/constants';
import { checkForOverlapingOrder } from 'common/helpers/scheduleFunctions';
import Avatar from 'components/Avatar/Avatar';
import { fillImgPath } from 'common/helpers/imagesFunctions';

const PendingOrders = ({ hideClear }) => {
  const {
    cache,
    pendingOrders,
    draggingAppointment,
    screenHeight,
    sectorId,
    refetchOrders,
    selectedProfessional,
    numberOfDays,
    scheduleHeaderRef,
    dataSource,
    appointmentsFilter,
  } = useContext(ScheduleContext);
  const { sectorData } = useContext(MainContext);
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const dispatch = useDispatch();
  const [timeDate, setTimeDate] = useState(new Date());
  const [hoverTime, setHoverTime] = useState(null);
  const [allowHover, setAllowHover] = useState(true);
  const [dragging, setDragging] = useState(null);
  const orders = useSelector((state) => state.appointments);
  const pendingOrdersR = useSelector((state) => state.pendingOrders);
  const startHour = useSelector((state) => state.scheduleReducer.startHour);
  const dateState = useSelector((state) => state.scheduleReducer.dateState);
  const cellDuration = useSelector(
    (state) => state.scheduleReducer.cellDuration,
  );
  const scrollableDiv = useRef(null);
  const overlaping = useRef(false);
  const appointmentCardRefs = useRef([]);
  usePendingOrders(sectorId, selectedProfessional?.value?.id);
  const modifyOrder = useModifyOrderDetailsHandler(cache, refetchOrders, {
    searchParams: {
      useVersioning: 1,
      allowOverlap: overlaping.current,
      serviceListMode: 'both',
    },
  });
  const scheduleHeaderHeight =
    scheduleHeaderRef?.current?.getBoundingClientRect()?.height;

  // -- side effects
  useEffect(() => {
    let timeInterval;

    if (!isMobile) {
      timeInterval = setInterval(() => setTimeDate(new Date()), 30000);
    }

    return () => clearInterval(timeInterval);
  }, [isMobile]);

  // Functions
  const getHoverTime = (topPosition) => {
    const top = Math.round(topPosition / SCHEDULE_CELL_HEIGHT);

    const x = (cellDuration / 60) * top + +startHour;

    const hours = Math.floor(x);
    let minutes = Math.round((x - hours) * 60);

    if (minutes === 0) {
      minutes = '00';
    }

    return `${hours}:${minutes}`;
  };

  const getPendingOrderHeight = (duration) => {
    const splited = duration.split(':');
    const fraction = +splited[0] + +splited[1] / 60;

    const cellDurationFraction = cellDuration / 60;

    return (fraction / cellDurationFraction) * SCHEDULE_CELL_HEIGHT;
  };

  const updatePendingToSchedule = (id, column, order, startTime) => {
    const employeeId = column.dataset.id.split('--')[0];

    const newOrder = structuredClone(order);

    newOrder.employeeId = employeeId;
    newOrder.startTimeUtc = DateTime.now()
      .setZone(sectorData.settings.timezoneIanaId)
      .set({
        year: startTime.getFullYear(),
        month: startTime.getMonth() + 1,
        day: startTime.getDate(),
        hours: startTime.getHours(),
        minutes: startTime.getMinutes(),
        millisecond: 0,
      })
      .toUTC()
      .toISO({ suppressMilliseconds: true });

    console.log(newOrder);

    dispatch(deletePendingOrder(id));
    dispatch(addAppointment(newOrder));

    const newColumn = {
      employeeId,
    };

    const isOvelaping = checkForOverlapingOrder(
      orders?.employees,
      newOrder,
      newColumn,
    );

    overlaping.current = isOvelaping;
    sendModifiedBody(order, newOrder, null, 'fromPendingToScheduler');
  };

  const getNewStartTime = (schedule, column, dragableDivOffsetTop) => {
    const columnDay = new Date(column.dataset.id.split('--')[1]);
    const tillTop = dragableDivOffsetTop + schedule.scrollTop - 10;
    const cellRow = Math.floor(tillTop / SCHEDULE_CELL_HEIGHT);
    let startDate = new Date(dateState);

    startDate.setTime(columnDay.getTime());
    startDate.setHours(startHour, 0, 0, 0);
    startDate.setMinutes(startDate.getMinutes() + cellDuration * cellRow);

    return startDate;
  };

  useLayoutEffect(() => {
    document.querySelector('#draggable').style.display = 'none';
  }, []);

  // TODO: move drag logic to hook?
  const drag = (event, index, id, setHover, element, duration, order) => {
    setAllowHover(false);
    setDragging(order);

    document.body.style.cursor = 'grab';
    // element.style.scale = '1.05';
    element.style.zIndex = '1';
    element.style.pointerEvents = 'none';
    element.classList.add('dragging');

    const schedule = document.querySelector('#schedule');
    const startPositionY = event.pageY;
    const startContainer = element.closest('#pending');
    let oldTop = element.offsetTop;
    let startTop = element.offsetTop;
    const cells = document.querySelectorAll('.dataPanelCell');

    if (cells) {
      for (const cell of cells) {
        cell.classList.add('dragging');
      }
    }

    const containerHeight = element
      .closest('#pending')
      .getBoundingClientRect().height;

    let lastIndex;

    const draggableDiv = document.querySelector('#draggable');

    const onMouseMove = (e) => {
      const hoverIndex = e.target.dataset.index;

      const diffY = e.pageY - startPositionY;

      let newTop = startTop + diffY;

      const isHome =
        e.target.id === 'pending' || !!e.target.closest('#pending');

      const isCollumn =
        e.target.closest('#collumn') ||
        e.target.closest('.collumn-body')?.querySelector('#collumn');

      if (!isHome && isCollumn) {
        element.style.visibility = 'hidden';
        draggableDiv.style.display = 'block';

        draggableDiv.style.left =
          isCollumn.getBoundingClientRect().left -
          startContainer.getBoundingClientRect().left +
          'px';

        draggableDiv.style.width =
          isCollumn.getBoundingClientRect().width + 'px';

        if (duration) {
          const newHeight = getPendingOrderHeight(duration);
          draggableDiv.childNodes[0].style.height = newHeight + 'px';
        }

        const p = scrollableDiv.current.scrollTop / 60;
        const s = schedule.scrollTop % 20;
        newTop =
          Math.floor((startTop + diffY) / SCHEDULE_CELL_HEIGHT) *
            SCHEDULE_CELL_HEIGHT +
          1 -
          p -
          s +
          20;

        setHover(
          getHoverTime(
            draggableDiv.getBoundingClientRect().top -
              isCollumn.getBoundingClientRect().top,
          ),
        );

        setHoverTime(
          getHoverTime(
            draggableDiv.getBoundingClientRect().top -
              isCollumn.getBoundingClientRect().top,
          ),
        );

        if (
          newTop >= 0 &&
          newTop <=
            containerHeight - 100 - element.getBoundingClientRect().height
        ) {
          draggableDiv.style.top = newTop + 'px';
          // draggableDiv.style.scale = '1';
        }
      } else if (isHome) {
        setHover(null);

        if (newTop >= 0) {
          element.style.top = newTop + 'px';
          element.style.left = '';
          element.style.width = '';

          element.style.visibility = 'visible';
          draggableDiv.style.display = 'none';
        }
      }

      if (!hoverIndex || hoverIndex === index.toString()) return;

      const eTop = e.target.offsetTop;
      e.target.style.top = oldTop + 'px';
      oldTop = eTop;
      lastIndex = eTop / 70; // height + gap
    };

    const onMouseUp = (e) => {
      setHover(null);

      const draggableDivOffsetTop = draggableDiv.offsetTop;

      element.style.visibility = 'visible';
      draggableDiv.style.display = 'none';
      draggableDiv.style.top = '';
      draggableDiv.style.left = '';

      element.classList.remove('dragging');
      // element.style.scale = null;
      element.style.zIndex = null;
      element.style.pointerEvents = null;

      element.style.top = '';
      element.style.left = '';
      element.style.width = '';

      if (cells) {
        for (const cell of cells) {
          cell.classList.remove('dragging');
        }
      }

      const isHome =
        e.target.id === 'pending' || !!e.target.closest('#pending');

      const isColumn =
        e.target.closest('#collumn') ||
        e.target.closest('.collumn-body')?.querySelector('#collumn');

      if (!isHome && isColumn) {
        const newStartTime = getNewStartTime(
          schedule,
          isColumn,
          draggableDivOffsetTop,
        );
        updatePendingToSchedule(id, isColumn, order, newStartTime);
      } else {
        const containerElement = document.querySelector('.content').children;

        for (const value of Object.entries(containerElement)) {
          value[1].style.top = '';
          value[1].style.left = '';
        }

        if (!isNaN(lastIndex)) {
          pendingOrders.setState((prev) => {
            const myEl = prev[index];
            const newPositionEl = prev[lastIndex];

            if (newPositionEl.id === myEl.id) {
              return prev;
            }

            const arrWithout = [...prev].filter((item) => item.id !== myEl.id);
            const newPositionIndex = arrWithout.findIndex(
              (item) => item.id === newPositionEl.id,
            );

            const newArr = [];

            for (let i = 0; i <= arrWithout.length - 1; i++) {
              let pushed = false;

              if (index < lastIndex) {
                newArr.push(arrWithout[i]);
                pushed = true;
              }

              if (newPositionIndex === i) {
                newArr.push(myEl);
              }

              !pushed && newArr.push(arrWithout[i]);
            }

            return newArr;
          });
        }
      }

      document.body.style.cursor = 'auto';
      document.body.removeEventListener('mousemove', onMouseMove);
      setAllowHover(true);
    };

    document.body.addEventListener('mousemove', onMouseMove);
    document.body.addEventListener('mouseup', onMouseUp, { once: true });
  };

  const clearFilter = () => selectedProfessional.set(null);

  const sendModifiedBody = (order, updatedOrder, newEmployee, type) => {
    const modifyBody = {
      id: updatedOrder.id,
      version: updatedOrder.version,
      startTimeUtc: updatedOrder.startTimeUtc,
      employeeId: newEmployee || updatedOrder.employeeId,
      durationInMinutes: updatedOrder.durationInMinutes,
    };

    cache.setState((prev) => [
      ...prev,
      {
        type,
        old: order,
        new: { ...updatedOrder, ...modifyBody },
      },
    ]);

    modifyOrder.mutate(modifyBody);
  };

  const filteredAppointments = pendingOrdersR.orders.filter((order) =>
    appointmentsFilter.includes(order.completionStatus.toLowerCase()),
  );

  return (
    <S.PendingOrders
      scheduleHeaderSize={
        scheduleHeaderRef?.current?.getBoundingClientRect()?.height
      }
      screenHeight={screenHeight}
      dataLoaded={dataSource !== null}
      id="pending"
      dragging={draggingAppointment?.state?.moving}
      numberOfDays={numberOfDays}
    >
      {isMobile && (
        <div className="filler">
          <span className="filler__title">
            {tr['pending-orders']}
            <div
              className="filler__close"
              onClick={() => pendingOrders.setShow((prev) => !prev)}
            >
              <Ex black width={8} height={8} />
            </div>
          </span>
          {selectedProfessional?.value?.id && !hideClear && (
            <div className="clear_button">
              <Ex black className="clear" onClick={clearFilter} />
              <Avatar
                size="30px"
                imagePath={fillImgPath(selectedProfessional.value.photoUrl)}
                raised
              />
            </div>
          )}
        </div>
      )}
      <div className="content" ref={scrollableDiv}>
        {filteredAppointments.map((item, i) => (
          <PendingOrderCard
            key={i}
            id={item.id}
            data={item}
            index={i}
            drag={drag}
            allowHover={allowHover}
            width="calc(100% - 10px)"
            appointmentCardRefs={appointmentCardRefs}
          />
        ))}
      </div>
      <S.DraggableContainer
        id="draggable"
        contentScroll={scrollableDiv?.current?.scrollTop}
      >
        {dragging && (
          <PendingOrderCard
            id={dragging?.id}
            data={dragging}
            hover={hoverTime}
            isDragging={true}
          />
        )}
      </S.DraggableContainer>
      {draggingAppointment.state?.moving &&
        !draggingAppointment.state?.timeOff &&
        !draggingAppointment.state?.queue && (
          <S.DragInHere>
            <span>DROP HERE</span>
            <span>+</span>
          </S.DragInHere>
        )}
    </S.PendingOrders>
  );
};

export default PendingOrders;
