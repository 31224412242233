import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Info } from 'luxon';
import * as yup from 'yup';

// style
import * as S from './SectorsSettings.styled';

// components
import { Loader } from 'components/Loader/Loader';
import { FormProvider, Input, Select } from 'components/HookForm';
import { FormContainer } from 'components/Form/FormContainer';
import { UnsavedChangesDialogBox } from 'components/UnsavedChangesDialogBox/UnsavedChangesDialogBox';

// context
import { UserContext } from 'context/UserContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useFormStatus } from 'hooks/useFormStatus';
import { useSectorsGetAll, useSectorsUpdate } from 'services/sectorService';
import { useSystemGetCultureOptions } from 'services/systemService';

export const SectorsSettings = ({ setButtons }) => {
  const tr = useTranslate().use().global;
  const { language } = useContext(UserContext);

  // get search params
  const [searchParams] = useSearchParams();
  const sectorId = searchParams.get('sectorId'); // current sector tab

  // get sectors list sorted by employee preference
  const { employeeIds } = useContext(UserContext);
  // data load context
  const resourceContext = useSectorsGetAll({
    searchParams: { employeeIds },
  });

  const { data: localeOptions } = useSystemGetCultureOptions({
    labelFormat: ['id', 'nativeName'],
    // searchParams: { popular: true },
  });

  // data save context
  const mutationContext = useSectorsUpdate({
    id: sectorId,
    queryPrams: { invalidate: ['sectors'] },
  });

  const { data: sectors, refetch, isFetching } = resourceContext;

  const {
    mutate,
    isSuccess: isMutationSuccess,
    isError: isMutationError,
    error: mutationError,
  } = mutationContext;

  const bypassUnsavedChanges = useRef();

  const firstDayOfWeekOptions = useMemo(
    () =>
      Info.weekdays('long', {
        locale: language,
      }).reduce((acc, item, index) => {
        if ([0, 6].includes(index)) {
          return [...acc, { value: index + 1, label: item }];
        } else return acc;
      }, []),
    [language],
  );

  const dataLanguageOptions = useCallback((config) => {
    const languages = [
      { value: 'en', label: 'English' },
      { value: 'lt', label: 'Lietuvių' },
      { value: 'ru', label: 'Русский' },
    ];
    return config?.valuesOnly
      ? languages.map((language) => language.value)
      : languages;
  }, []);

  const schema = yup.object().shape({
    settings: yup.object().shape({
      culture: yup
        .string()
        .test(
          'is-culture-valid',
          `${tr['locale']} ${`${tr['is']} ${tr['invalid']}`.toLowerCase()}`,
          (value) => !!localeOptions?.find((locale) => locale.value === value),
        )
        .required(
          `${tr['locale']} ${`${tr['is']} ${tr['required/feminine']}`.toLowerCase()}`,
        ),
      dataLanguage: yup
        .mixed()
        .oneOf(
          dataLanguageOptions({ valuesOnly: true }),
          `${tr['data-language']} ${`${tr['is']} ${tr['invalid']}`.toLowerCase()}`,
        )
        .required(
          `${tr['data-language']} ${`${tr['is']} ${tr['required/feminine']} ${tr['search/dative']}`.toLowerCase()}`,
        ),
      currencySymbol: yup
        .string()
        .max(
          3,
          ({ max }) =>
            `${tr['currency']} ${`${tr['can-not-exceed']} ${max} ${tr['characters']}`.toLowerCase()}`,
        )
        .required(
          `${tr['currency']} ${`${tr['is']} ${tr['required']}`.toLowerCase()}`,
        ),
      firstDayOfWeek: yup
        .number()
        .oneOf([1, 7])
        .required(
          `[${tr['week-start']}] ${`${tr['is']} ${tr['required/feminine']}`.toLowerCase()}`,
        ),
    }),
  });

  const sector = useMemo(
    () => sectors?.find((sector) => sector.id === Number(sectorId)),
    [sectors, sectorId],
  );

  const defaultValues = useMemo(
    () => ({
      id: sector?.id,
      name: sector?.name,
      // calendarTimeFrom: sector?.calendarTimeFrom,
      // calendarTimeTill: sector?.calendarTimeTill,
      isActive: sector?.isActive,
      // isRelevant: sector?.isRelevant,
      settings: {
        culture: sector?.settings?.culture ?? '',
        dataLanguage: sector?.settings?.dataLanguage ?? '',
        currencySymbol: sector?.settings?.currencySymbol ?? '',
        firstDayOfWeek: sector?.settings?.firstDayOfWeek ?? '',
        timezoneId: sector?.settings?.timezoneId,
        // timezoneIanaId: sector?.settings?.timezoneIanaId,
        // vatList: sector?.settings?.vatList,
      },
      version: sector?.version,
    }),
    [sector],
  );

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const { isLoading } = useFormStatus({
    resourceContext,
    mutationContext,
    setButtons,
    methods,
    resetForm: () => reset(defaultValues),
    submit: handleSubmit(mutate),
    modal: false,
    formCanBeDisabled: false,
    isMobileButtonsOnlyIcons: false,
  });

  useEffect(() => {
    if (!isFetching) {
      reset(defaultValues);
    }
  }, [reset, isFetching, defaultValues]);

  useEffect(() => {
    if (
      isMutationSuccess ||
      (isMutationError && mutationError.response.status === 412)
    ) {
      refetch();
    }
  }, [isMutationSuccess, isMutationError, mutationError, refetch]);

  // log
  // useEffect(() => console.log({ sectors }), [sectors]);
  // useEffect(() => console.log({ localeOptions }), [localeOptions]);

  return (
    <S.SectorsSettings>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <FormProvider methods={methods}>
            <FormContainer>
              <div className="form">
                <div className="row">
                  <div className="input-container">
                    <Input
                      name="settings.currencySymbol"
                      label={tr['currency']}
                      required
                    />
                  </div>

                  <div className="input-container">
                    <Select
                      name="settings.dataLanguage"
                      label={`${tr['data-language']} (${tr['search/dative']})`}
                      options={dataLanguageOptions()}
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="input-container">
                    <Select
                      name="settings.culture"
                      label={`${tr['locale']} (${tr['automated-services/dative']})`}
                      options={localeOptions}
                      required
                    />
                  </div>

                  <div className="input-container">
                    <Select
                      name="settings.firstDayOfWeek"
                      label={tr['week-start']}
                      options={firstDayOfWeekOptions}
                      required
                    />
                  </div>
                </div>
              </div>
            </FormContainer>
          </FormProvider>

          <UnsavedChangesDialogBox
            condition={isDirty}
            checkIfBypass={() => bypassUnsavedChanges.current}
          />
        </>
      )}
    </S.SectorsSettings>
  );
};

export default SectorsSettings;
