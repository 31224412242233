export const isObject = (value) => {
  return value !== null && typeof value === 'object';
};

export const isEmpty = (obj) => Object.keys(obj).length === 0;

const isDateObject = (obj) => typeof obj?.getMonth === 'function';

export const areObjectsIdentical = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    if (
      isDateObject(val1) &&
      isDateObject(val2) &&
      val1.getTime() !== val2.getTime()
    ) {
      return false;
    }

    if (isObject(val1) && isObject(val2)) {
      // Recursively compare nested objects
      if (!areObjectsIdentical(val1, val2)) {
        return false;
      }
    } else if (val1 !== val2) {
      return false;
    }
  }

  return true;
};

export const getOpbjectEntryByString = (obj, string) => {
  if (!string.includes('.')) {
    return obj[string];
  }

  const indexes = string.split('.');
  const index = indexes[0];
  const remainder = string.substring(index.length + 1, string.length);

  if (remainder.includes('.')) {
    return getOpbjectEntryByString(obj[index], remainder);
  } else {
    return obj?.[index]?.[remainder];
  }
};

export const filterObjectByKeys = (objectToFilter, keys = []) =>
  Object.keys(objectToFilter)
    .filter((key) => keys.includes(key))
    .reduce((obj, key) => {
      obj[key] = objectToFilter[key];

      return obj;
    }, {});
