import { useContext, useEffect } from 'react';
import {
  NavLink,
  createSearchParams,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';

// style
import * as S from './FunctionBar.styled';

// components
import { FunctionWidget } from './FunctionWidget/FunctionWidget';

// hooks and helpers
import { useHideOnScroll } from 'hooks/useHideOnScroll';
import { useMediaQuery } from 'hooks/useMediaQuery';

// context
import { MainContext } from 'pages/Main/Main';
import { useScrollIntoView } from 'hooks/useScrollIntoView';

export const FunctionBar = ({
  tabs,
  functions,
  plusAction,
  fixed,
  fabMarginY,
  id,
  local,
  elavatedFabs,
}) => {
  // Hooks
  let [searchParams, setSearchParams] = useSearchParams();
  // console.log({ searchParams: searchParams.toString() });

  // --- global
  const { shiftBy } = useHideOnScroll(); // hide on scrolldown function
  const { headerFixed, functionBarRef, currSectorId, setCurrSectorId } =
    useContext(MainContext);

  const isMobile = useMediaQuery('(max-width: 960px)');

  const location = useLocation();

  // automatic center-adjustment of selected tab
  const { ref: activeRef, scroll } = useScrollIntoView({
    auto: true,
    inline: 'nearest',
  }); // scroll into view currently selected item

  // useEffect(() => scroll(), [activeRef, scroll]); // FIXME: causes jumpy scrolling on mobile Safari

  // set searchParams from MainContext if context of FunctionBar of current page is not local
  useEffect(() => {
    !local &&
      !!currSectorId &&
      setSearchParams({ sectorId: currSectorId }, { state: location.state });
  }, []);

  return (
    <S.FunctionBar
      shiftBy={fixed ? !fixed && shiftBy : !headerFixed.position && shiftBy}
      isVisible={tabs || (!isMobile && functions)}
      id="function-bar"
      ref={functionBarRef}
    >
      <S.TabContainer functionsPresent={!!functions}>
        {tabs && (
          <ul>
            {tabs.map((item, key) => {
              const params = createSearchParams({
                ...Object.fromEntries(new URLSearchParams(searchParams)),
                ...item.params,
              }).toString();

              return (
                <li
                  key={key}
                  {...(item?.params?.sectorId ==
                    searchParams.get('sectorId') && {
                    ref: activeRef,
                  })}
                >
                  <NavLink
                    onClick={() =>
                      !local && setCurrSectorId(item?.params?.sectorId)
                    }
                    to={{
                      // pathname: item.id?.toString() || item.route || '',
                      pathname: item.route || '',
                      ...(item.params && {
                        search: params,
                      }),
                    }}
                    className={({ isActive }) => {
                      if (
                        !local &&
                        currSectorId &&
                        item?.params?.sectorId &&
                        currSectorId === item.params.sectorId
                      ) {
                        return 'active';
                      }

                      if (item?.end) {
                        return searchParams.get('sectorId') ? '' : 'active';
                      }

                      if (!!item.params) {
                        return params === location.search.substring(1)
                          ? 'active'
                          : '';
                      } else return isActive ? 'active' : '';
                    }}
                  >
                    {item.name}
                  </NavLink>
                  <S.Active />
                </li>
              );
            })}
          </ul>
        )}
      </S.TabContainer>

      {functions && (
        <FunctionWidget
          functions={functions}
          plusAction={plusAction}
          fabMarginY={fabMarginY}
          id={id}
          elavatedFabs={elavatedFabs}
        />
      )}
    </S.FunctionBar>
  );
};

FunctionBar.propTypes = {
  tabs: PropTypes.array,
  functions: PropTypes.array,
  fixed: PropTypes.bool,
};

export default FunctionBar;
