import { createContext, useCallback, useEffect, useReducer } from 'react';
import axios from 'axios';

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const getUserPicturePath = (user) => {
    if (user?.photoUrl && !user.photoUrl.includes('http')) {
      user.photoUrl = process.env.REACT_APP_API + user.photoUrl;
    }
    return user;
  };

  const logoutFromApi = () => {
    axios
      .post(process.env.REACT_APP_API + 'auth/logout', null, {
        headers: { Authorization: 'Bearer ' + localStorage.token },
      })
      .then(() => dispatch({ type: 'logout-user' }))
      .catch((err) => console.log({ err }));
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'login-user':
        !!action.value?.token &&
          localStorage.setItem('token', action.value.token);
        return {
          ...state,
          ...(action.value?.user && {
            user: {
              ...getUserPicturePath(action.value.user),
              acl: 1, // set user access level (ACL)
              type: 2, // set user type to 'company' (2) vs 'individual' (1)
            },
          }),
          ...(action.value?.token && { token: action.value.token }),
          locale: action.value?.user?.settings.culture,
          language: action.value?.user?.settings.uiLanguage,
        };

      case 'logout-user':
        localStorage.clear();
        return {};

      case 'update-user':
        return {
          ...state,
          ...((action.value?.user || action.value?.newUser) && {
            user: {
              ...getUserPicturePath(action.value.user),
              ...action.value.newUser,
              ...(action.value.newUser && {
                suggestedCultures: action.value.suggestedCultures,
              }),
              acl: 1,
              type: 2,
            },
          }),
          ...(action.value?.token && { token: action.value.token }),
          locale:
            action.value?.user?.settings.culture ||
            action.value?.newUser?.settings.culture,
          language:
            action.value?.user?.settings.uiLanguage ||
            action.value?.newUser?.settings.uiLanguage,
          companyId: action.value?.companyId,
          employeeIds: action.value?.employeeIds,
        };

      case 'set-locale':
        return {
          ...state,
          locale: action.value,
          ...(state.user && {
            user: {
              ...state.user,
              settings: { ...state.user.settings, culture: action.value },
            },
          }),
        };

      case 'set-language':
        return {
          ...state,
          language: action.value,
          ...(state.user && {
            user: {
              ...state.user,
              settings: { ...state.user.settings, uiLanguage: action.value },
            },
          }),
        };

      case 'set-company':
        localStorage.setItem('token', action.value.updatedToken);
        localStorage.setItem('rtKey', action.value.refreshTokenKey);
        localStorage.setItem('rTime', new Date().getTime());
        return {
          ...state,
          companyId: action.value.companyId,
          token: action.value.updatedToken,
        };

      case 'new-company':
        return {
          ...state,
          isCreatingCompany: action.value,
        };

      case 'set-employees':
        return {
          ...state,
          employeeIds: action.value,
        };

      case 'set-user-fetched-from-be':
        return {
          ...state,
          userIsFetchedFromBE: action.value,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    token: localStorage.getItem('token'),
  });

  // useEffect(() => console.log({ state }), [state]);

  return (
    <UserContext.Provider
      value={{
        user: state.user,
        userAcl: state.user?.acl,
        userType: state.user?.type,
        token: state.token,
        locale:
          state.user?.settings?.culture ||
          (navigator.language.length > 2
            ? navigator.language.slice(0, 2)
            : navigator.language),
        language:
          state.user?.settings?.uiLanguage ||
          (navigator.language.length > 2
            ? navigator.language.slice(0, 2)
            : navigator.language),
        companyId: state.companyId,
        employeeIds: state.employeeIds,
        updateState: dispatch,
        loginUser: (userData) =>
          dispatch({ type: 'login-user', value: userData }),
        logoutUser: logoutFromApi,
        updateUser: useCallback(
          (userData) => dispatch({ type: 'update-user', value: userData }),
          [],
        ),
        setCompany: (companyId, updatedToken, refreshTokenKey) =>
          dispatch({
            type: 'set-company',
            value: { companyId, updatedToken, refreshTokenKey },
          }),
        setLocale: (locale) => dispatch({ type: 'set-locale', value: locale }),
        setLanguage: (language) =>
          dispatch({ type: 'set-language', value: language }),
        isCreatingCompany: state.isCreatingCompany,
        setEmployees: (employeeIds) =>
          dispatch({ type: 'set-employees', value: employeeIds }),
        isUserFetchedFromBE: state.userIsFetchedFromBE,
        setIsUserFetchedFromBE: useCallback(
          (isFetched) =>
            dispatch({ type: 'set-user-fetched-from-be', value: isFetched }),
          [],
        ),
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
