import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { FlashMessage } from 'components/FlashMessage/FlashMessage.styled';

export const SectorDataMinimal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  > label {
    color: ${colors.bg_grey_3};
    display: block;
    font-family: ${fontFamilies.family_Secondary};
    font-size: 0.875rem;
  }

  .width-free {
    min-width: revert;
  }

  .settings-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  ${FlashMessage} {
    width: fit-content;

    @media (max-width: 960px) {
      margin-inline: -10px;
    }
  }
`;

export const InputFieldGroup = styled.div`
  background-color: ${({ disabled }) => !disabled && colors.bg_grey_1};
  border: 1px solid
    ${({ disabled }) => (disabled ? colors.bg_grey_2 : 'transparent')};
  border-radius: 10px;
  display: flex;
  flex-basis: min-content;
  gap: 20px;
  padding: ${({ disabled }) => (disabled ? '20px 20px 11px' : '20px')};

  @media (max-width: 960px) {
    flex-basis: revert;
    flex-wrap: wrap;
    margin-inline: -10px;
    overflow: hidden;
    padding: ${({ disabled }) => (disabled ? '10px 0 0' : '10px')};
    width: calc(100% + 20px);
  }

  > div {
    min-width: 130px;

    @media (max-width: 960px) {
      flex: 1 0 calc(50% - 10px);
      min-width: revert;
    }
  }

  @media (max-width: 960px) {
    :first-child > *:first-child {
      flex-basis: ${({ disabled }) => !disabled && '100%'};
    }
  }
`;
