import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const SectorList = styled.div`
  font-family: ${fontFamilies.family_Secondary};
  margin-block: 0;
  overflow: auto;
  padding: 0;

  ::-webkit-scrollbar {
    display: none;
  }

  .button-container {
    padding: 8.305px 10px;
  }

  .button-container button {
    background-color: ${colors.bg_grey_1};
    justify-content: left;
    font-family: ${fontFamilies.family_Secondary};
    font-size: 0.875rem;
    font-weight: 400;
    padding-inline: 20px;
    width: fit-content;
  }
`;
