import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';

// constants
import { TOKEN_REFRESH_TIME } from 'common/constants';

// context
import { UserContext } from 'context/UserContext';

export const useRestoreAppUserCredentials = ({
  forced,
  preventRedirect,
} = {}) => {
  const rtTimer = useRef(); // refresh token timer

  if (forced) {
    preventRedirect = true;
  }

  const APP_ROOT = process.env.REACT_APP_APP_ROOT;
  const navigate = useNavigate();
  const {
    token,
    user,
    companyId,
    updateUser,
    isUserFetchedFromBE,
    setIsUserFetchedFromBE,
  } = useContext(UserContext);
  const [userLocal, setUserLocal] = useState(user);
  const [companyIdLocal, setCompanyIdLocal] = useState(companyId);
  const [isUserInitialyFetchedFromBE, setIsUserInitialyFetchedFromBE] =
    useState(isUserFetchedFromBE);

  const restoreAppUserCredentials = useCallback(
    async () =>
      await axios.get(`${process.env.REACT_APP_API}myInfo`, {
        headers: { Authorization: `Bearer ${token}` },
      }),
    [token],
  );

  const handleUpdateUser = useCallback(
    () =>
      restoreAppUserCredentials()
        .then((apiResponse) => {
          // console.log({ apiResponse });
          updateUser(apiResponse.data);
          setIsUserFetchedFromBE(true);

          // restore user
          setUserLocal(apiResponse.data.user);

          // if there's company, restore company, otherwise navigate to dashboard for company selection
          if (!preventRedirect && !apiResponse.data.companyId) {
            navigate(APP_ROOT + 'dashboard');
          } else {
            setCompanyIdLocal(apiResponse.data.companyId);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // token expired
            navigate(APP_ROOT + 'login');
          }
          console.log({ err });
        }),
    [
      APP_ROOT,
      navigate,
      restoreAppUserCredentials,
      updateUser,
      setIsUserFetchedFromBE,
      preventRedirect,
    ],
  );

  useEffect(() => {
    if (!token) {
      navigate(APP_ROOT + 'login');
      return;
    }

    if (forced && !isUserInitialyFetchedFromBE) {
      handleUpdateUser();
      setIsUserInitialyFetchedFromBE(true);
    } else if (user) {
      setIsUserFetchedFromBE(false);
    } else {
      handleUpdateUser();
    }
  }, [
    token,
    user,
    APP_ROOT,
    navigate,
    setIsUserFetchedFromBE,
    handleUpdateUser,
    isUserInitialyFetchedFromBE,
    forced,
  ]);

  const refreshToken = useCallback(
    (timestamp) =>
      setTimeout(
        () =>
          axios
            .get(process.env.REACT_APP_API + 'auth/refreshToken', {
              params: { refreshTokenKey: localStorage.getItem('rtKey') },
              headers: {
                'Content-Type': 'application/json', // change according header type accordingly
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            })
            .then((res) => {
              localStorage.setItem('token', res.data.token);
              localStorage.setItem('rtKey', res.data.refreshTokenKey);
              localStorage.setItem('rTime', new Date().getTime());

              console.log({ newToken: res.data.token });

              refreshToken(TOKEN_REFRESH_TIME);
            })
            .catch((err) => {
              switch (err.response.status) {
                case 400:
                  console.log('Invalid refreshTokenKey');
                  break;
                case 403:
                  console.log('Invalid token');
              }
              localStorage.clear();
              window.location.href = window.location.origin;
            }),
        timestamp,
      ),
    [],
  );

  useEffect(() => {
    const lastRefreshTime = localStorage.getItem('rTime');

    if (lastRefreshTime && companyId) {
      // console.log({ rtTimer: rtTimer.current });
      const now = new Date().getTime();
      const remainingTime = now - lastRefreshTime;

      clearTimeout(rtTimer.current); // stop previous timer
      rtTimer.current = refreshToken(
        remainingTime <= 0 ? TOKEN_REFRESH_TIME : remainingTime,
      );
    }
  }, [refreshToken, companyId]);

  return {
    user: userLocal,
    companyId: companyIdLocal,
  };
};
