import { useMemo } from 'react';

// style
import * as S from './SectorDataMinimal.styled';

// components
import { InputField } from 'components/InputField/InputField';
import { FlashMessage } from 'components/FlashMessage/FlashMessage';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { getListWidth } from 'common/helpers/stringOperations';

export const SectorDataMinimal = ({ state, options, disabled, isNew }) => {
  const tr = useTranslate().use().global;

  const listWidth = useMemo(
    () =>
      isNew
        ? `${getListWidth(options['timezoneId'], 'bold 16px Roboto', { offset: 56 })}px`
        : 'max-content',
    [isNew, options],
  );

  return (
    <S.SectorDataMinimal>
      <label>{tr['settings']}</label>

      <div className="settings-container">
        {'timezoneId' in state && (
          <S.InputFieldGroup disabled={disabled}>
            <InputField
              id={'timezoneId'}
              label={tr['timezone']}
              state={state?.['timezoneId']}
              options={options?.['timezoneId']}
              notClearable
              disabled={disabled || !isNew}
              required
              width={listWidth}
            />
          </S.InputFieldGroup>
        )}

        <S.InputFieldGroup disabled={disabled}>
          <InputField
            id={'isActive'}
            label={tr['activity']}
            state={state?.['isActive']}
            options={options?.['isActive']}
            notClearable
            disabled={disabled}
          />
        </S.InputFieldGroup>
      </div>

      {isNew && (
        <FlashMessage>
          {tr['timezone-cannot-be-changed-after-saving']}
        </FlashMessage>
      )}
    </S.SectorDataMinimal>
  );
};
