import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

// style
import * as S from './DailyView.styled';

// components
import { FunctionBar } from 'components/FunctionBar/FunctionBar';
import { ScheduleDay } from './ScheduleDay/ScheduleDay';
import { SearchHeader } from 'components/SearchHeader/SearchHeader';
import { LoaderDebounce } from 'components/LoaderDebounce/LoaderDebounce';

// context
import { UserContext } from 'context/UserContext';
import { MainContext } from 'pages/Main/Main';

// redux
import { setEmployees } from 'redux/actions/calendarViewActions';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useFunctionBar } from 'hooks/useFunctionBar';
import { useSearchParams } from 'react-router-dom';
import { useSectorsTabs } from 'hooks/useSectorsTabs';
import { useCalenderViews } from 'hooks/schedule/useCalenderViews';
import { datesAreOnSameDay, toIsoString } from 'common/helpers/dateOperations';
import { updateFunctionBarItem } from 'common/helpers/functionBar';
import { removeDuplicates } from 'common/helpers/arrayFunctions';

export const DailyView = ({ numberOfDays = 1, personal }) => {
  const { employeeIds } = useContext(UserContext);
  const { setSearchIconVisibility, searchWidgetVisible, setState } =
    useContext(MainContext);

  const past = 10;
  const personalDays = { total: past + 10, past };
  const dispatch = useDispatch();

  // Hooks
  const functionBarItems = useFunctionBar('calendar-day');
  const isMobile = useMediaQuery('(max-width: 960px)');
  const [searchParams, setSearchParams] = useSearchParams();

  const showSearchWidget = () => setState({ type: 'show-search-widget' });
  const hideSearchWidget = () => setState({ type: 'hide-search-widget' });

  // useEffect(() => {
  //   const cachedSearchWidgetVisibility = searchWidgetVisible;
  //   setSearchIconVisibility(true);

  //   return () => {
  //     cachedSearchWidgetVisibility ? showSearchWidget() : hideSearchWidget();
  //     setSearchIconVisibility(false);
  //   };
  // }, []);

  useEffect(() => {
    if (!personal && searchParams.has('employeeId')) {
      setSearchParams((old) => {
        const newParams = old;
        newParams.delete('employeeId');
        return newParams;
      });
    }
  }, [searchParams, setSearchParams, personal]);

  const employeeId = searchParams.get('employeeId');
  const sectorId = searchParams.get('sectorId');

  // encode 1st entry of employeeIds (received after company selection) into search params
  useEffect(() => {
    if (
      personal &&
      employeeIds &&
      !Array.from(searchParams.keys()).includes('employeeId')
    ) {
      setSearchParams((old) => ({
        ...Object.fromEntries(new URLSearchParams(old)),
        employeeId: employeeIds[0],
      }));
    }
  }, [employeeIds, searchParams, setSearchParams, personal, numberOfDays]);

  const tabs = useSectorsTabs({ employeeId });

  // -- state
  // --- local
  const [fbItems, setFbItems] = useState(
    personal
      ? functionBarItems
      : functionBarItems.filter((item) => item.id !== 'workingDays'),
  );

  const [showSearchHeader, setShowSearchHeader] = useState(false);
  const [days, setDays] = useState(null);
  const [page, setPage] = useState(1);

  const [showOnlyWorkingDays, setShowOnlyWorkingDays] = useState(false);
  const [scheduleKey, setScheduleKey] = useState(Math.random());
  const [columnFetching, setColumnFetching] = useState(true);

  const [displayProfessionals, setDisplayProfessionals] = useState({
    professionals: null,
    compare: false,
  });

  // Why useMemo???
  const searchHeader = useMemo(() => {
    return {
      compare: displayProfessionals.compare,
    };
  }, [displayProfessionals.compare]);

  // Why useMemo again???
  const pageState = useMemo(() => {
    return {
      state: page,
      setState: setPage,
    };
  }, [page]);

  // --- global
  const { employees } = useSelector((state) => state.calendarView);
  const dateState = useSelector((state) => state.scheduleReducer.dateState);

  const [dateStateCopy, setDateStateCopy] = useState(null);

  useEffect(() => {
    setDateStateCopy(new Date(dateState));
  }, []);

  // -- api
  const employeesBE = useCalenderViews({
    personal,
    sectorId,
    employeeId,
    days,
    dateState,
    past,
    page,
    personalDays,
    showOnlyWorkingDays,
    displayProfessionals,
  });

  // -- side effect;
  useEffect(() => {
    if (!days?.visible || !employeesBE.data?.employees) return;

    if (days.visible > 1) {
      if (employeeId) {
        // Personal
        if (!employeesBE.isSuccess) return;

        let newEmployees = employeesBE.data.employees;
        let hasMore = true;

        if (showOnlyWorkingDays) {
          const sameDay = datesAreOnSameDay(dateState, dateStateCopy);

          if (sameDay) {
            if (!!displayProfessionals.professionals[0]?.days) {
              const sameSector = displayProfessionals.sectorId === sectorId;

              if (sameSector) {
                newEmployees[0].days = removeDuplicates(
                  displayProfessionals.professionals[0]?.days.concat(
                    newEmployees[0].days,
                  ),
                  'day',
                );
              }
            }
          } else {
            setDateStateCopy(dateState);
          }

          const expexted = page === 1 ? past + 10 : 7;
          const got = employeesBE.data.employees[0]?.days?.length;

          hasMore = expexted === got;
        }

        setDisplayProfessionals((prev) => {
          return {
            ...prev,
            ...((!!newEmployees || page === 1) && {
              professionals: newEmployees,
            }),
            hasMore,
            date: dateState,
            sectorId,
          };
        });
      } else {
        // Week
        loadSource(page);
      }
    } else {
      if (displayProfessionals.compare) return;
      loadSource(page);
    }
    // }, [employeeId, employees]);
  }, [employeeId, page, employeesBE.data?.employees]);

  useLayoutEffect(() => {
    return () => {
      const mainHeaderElement = document.getElementById('header-nav');
      mainHeaderElement.style.visibility = 'visible';
    };
  }, []);

  useEffect(() => {
    // Questionable method
    let searchButton;

    if (isMobile) {
      searchButton = document.getElementById('header-search');
      if (!searchButton) return;

      searchButton.addEventListener('click', handleSearchHeaderToggle);
    }

    return () => {
      // if (!searchButton) return;
      searchButton?.removeEventListener('click', handleSearchHeaderToggle);
    };
  }, [isMobile]);

  useEffect(() => {
    updateFunctionBar(
      'search-by-selected-professionals',
      'action',
      handleSearchHeaderToggle,
    );
  }, []);

  useEffect(() => {
    updateFunctionBar(
      'search-by-selected-professionals',
      'isActive',
      showSearchHeader,
    );
  }, [showSearchHeader]);

  useEffect(() => {
    updateFunctionBar('workingDays', 'action', () => {
      setShowOnlyWorkingDays((prev) => !prev);
    });
  }, []);

  useEffect(() => {
    updateFunctionBar('workingDays', 'isActive', showOnlyWorkingDays);
  }, [showOnlyWorkingDays]);

  useEffect(() => {
    const header = document.getElementById('header-nav');
    if (!header) return;
    header.style.zIndex = 7;

    return () => {
      header.style.zIndex = '';
    };
  }, []);

  useEffect(() => {
    // clean up
    return () => dispatch(setEmployees(null));
  }, []);

  // Functions
  const updateFunctionBar = useCallback((id, key, value) => {
    updateFunctionBarItem(
      { state: fbItems, setState: setFbItems },
      id,
      key,
      value,
    );
  }, []);

  const handleSearchHeaderToggle = () => {
    const mainHeaderElement = document.getElementById('header-nav');

    setShowSearchHeader((prev) => {
      if (prev) {
        hideSearchWidget();
        mainHeaderElement.style.visibility = 'visible';
      } else {
        setTimeout(() => {
          mainHeaderElement.style.visibility = 'hidden';
        }, 200);
      }

      return !prev;
    });
  };

  const updateProfessionals = (value) => {
    console.log('UPDATE EMPLOYEES');
    // const filteredProfessionals = [];

    // if (!value) return;

    // value.forEach((item) => {
    //   const found = professionals.find((obj) => obj.id === item.select?.value);

    //   found && filteredProfessionals.push(found);
    // });

    // setDisplayProfessionals((prev) => {
    //   if (filteredProfessionals.length) {
    //     setDays({ visible: 1 });
    //     return {
    //       professionals: filteredProfessionals,
    //       compare: true,
    //     };
    //   } else {
    //     const days = setDaysInMonth();

    //     let displayProf;
    //     if (days > 1) {
    //       if (employeeId) {
    //         const foundEmployee = professionals.filter(
    //           (prof) => prof.id == employeeId
    //         );

    //         if (foundEmployee) displayProf = foundEmployee;
    //       } else if (prev.professionals.length < 3) {
    //         displayProf = [
    //           professionals[0],
    //           professionals[1],
    //           professionals[2],
    //         ];
    //         setPage(1);
    //       } else {
    //         displayProf = prev.professionals;
    //       }
    //     }

    //     return {
    //       professionals: days > 1 ? displayProf : professionals,
    //       compare: false,
    //     };
    //   }
    // });
  };

  const loadSource = useCallback(
    (page) => {
      if (personal) {
        setDays(() => {
          return {
            visible: personalDays.total + (page - 1) * 7,
            notInView: (page - 1) * 7,
          };
        });
      } else {
        const notInView = [];

        const hidden =
          numberOfDays === 1 ? (page - 1) * 7 : numberOfDays === 7 && page - 1;

        for (let i = 0; i < hidden; i++) {
          notInView.push({
            ...employees[0],
            notInView: true,
          });
        }

        if (employeesBE.data?.employees) {
          const addToSource = getNewEmployees(page);

          setDisplayProfessionals((prev) => {
            return {
              ...prev,
              professionals: [...notInView, ...addToSource],
              date: dateState,
              sectorId,
            };
          });
        }
      }
    },
    // [employees]
    [employees, page, employeesBE],
  );

  const getNewEmployees = (page) => {
    const employeesFromBe = employeesBE.data?.employees || [];

    if (numberOfDays === 1) {
      const start = (page - 1) * 7;
      const end = start + 14;

      return employeesFromBe.slice(start, end);
    }

    if (numberOfDays === 7) {
      return employeesFromBe.slice(page - 1, page + 2);
    }
  };

  const setDaysInMonth = useCallback(() => {
    if (!personal || !dateState) {
      setDays({ visible: numberOfDays });

      return numberOfDays;
    } else {
      setDays({ visible: personalDays.total });

      return personalDays.total;
    }
  }, [employeeId, dateState, numberOfDays]);

  // -- side effects
  useEffect(() => {
    setDaysInMonth();
  }, [setDaysInMonth]);

  useEffect(() => {
    // setDisplayProfessionals({
    //   professionals: null,
    //   compare: false,
    // });
    setScheduleKey(Math.random());
  }, [showOnlyWorkingDays]);

  useEffect(() => {
    if (days?.visible >= personalDays.total) {
      setPage(1);
      setDaysInMonth();
    }
  }, [dateState, sectorId, showOnlyWorkingDays]);

  const isLoading = employeesBE.isFetching || columnFetching;

  return (
    <S.DailyView showSearchHeader={showSearchHeader}>
      <FunctionBar tabs={tabs} functions={fbItems} fixed={showSearchHeader} />
      <div className="search_header-contaiener">
        <SearchHeader
          exitAction={handleSearchHeaderToggle}
          updateValue={updateProfessionals}
          reset={showSearchHeader}
        />
      </div>

      <section>
        <div className="schedule__wrapper">
          {days ? (
            <ScheduleDay
              key={scheduleKey + showOnlyWorkingDays}
              startHour={0}
              endHour={24}
              cellDuration={15}
              scrollToHour={8}
              defaultColumnWidth={200}
              numberOfDays={days}
              dataSource={displayProfessionals.professionals}
              updateSource={loadSource}
              updateFunctionBarItem={updateFunctionBar}
              searchHeader={searchHeader}
              page={pageState}
              personalDays={personalDays}
              limit={employees?.length}
              showOnlyWorkingDays={showOnlyWorkingDays}
              personal={personal}
              setColumnFetching={setColumnFetching}
              shouldUpdateSource={displayProfessionals?.hasMore}
              dataSourceDate={displayProfessionals?.date}
              dataSourceSector={displayProfessionals?.sectorId}
              isLoading={isLoading}
            />
          ) : (
            <LoaderDebounce />
          )}
          {isLoading && <LoaderDebounce debounce />}
        </div>
      </section>
    </S.DailyView>
  );
};

export default DailyView;
