import { useRef } from 'react';

// style
import * as S from './BaseInputTime.styled';
import { StyledInputErrorMessage } from '../InputsCommon.styled';

// components
import InputFieldTime from 'components/InputFieldTime/InputFieldTime';

export default ({
  id,
  label,
  value,
  disabled,
  onChange,
  errorMessage,
  variation = 'primary',
  className,
  ...rest
}) => {
  const ref = useRef();

  return (
    <S.BaseInputTime
      disabled={disabled}
      isInvalid={!!errorMessage}
      variation={variation}
      hasValue={!!value}
      className={className}
    >
      {!!label && <label htmlFor={id}>{label}</label>}
      <div className="input-wrapper">
        <InputFieldTime
          ref={ref}
          id={id}
          value={value}
          onChange={onChange}
          disabled={disabled}
          {...rest}
        />
      </div>
      <StyledInputErrorMessage>{errorMessage}</StyledInputErrorMessage>
    </S.BaseInputTime>
  );
};
