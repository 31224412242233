import { useState } from 'react';

// style
import * as S from './SectorList.styled';

// icons
import { TrashCan } from 'common/icons';

// components
import { Sector } from '../Sector/Sector';
import { TheButton } from 'components/Buttons/TheButton';
import { Overlay } from 'components/Overlay/Overlay';
import { DialogBox } from 'components/DialogBox/DialogBox';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useToggler } from 'hooks/useToggler';
import { toSentenceCase } from 'common/helpers/stringOperations';
import { DIALOG_BOX_TYPE } from 'common/constants';

export const SectorList = ({
  sectors,
  setSectors,
  activeItem,
  setActiveItem,
}) => {
  const tr = useTranslate().use().global;
  const type = 'sector';

  const addNewItem = () =>
    setSectors((old) => {
      const newSectors = structuredClone(old);

      newSectors.push({
        id: 'new',
        type,
        name: tr['new-' + type],
        isActive: true,
      });

      return newSectors;
    });

  const createNewItem = () => {
    console.log(`create new ${type}`);
    activeItem?.id === 'new' && setActiveItem(undefined);
    addNewItem();
    setActiveItem({ id: 'new', type, selected: true });
  };

  // delete confirmation dialog box visibility state
  const confirmDialogHandler = useToggler(false);

  // confirmation dialog box buttons
  const [confirmDeleteButtons, setConfirmDeleteButtons] = useState([
    {
      id: 'cancel',
      title: tr['cancel'],
      action: confirmDialogHandler.off,
      inverted: true,
    },
    {
      id: 'remove',
      title: toSentenceCase(tr['remove']),
      icon: <TrashCan width={16} height={16} bold />,
    },
  ]);

  return (
    <>
      <S.SectorList>
        <div className="button-container">
          <TheButton
            title={tr[`new-${type}`] + ' +'}
            secondary
            inverted
            flat
            height={'25px'}
            action={() => createNewItem(type)}
          />
        </div>

        {sectors.map((sector) => (
          <Sector
            key={sector.id}
            sector={sector}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            setConfirmDeleteButtons={setConfirmDeleteButtons}
            confirmDialogHandler={confirmDialogHandler}
          />
        ))}
      </S.SectorList>

      <Overlay
        isVisible={confirmDialogHandler.active}
        hide={confirmDialogHandler.off}
      >
        <div className="modal-wrapper confirm">
          <DialogBox
            close={confirmDialogHandler.off}
            headerText={tr['warning']}
            buttons={confirmDeleteButtons}
            type={DIALOG_BOX_TYPE.CONFIRMATION}
          >
            <>{confirmDeleteButtons[0]?.message}</>
          </DialogBox>
        </div>
      </Overlay>
    </>
  );
};
