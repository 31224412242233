import { useEffect, useState } from 'react';

// hooks
import {
  useDelete,
  useFetch,
  useFetchMulti,
  usePost,
  useProcessMessages,
} from './useApi';
import { pluralize } from 'common/helpers/stringOperations';
import { hydrateServiceData } from 'common/helpers/serviceFunctions';

export const useServicesGetCategories = (params = {}) => {
  const DB_SLICE = 'categories';

  const context = useFetch({
    endpoint: DB_SLICE,
    ...params,
    queryParams: {
      retry: true,
      refetchOnWindowFocus: false,
      ...params.queryParams,
    },
  });

  const [processedData, setProcessedData] = useState();

  if (context.isError) {
    console.log(context.error.stack);
  }

  useEffect(() => {
    context.isSuccess &&
      setProcessedData(hydrateServiceData([...context.data[DB_SLICE]]));
  }, [context.isSuccess, context.data]);

  return {
    ...context,
    isSuccess: context.isSuccess && processedData !== undefined,
    data: processedData,
  };
};

export const useServicesGetGroupById = (params = {}) => {
  const DB_SLICE = 'groups';

  const context = useFetch({
    endpoint: DB_SLICE,
    ...params,
    queryParams: { retry: true, ...params.queryParams },
  });

  const [processedData, setProcessedData] = useState();

  if (context.isError) {
    console.log(context.error.stack);
  }

  useEffect(() => {
    context.isSuccess &&
      setProcessedData(
        hydrateServiceData(context.data[DB_SLICE.replace(/.$/, '')]),
      );
  }, [context.isSuccess, context.data]);

  return {
    ...context,
    isSuccess: context.isSuccess && processedData !== undefined,
    data: processedData,
  };
};

export const useServicesGetServiceById = (params = {}) => {
  const DB_SLICE = 'services';

  const context = useFetch({
    endpoint: DB_SLICE,
    ...params,
    queryParams: {
      retry: true,
      // refetchOnWindowFocus: false,
      ...params.queryParams,
    },
  });

  const [processedData, setProcessedData] = useState();

  if (context.isError) {
    console.log(context.error.stack);
  }

  useEffect(() => {
    context.isSuccess &&
      setProcessedData(
        hydrateServiceData(context.data[DB_SLICE.replace(/.$/, '')]),
      );
  }, [context.isSuccess, context.data]);

  return {
    ...context,
    isSuccess: context.isSuccess && processedData !== undefined,
    data: processedData,
  };
};

export const useServicesGetServiceComposition = (params = {}) => {
  const DB_SLICE = `services/${params?.compositionId}/composition`;

  return useFetch({
    endpoint: DB_SLICE,
    ...params,
  });
};

export const useServicesGetServices = (params = {}) => {
  const DB_SLICE = 'services';

  const context = useFetch({
    endpoint: DB_SLICE,
    ...params,
  });

  const [processedData, setProcessedData] = useState();

  if (context.isError) {
    console.log(context.error.stack);
  }

  useEffect(() => {
    context.isSuccess &&
      setProcessedData({
        ...context.data,
        data: hydrateServiceData(context.data.services),
      });
  }, [context.isSuccess, context.data]);

  return {
    ...context,
    isSuccess: context.isSuccess && processedData !== undefined,
    data: processedData,
  };
};

export const useServicesGetServiceByIds = (arr) => {
  const DB_SLICE = 'services';

  const context = useFetchMulti(
    arr.map((el) => {
      return {
        queryParams: { retry: true, refetchOnWindowFocus: false },
        endpoint: DB_SLICE,
        ...el,
      };
    }),
  );

  if (context.isError) {
    console.log(context.error.stack);
  }

  const isLoading = context.some((query) => query.isLoading);
  const isSuccess = context.some((query) => query.isSuccess);

  return { data: context, isLoading, isSuccess };
};

export const useServicesUpdate = (type, params = {}) => {
  const processedMessages = useProcessMessages(type, 'update', params);

  const { isSuccess, ...context } = usePost({
    endpoint: pluralize(type),
    ...params,
    ...processedMessages,
  });

  return { isSuccess, ...context };
};

export const useServicesDelete = (type, params = {}) => {
  const processedMessages = useProcessMessages(type, 'delete', params);

  const { isSuccess, ...context } = useDelete({
    endpoint: pluralize(type),
    ...params,
    ...processedMessages,
  });

  return { isSuccess, ...context };
};
