import { Fragment } from 'react';
import { Navigate, Route, Routes } from 'react-router';

// style
import * as S from './Settings.styled';

// components
import { SettingsTemplate } from './SettingsTemplate';
import { Footer } from 'components/Footer/Footer';

// hooks
import { useSectorsTabs } from 'hooks/useSectorsTabs';
import { useRestoreAppUserCredentials } from 'hooks/useRestoreAppUserCredentials';

export const Settings = () => {
  const { companyId } = useRestoreAppUserCredentials();

  const tabs = useSectorsTabs();

  const settingsRouteConfig = [
    {
      id: 'system',
      route: 'system',
      subItems: [
        {
          id: 'project-details',
          route: 'project-details/*',
        },
        {
          id: 'general',
          route: 'general/*',
          subItems: [
            {
              id: 'professions',
              route: 'professions/*',
            },
          ],
        },
        {
          id: 'permissions',
          route: 'permissions/*',
        },
        {
          id: 'personal',
          route: 'personal/*',
        },
        // TODO: Get rid of this after implementing setting pages
        // {
        //   id: 'deprecated-general',
        //   route: 'deprecated-general/*',
        // },
      ],
    },
    {
      id: 'sectors',
      route: 'sectors',
      subItems: [
        {
          id: 'management',
          route: 'management/*',
        },
        {
          id: 'settings',
          route: 'settings/*',
          tabs: tabs,
        },
        {
          id: 'details',
          route: 'details/*',
        },
        {
          id: 'work-areas',
          route: 'work-areas/*',
        },
        {
          id: 'sms',
          route: 'sms/*',
          tabs: tabs,
        },
        {
          id: 'online-reservations',
          route: 'online-reservations/*',
          tabs: tabs,
        },
        // TODO: Get rid of this after implementing setting pages
        // {
        //   id: 'deprecated-sector-details',
        //   route: 'deprecated-sector-details/*',
        //   tabs: branchTabs,
        // },
        // {
        //   id: 'deprecated-calendar',
        //   route: 'deprecated-calendar/*',
        //   tabs: branchTabs,
        // },
        // {
        //   id: 'working-areas',
        //   route: 'working-areas/*',
        //   tabs: branchTabs,
        // },
      ],
    },
  ];

  return (
    companyId && (
      <S.Settings>
        <Routes>
          <Route index element={<Navigate to={settingsRouteConfig[0].id} />} />
          {settingsRouteConfig.map((item, i) => (
            <Route path={item.route} key={i}>
              <Route index element={<Navigate to={item?.subItems?.[0].id} />} />
              {item?.subItems?.map((subItem, subItemIndex) => (
                <Fragment key={'sub-item-' + subItemIndex}>
                  <Route
                    index
                    key={'sub-item-' + subItemIndex}
                    path={subItem.route}
                    element={
                      <SettingsTemplate
                        key={subItem.id}
                        funcTabs={subItem?.tabs}
                      />
                    }
                  />
                  <Route path={subItem.route}>
                    {subItem.subItems?.map(
                      (subItemSubItem, subItemSubItemIndex) => (
                        <Fragment
                          key={'sub-item-sub-item' + subItemSubItemIndex}
                        >
                          <Route
                            index
                            element={<Navigate to={subItem.subItems[0].id} />}
                          />
                          <Route
                            path={subItemSubItem.route}
                            element={
                              <SettingsTemplate
                                key={subItemSubItem.id}
                                // funcTabs={subItemSubItem?.tabs}
                              />
                            }
                          />
                        </Fragment>
                      ),
                    )}
                  </Route>
                </Fragment>
              ))}
            </Route>
          ))}
        </Routes>
        <Footer content={2} />
      </S.Settings>
    )
  );
};

export default Settings;
