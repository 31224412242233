import styled from 'styled-components/macro';

export const SectorDetails = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media screen and (max-width: 960px) {
    height: 100%;

    .footer {
      display: none;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  gap: 40px;

  @media screen and (max-width: 960px) {
    background-color: white;
    gap: 30px;
    padding: 20px;
  }
`;
