import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteAppointment } from 'redux/actions/appointmentsAction';
import { deletePendingOrder } from 'redux/actions/pendingOrdersAction';

// icons
import { Copy, Euro, PaperPlaneSVG, TrashCan } from 'common/icons';

// components
import Badge from 'components/Badge/Badge';
import DialogPresets from 'components/DialogBox/DialogPresets/DialogPresets';

// contexts
import { ScheduleContext } from 'context/ScheduleContext';

// hooks
import { toast } from 'common/helpers/toast';
import useTranslate from 'hooks/useTranslate';
import useStatusList from 'hooks/useStatusList';
import { useContextMenuItems } from 'hooks/useContextMenuItems';
import useMediaQuery from 'hooks/useMediaQuery';
import useModal from 'hooks/useModal';
import { useModifyOrderHandler } from './useModifyOrderHandler';
import {
  useOrderDelete,
  useOrderPaymentStatusPatch,
  useServicesModifyOrderStatus,
} from 'services/orderService';
import { colors } from 'common/colors/colors';
import { ORDER_COMPLETION_STATUS } from 'common/constants';
import { toSentenceCase } from 'common/helpers/stringOperations';

export const useAppointmentCard = ({
  isPending,
  appointment,
  handleClickAway,
  setDisplayDetails,
}) => {
  const { id, completionStatus, version } = appointment;
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const { isShowing, toggle } = useModal();
  const statusList = useStatusList(isPending ? 'pending-orders' : 'all');
  const dispatch = useDispatch();
  const currentStatus = useStatusList(completionStatus.toLowerCase());
  const serviceCardMenuItems = useContextMenuItems([
    isMobile && 'open-folder',
    isMobile && 'balance-status',
    isMobile && 'change-status',
    'functions',
  ]);
  const { cache } = useContext(ScheduleContext);
  const { mutate: deleteOrderMutation, isSuccess: isOrderDeletionSuccess } =
    useOrderDelete();
  const { mutate: mutatePaymentStatus } = useModifyOrderHandler({
    context: useOrderPaymentStatusPatch({
      searchParams: {
        serviceListMode: 'both',
      },
    }),
    isPending,
  });
  const { mutate: mutateOrderStatus } = useModifyOrderHandler({
    context: useServicesModifyOrderStatus(),
    isPending,
  });
  const [displayModal, setDisplayModal] = useState(null);
  const [modalSwitch, setModalSwitch] = useState('');

  const modalButtons = {
    send: [
      {
        title: tr['send'],
        icon: <PaperPlaneSVG bold color={colors.bg_Secondary} />,
        action: () => {
          console.log('sms');
        },
      },
    ],
    delete: [
      {
        title: tr['delete'],
        icon: <TrashCan bold />,
        action: () => {
          cache.setState([]);
          deleteOrderMutation({
            id,
            searchParams: {
              version,
            },
          });
        },
      },
    ],
  };

  const updatePaymentStatus = (payed) =>
    mutatePaymentStatus({
      id,
      version,
      isPaid: payed,
    });

  useEffect(() => {
    if (!modalSwitch) return;

    switch (modalSwitch) {
      case 'send-sms-link':
        setDisplayModal(
          <DialogPresets
            preset="smsLink"
            close={handleCloseAction}
            buttons={modalButtons.send}
          />,
        );
        break;
      case 'send-sms-reminder':
        setDisplayModal(
          <DialogPresets
            preset="smsReminder"
            close={handleCloseAction}
            buttons={modalButtons.send}
          />,
        );
        break;
      case 'delete-order':
        setDisplayModal(
          <DialogPresets
            preset="deleteOrder"
            close={handleCloseAction}
            buttons={modalButtons.delete}
          />,
        );
        break;
      case 'delete-timeoff':
        setDisplayModal(
          <DialogPresets
            preset="deleteTimeOff"
            close={handleCloseAction}
            buttons={modalButtons.delete}
          />,
        );
        break;
      default:
        return;
    }
  }, [modalSwitch]);

  useEffect(() => {
    if (isOrderDeletionSuccess) {
      const dispatchFunction = isPending
        ? deletePendingOrder
        : deleteAppointment;
      dispatch(dispatchFunction(id));
      setDisplayDetails(false);
    }
  }, [isOrderDeletionSuccess]);

  const handleCloseAction = () => {
    setModalSwitch(null);
    setDisplayModal(null);
    toggle();
    return;
  };

  const getBalanceActions = () => [
    {
      id: '1.1',
      title: tr['paid-for-known'],
      icon: <Badge icon={<Euro />} sm color={colors.paidStatus} />,
      action: () => updatePaymentStatus(true),
      acl: 1,
      hide: () => true,
    },
    {
      id: '2.1',
      title: tr['unpayed-for-known'],
      icon: <Badge icon={<Euro />} sm color={colors.debtStatus} />,
      action: () => updatePaymentStatus(false),
      acl: 1,
      hide: () => true,
    },
  ];

  const getStatusActions = (action) => {
    if (currentStatus.id === ORDER_COMPLETION_STATUS.CONFIRMED) {
      return [
        {
          id: '1-cancel-accomplishment',
          title: tr['cancel-accomplished'],
          statusColor: colors.ordered,
          action: () => {
            mutateOrderStatus({
              id,
              version,
              completionStatus: toSentenceCase(ORDER_COMPLETION_STATUS.ORDERED),
            });
          },
          acl: 1,
          hide: () => true,
        },
      ];
    } else {
      return statusList.map((item) => {
        return {
          id: `1-${item.id}`,
          title: item.title,
          statusColor: item.background,
          action: () => {
            const sameStatus =
              item.id.toLowerCase() === completionStatus.toLowerCase();

            if (sameStatus) return;

            mutateOrderStatus({
              id,
              version,
              completionStatus: item.id,
            });
          },
          acl: 1,
          hide: () => true,
        };
      });
    }
  };

  const getFunctionList = (action) => {
    return [
      {
        id: 'send-sms-reminder',
        title: tr['send-sms-reminder'],
        icon: <PaperPlaneSVG bold={isMobile} />,
        action: () => {
          setModal('send-sms-reminder');
          action && action();
          handleClickAway && handleClickAway();
        },
        acl: 1,
        hide: () => true,
      },
      {
        id: 'send-sms-link',
        title: tr['send-sms-link'],
        icon: <PaperPlaneSVG bold={isMobile} />,
        action: () => {
          setModal('send-sms-link');
          action && action();
          handleClickAway && handleClickAway();
        },
        acl: 1,
        hide: () => true,
      },
      {
        id: 'copy-order',
        title: tr['copy-order'],
        icon: <Copy bold={isMobile} />,
        action: () => {
          createAppointmentCopy(id);
          action && action();
          handleClickAway && handleClickAway();

          if (!isMobile) return;
          setDisplayDetails(false);
        },
        acl: 1,
        hide: () => true,
      },
      {
        id: 'delete-order',
        title: tr['delete-order'],
        icon: <TrashCan bold={isMobile} />,
        color: colors.mandyRed,
        action: () => {
          setModal('delete-order', id);
          action && action();
          handleClickAway && handleClickAway();
        },
        isDisabled:
          completionStatus ===
          toSentenceCase(ORDER_COMPLETION_STATUS.CONFIRMED),
        acl: 1,
        hide: () => true,
      },
    ];
  };

  const setModal = (modal) => {
    setModalSwitch(modal);
    toggle();
  };

  const getAppointemtFunctions = (close) => {
    return serviceCardMenuItems.map((item) => {
      switch (item.id) {
        case 'balance-status':
          return {
            ...item,
            subItems: getBalanceActions(),
          };
        case 'change-status':
          return { ...item, subItems: getStatusActions(close) };
        case 'functions':
          return {
            ...item,
            subItems: getFunctionList(close),
          };

        default:
          return item;
      }
    });
  };

  const createAppointmentCopy = (id) => {
    // TODO: update with be
    cache.setState([]);
    toast.success(tr['order-copy-created-successfully']);

    // dispatch(copyAppointment(id));
  };

  return {
    getBalanceActions,
    getStatusActions,
    getAppointemtFunctions,
    getFunctionList,
    modalToggle: toggle,
    isModalShowing: isShowing,
    modalContent: displayModal,
  };
};

export default useAppointmentCard;
