// style
import { StyledInputErrorMessage } from '../InputsCommon.styled';
import * as S from './BaseCheckbox.styled';

// icons
import { Check, Circle } from 'common/icons';

export default ({
  value,
  label,
  onClick,
  disabled,
  excludeLabel,
  icon,
  errorMessage,
  ...rest
}) => {
  const commonProps = {
    onClick: disabled ? null : onClick,
  };

  return (
    <S.BaseCheckbox disabled={disabled} excludeLabel={excludeLabel} {...rest}>
      {value ? (
        <Check inCircle black {...commonProps} />
      ) : (
        <Circle bold {...commonProps} />
      )}
      <div className="label-wrapper">
        {icon && icon}
        {label && (
          <label onClick={disabled || excludeLabel ? null : onClick}>
            {label}
          </label>
        )}
      </div>
      <StyledInputErrorMessage>{errorMessage}</StyledInputErrorMessage>
    </S.BaseCheckbox>
  );
};
