import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { FLASH_MESSAGE_TYPE } from 'common/constants';

export const FlashMessage = styled.div`
  background: ${({ type }) => {
    switch (type) {
      case FLASH_MESSAGE_TYPE.WARNING:
        return colors.warning;
      case FLASH_MESSAGE_TYPE.INFO:
      default:
        return colors.info;
    }
  }};
  border-radius: 5px;
  display: flex;
  font-family: ${fontFamilies.family_Secondary};
  font-size: 0.875rem;
  gap: 5px;
  padding: 10px;

  svg {
    flex-shrink: 0;
    height: 16px;
    width: 16px;
  }
`;
