import { createContext, useReducer } from 'react';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_EMPLOYEE':
      return action.payload;
  }

  return state;
};
export const ScheduleColumnContext = createContext();
export const ScheduleColumnContextProvider = ({ initialState, children }) => {
  const [state, dispatch] = useReducer(reducer, {
    employee: undefined,
    ...initialState,
  });

  return (
    <ScheduleColumnContext.Provider
      value={{
        state: state,
        dispatch,
      }}
    >
      {children}
    </ScheduleColumnContext.Provider>
  );
};
