import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { BaseCheckbox } from 'components/Inputs/BaseCheckbox/BaseCheckbox.styled';

export const AppointmentItemsVisibilitySettings = styled.div`
  > label {
    color: ${colors.bg_grey_3};
    display: block;
    font-size: 0.875rem;
    margin: ${(props) => (props.noLabel ? '0 10px' : '0 10px 5px')};
    white-space: nowrap;
    font-family: ${fontFamilies.family_Secondary};
  }

  .wrapper {
    display: flex;
    background-color: #fff;
    padding: 20px;

    .checkbox-list {
      display: flex;
      flex-direction: column;
      gap: 10px;

      ${BaseCheckbox} .label-wrapper label {
        font-family: ${fontFamilies.family_Secondary};
      }
    }

    .appointment-card {
      height: 70px;
      width: 170px;
      margin-left: auto;
    }
  }

  @media screen and (max-width: 960px) {
    .wrapper {
      flex-direction: column;
      gap: 20px;

      .appointment-card {
        margin-left: unset;
      }
    }
  }
`;
