import styled from 'styled-components/macro';
import { StyledRadioButtonAndCheckbox } from '../InputsCommon.styled';

export const BaseCheckbox = styled.div`
  ${StyledRadioButtonAndCheckbox}
  position: relative;

  svg {
    width: 16px;
    height: 16px;
  }
`;
