import { useReducer } from 'react';

const reducer = (state, action) => {
  switch (action.type) {
    case 'setup':
      return action.value;
    case 'cleanup':
      return [];
    default: {
      let newState = [...state];
      let item = newState?.find((button) => button.id === action.id);

      switch (action.type) {
        case 'cleanup':
          newState = [];

          break;
        case 'show':
          item.show = action.value;

          break;
        case 'add':
          newState.push(action.value);

          break;
        case 'remove':
          newState = state.filter((button) => action.id !== button.id);

          break;
        case 'replace':
          item = action.value;

          break;
        case 'update':
          Object.entries(action.value).forEach(
            (prop) => (item[prop[0]] = prop[1]),
          );

          break;
        default:
      }

      return newState;
    }
  }
};

export const useButtonsSetter = (presetButtons) => {
  const [buttons, dispatch] = useReducer(reducer, presetButtons || []);

  return { buttons, setButtons: dispatch };
};
