import { useEffect, useState } from 'react';

// hooks
import { useDelete, useFetch, usePost, useProcessMessages } from './useApi';
import { pluralize } from 'common/helpers/stringOperations';

export const useSectorsGetAll = (params = {}) => {
  const DB_SLICE = 'sectors';

  const context = useFetch({
    endpoint: DB_SLICE,
    ...params,
    queryParams: {
      retry: true,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60,
      ...params.queryParams,
    },
  });

  const [prioritizedData, setPrioritizedData] = useState();

  if (context.isError) {
    console.log(context.error.stack);
  }

  useEffect(() => {
    if (context.isSuccess) {
      setPrioritizedData([...context.data[DB_SLICE]]);
    }
  }, [context.isSuccess, context.data]);

  return {
    ...context,
    isSuccess: context.isSuccess && prioritizedData !== undefined,
    data: prioritizedData,
  };
};

export const useSectorsUpdate = (params = {}) => {
  const type = 'sector';
  const processedMessages = useProcessMessages(type, 'update', params);

  const { isSuccess, ...context } = usePost({
    endpoint: pluralize(type),
    ...params,
    ...processedMessages,
  });

  return { isSuccess, ...context };
};

export const useSectorsDelete = (params = {}) => {
  const type = 'sector';
  const processedMessages = useProcessMessages(type, 'delete', params);

  const { isSuccess, ...context } = useDelete({
    endpoint: pluralize(type),
    ...params,
    ...processedMessages,
  });

  return { isSuccess, ...context };
};