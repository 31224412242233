import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// Service
import { useSectorsGetAll } from 'services/sectorService';

// Context
import { UserContext } from 'context/UserContext';

// hooks
import useTranslate from './useTranslate';

export const useSectorsTabs = (config = {}) => {
  const { employeeId, includeAllTab, prepend, route, prevent } = config;

  const [searchParams, setSearchParams] = useSearchParams();
  const tr = useTranslate().use().global;
  const { employeeIds } = useContext(UserContext);

  const [tabs, setTabs] = useState([]);

  const sectorId = searchParams.get('sectorId');

  // -- api
  const sectorsBE = useSectorsGetAll({
    searchParams: { employeeIds: employeeId ? [employeeId] : employeeIds },
    queryParams: { enabled: !!(employeeId || employeeIds) },
  });

  useEffect(() => {
    if (sectorsBE.isSuccess) {
      const sectors = prevent
        ? []
        : sectorsBE.data.map((sector) => {
            const newItem = {
              ...sector,
              name: sector.name,
              params: { sectorId: sector.id },
              ...(route && { route }),
            };

            return newItem;
          });

      includeAllTab && sectors?.unshift({ name: tr['all'], end: true });
      prepend && sectors?.unshift(prepend);

      setTabs(sectors);
    }
  }, [sectorsBE.isSuccess, sectorsBE.data, includeAllTab, tr, route, prevent]);

  useEffect(() => {
    const sectorId = searchParams.get('sectorId');

    if (sectorId || !tabs.length) return;

    // const params = createSearchParams({
    //   ...tabs[0].params,
    //   ...(employeeId && { employeeId }),
    // }).toString();

    setSearchParams((old) => {
      // console.log(old.toString());
      return {
        ...old,
        ...tabs[0].params,
        ...(employeeId && employeeId !== 0 && { employeeId }),
      };
    });

    // navigate({ to: './', search: params }, { state: location.state });
  }, [tabs, searchParams, setSearchParams, employeeId, sectorId]);

  return tabs;
};

export default useSectorsTabs;
