import { useContext } from 'react';
import { DateTime } from 'luxon';

// icons
import { Cake, Comment, Euro, Globe, LetterNInCircle, Sms } from 'common/icons';

// components
import Badge from 'components/Badge/Badge';

// contexts
import { UserContext } from 'context/UserContext';

// hooks
import { getTimeString } from 'common/helpers/dateOperations';
import useTranslate from './useTranslate';
import { colors } from 'common/colors/colors';

export const useAppointment = () => {
  const tr = useTranslate().use().global;
  const { locale } = useContext(UserContext);

  const getDateAndTime = (startTime, endTime, timezoneIanaId, config = {}) => {
    let jsDate = new Date();

    if (startTime && timezoneIanaId) {
      jsDate = DateTime.fromISO(startTime, {
        zone: timezoneIanaId,
      }).toJSDate();
    }

    return {
      startTimeString: getTimeString(
        startTime,
        timezoneIanaId,
        config.h24 ? 'lt-LT' : locale,
        false,
      ),
      endTimeString: getTimeString(
        endTime,
        timezoneIanaId,
        config.h24 ? 'lt-LT' : locale,
        false,
      ),
      jsDate,
    };
  };

  const icons = [
    {
      id: 'debt',
      className: 'debt',
      icon: <Badge icon={<Euro />} xs color={colors.debtStatus} />,
    },
    {
      id: 'onlineRegistration',
      icon: <Globe />,
      label: tr['registered-online'],
    },
    {
      id: 'newClient',
      icon: <LetterNInCircle />,
      label: tr['new-client'],
    },
    {
      id: 'note',
      icon: <Comment />,
      label: tr['sms-generated'],
    },
    {
      id: 'sms',
      icon: <Sms />,
      label: tr['order-has-note'],
    },
    {
      id: 'birthday',
      icon: <Cake />,
      label: tr['today-birthday'],
    },
  ];

  return { icons, getDateAndTime };
};
