import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// style
import * as S from './Employees.styled';

// components
import { MainLayout } from 'layouts/MainLayout/MainLayout';
import { EmployeesTable } from './partials/EmployeesTable/EmployeesTable';
import { FunctionBarOptions } from 'components/FunctionBar/FunctionBarOptions/FunctionBarOptions';
import { FunctionBar } from 'components/FunctionBar/FunctionBar';
import { ColumnsVisibility } from 'components/FunctionBar/FunctionBarOptions/Options/Table/ColumnsVisibility/ColumnsVisibility';

// contexts
import { TableContextProvider } from 'context/TableContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useFunctionBar } from 'hooks/useFunctionBar';
import { useSectorsTabs } from 'hooks/useSectorsTabs';

export const Employees = () => {
  const tr = useTranslate().use().global;
  const tabs = useSectorsTabs({ includeAllTab: true });
  const [functionBarFunctions] = useState(useFunctionBar('employees'));
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // console.log({ searchParams: searchParams.toString() });
    if (searchParams.has('employeeId')) {
      setSearchParams((old) => {
        const newParams = old;
        newParams.delete('employeeId');
        return newParams;
      });
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    functionBarFunctions.find((func) => func.id === 'hide-column').action = (
      <FunctionBarOptions title={tr.showColumns}>
        <ColumnsVisibility />
      </FunctionBarOptions>
    );
  }, []);

  return (
    <MainLayout>
      <TableContextProvider>
        <S.Employees>
          <FunctionBar tabs={tabs} functions={functionBarFunctions} />
          <S.Container>
            <EmployeesTable />
          </S.Container>
        </S.Employees>
      </TableContextProvider>
    </MainLayout>
  );
};

export default Employees;
