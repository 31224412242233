import styled from 'styled-components/macro';

export const Main = styled.div`
  /* overflow-x: hidden; breaks hide/show of functionBar */

  .Toastify__toast-container {
    right: 10px;
    padding: unset;
    top: ${({ addToTop }) => addToTop + 10}px;
    transition: top 300ms;
  }

  .Toastify__toast--stacked:before {
    transform: none;
  }

  .Toastify__toast {
    border-radius: 10px;
  }

  .Toastify__toast--info .Toastify__close-button > svg {
    fill: var(--toastify-text-color-info);
  }

  .Toastify__toast--warning .Toastify__close-button > svg {
    fill: var(--toastify-text-color-warning);
  }

  .Toastify__toast--success .Toastify__close-button > svg {
    fill: var(--toastify-text-color-success);
  }

  .Toastify__toast--error .Toastify__close-button > svg {
    fill: var(--toastify-text-color-error);
  }
`;

export const Container = styled.div`
  padding-top: 70px;
  position: relative;
  left: ${(props) => (props.showSideBar ? '280px' : '0')};
  width: ${(props) => (props.showSideBar ? 'calc(100% - 280px)' : '100%')};
  display: flex;
  flex-direction: row;
  transition:
    left 0.3s ease-in-out,
    width 0.3s ease-in-out;

  @media (max-width: 960px) {
    position: unset;
    width: 100%;
    display: block;
    left: 0;
  }

  @media (max-width: 960px) {
    padding-top: 54px;
  }
`;
