import { useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';

// style
import * as S from './MySettings.styled';

// icons
import { Check } from 'common/icons';

// components
import { PageHeader } from 'components/PageHeader/PageHeader';
import { Footer } from 'components/Footer/Footer';
import { Avatar } from 'components/Avatar/Avatar';
import { TheButton } from 'components/Buttons/TheButton';
import { InputField } from 'components/InputField/InputField';
import { AvatarCropper } from 'components/AvatarCropper/AvatarCropper';
import { Overlay } from 'components/Overlay/Overlay';

// context
import { UserContext } from 'context/UserContext';

// hooks
import { toast } from 'common/helpers/toast';
import { useTranslate } from 'hooks/useTranslate';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useFormState } from 'hooks/useFormState';
import { useSystemGetCultureOptions } from 'services/systemService';
import { useToggler } from 'hooks/useToggler';

const MySettings = () => {
  const APP_ROOT = process.env.REACT_APP_APP_ROOT;
  const tr = {
    global: useTranslate().use().global,
    main: useTranslate().use().main,
  };
  const isMobile = useMediaQuery('(max-width: 960px)');

  const navigate = useNavigate();

  const { user, token, updateUser } = useContext(UserContext);

  const { data: locales, isSuccess: isLocalesSuccess } =
    useSystemGetCultureOptions({
      labelFormat: ['id', 'nativeName'],
      searchParams: { popular: true },
    });

  // configure required fields
  const setupFields = useCallback(() => {
    return [
      {
        id: 'firstName',
        level: 1,
        type: 'search',
        ...(user?.firstName && { default: user.firstName }),
      },
      {
        id: 'lastName',
        type: 'search',
        ...(user?.fullName && { default: user.fullName.split(' ')[1] }),
      },
      {
        id: 'email',
        type: 'select',
        ...(user?.email && { default: user.email }),
      },
      {
        id: 'locale',
        type: 'select',
        ...(user?.settings?.culture && {
          default: user.settings.culture,
        }),
        ...(isLocalesSuccess && { options: locales }),
      },
      {
        id: 'language',
        type: 'select',
        options: [
          { value: 'en', label: 'English' },
          { value: 'lt', label: 'Lietuvių' },
          { value: 'ru', label: 'Русский' },
        ],
        ...(user?.settings?.uiLanguage && {
          default: {
            value: user.settings.uiLanguage,
            label:
              user?.settings?.uiLanguage === 'lt'
                ? 'Lietuvių'
                : user.settings.uiLanguage === 'ru'
                  ? 'Русский'
                  : 'English',
          },
        }),
      },
      {
        id: 'picture',
        type: 'search',
        ...(user?.photoUrl && { default: { value: user.photoUrl } }),
      },
    ];
  }, [user, isLocalesSuccess, locales]);

  // create form state
  const { state, setValue, options, resetOptions } =
    useFormState(setupFields());

  useEffect(() => {
    isLocalesSuccess && resetOptions(setupFields());
  }, [isLocalesSuccess, resetOptions, setupFields]);

  // log
  // useEffect(() => console.log({ state }), [state]);
  // useEffect(() => console.log({ options: options?.['locale'] }), [options]);

  // picture picker
  const picturePickerStatus = useToggler();

  const saveChanges = () => {
    console.log('update user settings');
    const user = {
      firstName: state['firstName']?.value?.value,
      lastName: state['lastName']?.value?.value,
      settings: {
        culture: state['locale']?.value?.value,
        uiLanguage: state['language']?.value?.value,
      },
    };

    var fd = new FormData();
    fd.append('user', JSON.stringify(user));

    if (state['picture']?.value !== state['picture']?.default) {
      if (
        state['picture']?.value === undefined ||
        state['picture']?.value === null
      ) {
        fd.append('photo', new Blob([new ArrayBuffer(0)]), 'photo');
      } else {
        fd.append('photo', state['picture'].value.label, 'photo');
      }
    }

    axios
      .post(`${process.env.REACT_APP_API}myInfo`, fd, {
        headers: { Authorization: 'Bearer ' + token },
      })
      .then((response) => {
        if (response.status === 200) {
          updateUser(response.data);
          toast.success(tr.global['user-settings-updated']);
        }
      })
      .catch((err) => {
        console.log({ err });

        let errorMessage;

        if (err.response?.status === 401) {
          navigate(APP_ROOT + 'login');
        }

        if (err.code === 'ERR_NETWORK') {
          errorMessage = tr.global['err-network-error'];
        } else {
          errorMessage = err.response?.data?.title;
          Object.entries(err.response?.data?.errors).forEach((element) => {
            errorMessage += ' ' + element[0] + ': ' + element[1][0];
          }, errorMessage);
        }

        toast.error(errorMessage);
      });
  };

  return (
    <S.MySettings>
      <S.RootContainer>
        <PageHeader>
          <div className="control">
            <TheButton
              title={tr.global['save']}
              action={saveChanges}
              raised={isMobile}
              icon={<Check bold />}
            />
          </div>
        </PageHeader>

        <S.ScrollableContainer>
          <S.Container>
            <S.AvatarPicker>
              <div className="avatar-picker__image">
                <Avatar
                  imagePath={state?.['picture']?.value?.value}
                  raised="md"
                />
              </div>

              <div className="avatar-picker__button">
                <TheButton
                  secondary
                  raised
                  action={() => picturePickerStatus.on()}
                  title={
                    state['picture']?.value
                      ? tr.global['change-photo']
                      : tr.global['upload-photo']
                  }
                  height="25px"
                />
              </div>

              <Overlay
                isVisible={picturePickerStatus.active}
                hide={() => picturePickerStatus.off()}
              >
                {picturePickerStatus.active && (
                  <AvatarCropper
                    src={state['picture']?.value?.value}
                    close={() => picturePickerStatus.off()}
                    updatePicture={(value) => setValue('picture', value)}
                  />
                )}
              </Overlay>
            </S.AvatarPicker>

            <S.DetailedInfo>
              <form>
                <InputField
                  id={'firstName'}
                  label={tr.global['firstName']}
                  state={state?.['firstName']}
                />

                <InputField
                  id={'lastName'}
                  label={tr.global['lastName']}
                  state={state?.['lastName']}
                />

                <InputField
                  id={'locale'}
                  label={tr.global['locale']}
                  state={state?.['locale']}
                  options={options?.['locale']}
                />

                <InputField
                  id={'language'}
                  label={tr.global['language']}
                  state={state?.['language']}
                  options={options?.['language']}
                />

                <InputField
                  id={'email'}
                  label={tr.global['email']}
                  state={state?.['email']}
                  disabled
                />
              </form>

              <div>
                <button
                  type="button"
                  onClick={() => console.log('delete account')}
                >
                  Pašalinti paskyrą
                </button>
              </div>

              {/* <a href="#">Pašalinti paskyrą</a> */}
            </S.DetailedInfo>
          </S.Container>
        </S.ScrollableContainer>
      </S.RootContainer>

      {!isMobile && <Footer content={2} />}
    </S.MySettings>
  );
};

export default MySettings;
