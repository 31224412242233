import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { Avatar } from 'components/Avatar/Avatar.styled';
import { Child } from 'components/DialogLayout/DialogLayout.styled';

// MOBILE FIRST.
export const AppointmentsDetails = styled.div`
  .section {
    padding: 10px 0;
    border-bottom: 1px solid ${colors.bg_grey_1};
    font-size: 0.875rem;

    &__link {
      width: 95%;
      display: flex;
      align-items: center;
      gap: 10px;

      &__icon {
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
      }
    }

    &__functions {
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      position: relative;
    }

    &__arrow {
      height: 12px;
    }

    &__label {
      font-weight: 600;
    }

    :last-of-type {
      border-bottom: none;
    }
  }

  .display {
    &__row {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    &__column {
      display: flex;
      gap: 10px;
      flex-direction: column;
    }
  }

  ${Child} {
    background-color: white;
    padding: 10px;
    max-width: 100vw;
    font-family: ${fontFamilies.family_Secondary};
  }

  .head {
    display: flex;
    flex-direction: column;
    background-color: ${colors.bg_grey_1};
    padding: 10px;
    border-radius: 5px;
    gap: 10px;

    .full-name {
      display: flex;
      font-size: 1rem;
      font-family: ${fontFamilies.family_Primary};
      font-weight: 900;
      align-items: center;
      gap: 10px;
    }

    .phone {
      font-size: 0.875rem;
      display: flex;
      gap: 10px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .body {
    padding: 0 10px;
    line-height: 1;

    .appopintment-id {
      font-size: 1rem;
      font-weight: 700;
      padding: 20px 0;
    }

    .emoloyee-and-office {
      gap: 10px;

      .emoloyee {
        .section__label {
          align-self: flex-start;
          padding: 10px 0;
        }

        .fullname {
          align-self: flex-start;
          padding: 10px 0;
        }

        ${Avatar} {
          width: 40px;
          height: 40px;
        }
      }
    }

    .preferred-employees {
      gap: 10px;

      .avatars {
        gap: 5px;

        ${Avatar} {
          cursor: pointer;
          width: 20px;
          height: 20px;

          .initials {
            font-size: 0.75rem;
          }
        }
      }
    }

    .appointment-status {
      position: relative;
      cursor: pointer;

      .status {
        display: flex;
        align-items: center;
        width: 95%;
        gap: 10px;

        &__color {
          background-color: ${(props) => props.statusColor};
          width: 20px;
          height: 20px;
          border-radius: 50%;
          flex-shrink: 0;
          transition: background-color 300ms ease-in-out;
        }

        &__label {
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .time .display__column {
      gap: 0;
    }

    .services {
      ul {
        margin: 0;
        padding: 0px 0px 0px 13px;
        white-space: pre-wrap;
        font-size: 0.875rem;
        list-style-type: disc;
        gap: 8px;
        display: flex;
        flex-direction: column;
        line-height: 1.15;

        li span {
          margin-left: -3px;
        }
      }

      .price {
        display: block;
        font-weight: 600;
      }
    }

    .balance {
      justify-content: space-between;
      position: relative;

      .status {
        gap: 30px;

        .display__column {
          gap: 6px;
        }

        &__debt {
          font-weight: 600;
          color: ${colors.discount};
        }
      }
    }

    .note {
      flex-wrap: wrap;

      p {
        line-height: 1.15;
        margin: 0;
        padding: 0;
        white-space: pre-wrap;
      }
    }

    .additional-info {
      gap: 10px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  @media screen and (min-width: 960px) {
    .section {
      border-bottom: 1px solid ${colors.bg_grey_5};

      &__link {
        width: 95%;
        display: flex;
        align-items: center;
        gap: 5px;

        &__icon {
          margin-top: 3px;
        }
      }
    }

    .display {
      &__row {
        gap: 20px;
      }
      &__column {
        gap: 5px;
      }
    }

    .head {
      gap: 3px;

      .full-name {
        gap: 5px;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          height: 20px;
          margin-bottom: -1.6px;
        }
      }

      .phone {
        gap: 5px;
      }
    }

    .body {
      line-height: 1.3;

      .appopintment-id {
        font-family: ${fontFamilies.family_Primary};
        font-weight: 900;
        padding: 10px 0;
      }

      .appointment-status .status {
        gap: 5px;
      }

      .services ul {
        font-size: 0.75rem;
        padding: 0 15px;
        gap: 0;
        line-height: 1.3;

        li span {
          margin: 0;
        }
      }

      .balance .status {
        gap: 20px;
        .display__column {
          gap: 0;
        }
      }

      .note {
        gap: 0;

        p {
          line-height: 1.3;
        }
      }

      .additional-info {
        gap: 5px;
      }
    }
  }
`;
