import * as S from './MultiAppointmentsCard.styled';

import useStatusList from 'hooks/useStatusList';

// Components
import AppointmentCard from '../AppointmentCard';
import { useEffect, useState } from 'react';

const MultiAppointmentsCard = ({
  id,
  order,
  displayData,
  hoverDisplay,
  onClick,
}) => {
  const statusObj = useStatusList(displayData.status.toLowerCase());

  // const [splitedServices, setSplitedServices] = useState([]);

  const start = new Date(order.startTimeUtc);
  const end = new Date(
    new Date(start).getTime() + order.durationInMinutes * 60000,
  );

  // const totalDuration = end.getTime() - start.getTime();
  const totalDuration = Math.floor((end.getTime() - start.getTime()) / 60000);

  // useEffect(() => {
  //   const result = [];
  //   let currentGroup = [];
  //   let starTime = new Date(start);

  //   for (const element of displayData.services) {
  //     if (element.serviceId === 1) {
  //       if (currentGroup.length > 0) {
  //         currentGroup.push({ ...element, starTime });
  //         starTime = new Date(starTime);

  //         starTime.setHours(
  //           starTime.getHours(),
  //           starTime.getMinutes() + element.totalDurationInMinutes
  //         );

  //         result.push(currentGroup);
  //         currentGroup = [];
  //       }
  //     } else {
  //       currentGroup.push({ ...element, starTime });
  //       starTime = new Date(starTime);

  //       starTime.setHours(
  //         starTime.getHours(),
  //         starTime.getMinutes() + element.totalDurationInMinutes
  //       );
  //     }
  //   }

  //   if (currentGroup.length > 0) {
  //     result.push(currentGroup);
  //   }

  //   setSplitedServices(result);
  // }, [displayData.services]);

  const getSplitedServicesData = (arr) => {
    const combinedDuration = arr.reduce((acc, curr) => {
      return curr.serviceId === 1 ? acc : acc + curr.totalDurationInMinutes;
    }, 0);

    return {
      services: arr,
      startDate: arr[0].starTime,
      durationInMinutes: combinedDuration,
    };
  };

  const durationDiff = (duration) => (duration / totalDuration) * 100;

  const getTopPosition = (index, objStart) => {
    const prevOrder = displayData.services[index - 1];

    if (!prevOrder) return 0;

    const diffBetweenStart = Math.floor(
      (new Date(objStart).getTime() - start.getTime()) / 60000,
    );

    return (diffBetweenStart / totalDuration) * 100;
  };

  const generateDisplaySettings = (arr) => {
    const newArr = [];

    arr.forEach((id) => {
      newArr.push({ id, value: false });
    });

    return newArr;
  };

  const getDisplaySettings = (index) => {
    const first = index === 0;
    const last = index === displayData.splits.length - 1;

    if (first) {
      return generateDisplaySettings([
        'newClient',
        'sms',
        'birthday',
        'note',
        'onlineRegistration',
        'debt',
      ]);
    } else if (last) {
      return generateDisplaySettings(['name', 'lastName', 'phone']);
    } else {
      return generateDisplaySettings([
        'name',
        'lastName',
        'phone',
        'newClient',
        'sms',
        'birthday',
        'note',
        'onlineRegistration',
      ]);
    }
  };

  // TODO: use splited services for looping

  return (
    <S.MultiAppointmentsCard
      color={statusObj?.background}
      draggable={hoverDisplay}
    >
      {/* {displayData.services.map((obj, i) => { */}
      {/* {splitedServices.map((obj, i) => { */}
      {displayData.splits.map((obj, i) => {
        // const ordersData = getSplitedServicesData(obj);
        const splitDisplayData = { ...displayData };
        splitDisplayData.services = obj.services;

        return (
          <S.ServiceWrapper
            key={i}
            topPosition={getTopPosition(i, obj.startTimeUtc)}
            height={durationDiff(obj.durationInMinutes)}
            // height={getHeightPercentage(0, ordersData.durationInMinutes)}
          >
            <AppointmentCard
              id={id}
              onClick={onClick}
              hoverDisplay={hoverDisplay}
              displayData={splitDisplayData}
              displaySettings={getDisplaySettings(i)}
            />
          </S.ServiceWrapper>
        );
      })}
    </S.MultiAppointmentsCard>
  );
};

export default MultiAppointmentsCard;
