import { colors } from 'common/colors/colors';
import styled from 'styled-components/macro';

export const InputFieldTime = styled.div`
  width: max-content;

  .react-time-picker {
  }

  .react-time-picker--disabled {
    color: ${({ hasValue }) =>
      hasValue ? colors.bg_grey_3 : colors.bg_grey_2};
    background-color: transparent;
  }

  .react-time-picker__wrapper {
    border: none;
  }

  .react-time-picker__inputGroup {
    min-width: max-content;
    padding-inline: 0;
  }

  .react-time-picker__inputGroup__input {
    color: ${({ isSelected }) => isSelected && colors.text_Secondary};
    /* padding: 0; */

    ::-webkit-date-and-time-value {
      margin: 0; // By default Android renders this as 1px 24px 1px 1px
    }

    :focus {
      outline: none;
    }

    ::selection {
      background-color: ${colors.bg_grey_2};
    }

    ::placeholder {
      color: ${({ isSelected }) =>
        isSelected ? colors.bg_grey_3 : colors.bg_grey_2};
    }

    :disabled {
      color: ${({ hasValue }) =>
        hasValue ? colors.bg_grey_3 : colors.bg_grey_2};
    }

    :disabled::placeholder {
      color: ${colors.bg_grey_2};
    }
  }

  .react-time-picker__inputGroup__divider {
    margin-inline: 0;
  }

  .react-time-picker--disabled .react-time-picker__inputGroup__divider {
    color: ${({ hasValue }) =>
      hasValue ? colors.bg_grey_3 : colors.bg_grey_2};
  }

  .react-time-picker__inputGroup__input {
    :focus {
      background-color: ${colors.bg_grey_2};
    }
  }

  .react-time-picker
    .react-time-picker__wrapper
    .react-time-picker__inputGroup {
    .react-time-picker__inputGroup__leadingZero,
    input[name='minute'] {
      color: ${({ disableMinutes }) =>
        disableMinutes ? colors.bg_grey_3 : colors.text_Secondary};
      user-select: none;
    }
  }

  .react-time-picker--disabled .react-time-picker__inputGroup__leadingZero {
    color: ${({ hasValue }) =>
      hasValue ? colors.bg_grey_3 : colors.bg_grey_2};
  }

  .react-time-picker__inputGroup__amPm {
    appearance: none;

    :focus {
      background-color: ${colors.bg_grey_2};
    }
  }

  .react-time-picker__inputGroup__amPm:has(option[value='']) {
    color: ${({ hasValue, isSelected }) =>
      hasValue
        ? colors.text_Secondary
        : isSelected
          ? colors.bg_grey_3
          : colors.bg_grey_2};
  }

  .react-time-picker__inputGroup__amPm:has(option[value='']):disabled {
    color: ${colors.bg_grey_2};
  }
`;
