import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const PendingOrderCard = styled.div`
  min-height: 60px;
  background-color: ${({ color }) => (color ? color : colors.ordered)};
  border-radius: 3px;
  padding: 3px;

  position: absolute;

  color: ${colors.text_Secondary};
  font-family: ${fontFamilies.family_Secondary};

  /* width: ${(props) => (props.fullWidth ? '159px' : 'calc(100% - 10px)')}; */
  width: ${(props) => (props.width ? props.width : '100%')};

  top: ${({ top }) => top}px;
  display: flex;
  justify-content: space-between;
  border: 1px solid
    ${(props) => (props.selected ? colors.button_Secondary : 'transparent')};

  &.dragging {
    border: 1px solid ${colors.button_Secondary};
  }

  &:first-child {
    top: ${(props) => props.index * 60}px;
  }

  .pendingOrder_info {
    pointer-events: none;

    font-size: 0.688rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 80%;

    .pendingOrder_info-name {
      pointer-events: none;

      font-size: 0.75rem;
      font-weight: bold;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .pendingOrder_info-duration {
      border-left: 1px solid ${colors.bg_Primary};
      padding-left: 3px;

      display: ${({ height }) => height < 40 && 'none'};
    }

    .pendingOrder_info-timeStamp {
    }
  }

  .pendingOrder_professionals {
    flex-direction: column;
    gap: 3px;
    display: ${({ height }) => (height < 40 ? 'none' : 'flex')};
    z-index: 2;
    cursor: pointer;

    .pendingOrder_professionals-head {
      width: 25px;
      height: 25px;
      border-radius: 50%;

      .initials {
        font-size: 0.75rem;
      }

      /* background: rgb(34, 44, 195);
      background: linear-gradient(
        148deg,
        rgba(34, 44, 195, 1) 0%,
        rgba(253, 165, 45, 1) 100%
      ); */

      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .hover-time {
    position: absolute;
    top: 0;
    right: calc(100% + 1px);

    span {
      height: 19px;
      background-color: white;
      display: flex;
      align-items: center;
      padding: 0 7px;
      padding-bottom: 2px;
      max-width: 40px;
      display: flex;
      justify-content: center;
      border-radius: 100px;
      font-size: 0.75rem;
      color: ${colors.bg_Primary};
      border: 1px solid ${colors.bg_Primary};
      font-weight: 900;
      font-family: ${fontFamilies.family_Primary};
    }
  }
`;

export const Tooltip = styled.div`
  width: 290px;
`;
