import { useCallback, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslate } from 'hooks/useTranslate';

// style
import * as S from './ServiceList.styled';

// components
import { DebouncedSearch } from 'components/DebouncedSearch/DebouncedSearch';
import { Service } from 'components/Cards/Services/CategoryTree/Service/Service';
import { Overlay } from 'components/Overlay/Overlay';
import { DialogBox } from 'components/DialogBox/DialogBox';
import { ExplodeComposition } from 'components/ExplodeComposition/ExplodeComposition';
import { SetListItem } from '../SetList/SetListItem/SetListItem';
import { CategoryTree } from 'components/Cards/Services/CategoryTree/CategoryTree';

// context
import { ServiceSelectContext } from 'context/ServiceSelectContext';

// hooks
import { toast } from 'common/helpers/toast';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useModal } from 'hooks/useModal';
import {
  useServicesGetCategories,
  useServicesGetServices,
} from 'services/serviceService';

export const ServiceList = ({
  selectedServices,
  searchParams,
  searchQuery,
  queryId,
  handleAdd,
  toastTextOnAdd,
  autoExpandCategories,
  includeUngroupedCategory = true,
}) => {
  const [urlSearchParams] = useSearchParams();
  const sectorId = urlSearchParams.get('sectorId');

  const categoriesSearchParams = {
    serviceActivity: true,
    ...(sectorId && { sectorId }),
    ...searchParams,
  };
  const { showServiceAmountSelectionModal } = useContext(ServiceSelectContext);

  // Hooks
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const { isShowing, toggle } = useModal();

  // -- state
  const [categories, setCategories] = useState([]);
  const [foundServices, setFoundServices] = useState([]);

  const [selectedComp, setSelectedComp] = useState(null);

  const [notificationList, setNotificationList] = useState([]);

  const [selectedService, setSelectedService] = useState(null);

  // -- api
  const categoriesBE = useServicesGetCategories({
    searchParams: categoriesSearchParams,
  });

  const searchServicesBE = useServicesGetServices({
    queryParams: {
      enabled: !!searchQuery,
      retry: true,
      refetchOnWindowFocus: false,
    },
    searchParams: {
      searchPhrase: searchQuery,
      ...searchParams,
    },
  });

  // functions
  const convertData = useCallback((data) => {
    if (data instanceof Array) {
      for (let i = 0; i < data.length; i++) {
        convertData(data[i]);
      }
    } else if (data instanceof Object) {
      data = setItemType(data);

      for (let prop in data) {
        if (
          !['assignedToSectorIds', 'sectors'].includes(prop) &&
          (data[prop] instanceof Object || data[prop] instanceof Array)
        ) {
          convertData(data[prop]);
        }
      }
    }
    return data;
  }, []);

  const setItemType = (data) => {
    if ('baseDurationInMinutes' in data || 'unitId' in data) {
      data.type = 'service';
    } else if ('groupId' in data) data.type = 'subgroup';
    else if ('categoryId' in data) data.type = 'group';
    else data.type = 'category';

    if (data.hasComp) data.type = 'set';

    return data;
  };

  const incrementServicesListItem = (service) => {
    const found = selectedServices.state.find((item) => item.id === service.id);

    if (found) {
      isMobile && addNotification();

      selectedServices.setState((prev) => {
        return prev.map((item) => {
          if (item.id !== service.id) return item;

          return {
            ...item,
            compServiceCount: +(item.compServiceCount + 1).toFixed(3),
          };
        });
      });

      setSelectedComp(null);
    } else {
      service.compServiceCount = 1;

      isMobile && addNotification();

      if (
        service.hasComp &&
        service.id !== selectedComp?.id &&
        service.type !== 'set'
      ) {
        setSelectedComp(service);
      } else {
        selectedServices.setState((prev) => [...prev, service]);

        setSelectedComp(null);
      }
    }
  };

  const saveToSelected = (service, showNotification = true) => {
    const found = selectedServices.state.find((item) => item.id === service.id);

    if (found) {
      isMobile && showNotification && addNotification();

      selectedServices.setState((prev) => {
        return prev.map((item) => {
          if (item.id !== service.id) return item;
          return { ...item, compServiceCount: service.compServiceCount };
        });
      });

      setSelectedComp(null);
    } else {
      isMobile && showNotification && addNotification();
      selectedServices.setState((prev) => [...prev, service]);
      setSelectedComp(null);
    }
  };

  const notificationObj = {
    background: 'success',
    mainText: toastTextOnAdd || tr['extended'],
    closeAction: () => setNotificationList([]),
    selfDestructive: true,
  };

  const addNotification = () => toast.success(notificationObj.mainText);

  const selectService = (service, showNotification = true) => {
    let foundService = selectedServices.state.find(
      (item) => item.id === service.id,
    );

    if (!foundService) {
      foundService = {
        ...service,
        compServiceCount: service.compServiceCount || 1,
      };
    }

    if (!selectedComp && service.hasComp && selectedComp?.id !== service.id) {
      setSelectedComp(service);
    } else {
      if (showServiceAmountSelectionModal) {
        setSelectedService(foundService);
      } else {
        saveToSelected(foundService, showNotification);
      }

      if (showServiceAmountSelectionModal) {
        toggle();
      }
    }
  };

  // -- side effects
  useEffect(() => {
    categoriesBE.isSuccess &&
      setCategories(
        convertData(
          includeUngroupedCategory
            ? categoriesBE.data
            : categoriesBE.data.filter((category) => category.id !== 0),
        ),
      );
  }, [categoriesBE.isSuccess, categoriesBE.data, convertData]);

  useEffect(() => {
    if (searchServicesBE.isSuccess) {
      setFoundServices(searchServicesBE.data.services);
    }
  }, [searchServicesBE]);

  return (
    <>
      <S.ServiceList>
        {!isMobile && (
          <>
            <div className="list__header">
              <h2 className="list__title">{tr['services']}</h2>
              <DebouncedSearch
                placeholder={tr.serviceSearch}
                darkTheme
                queryId={queryId}
              />
            </div>
          </>
        )}
        <div className="list__body">
          {searchQuery ? (
            foundServices?.map((service) => (
              <Service
                key={service.id}
                service={service}
                serviceAction={(item) => {
                  isMobile
                    ? selectService(item)
                    : incrementServicesListItem(item);

                  handleAdd && !item?.hasComp && handleAdd(item);
                }}
                noIndent
                search
              />
            )) || <div className="list__body-empty">{tr['empty']}</div>
          ) : categories.length ? (
            <CategoryTree
              categories={categories}
              setCategories={setCategories}
              convertData={convertData}
              addValueToObject={() => {}}
              serviceAction={(item) => {
                if (item.id !== 'new') {
                  isMobile
                    ? selectService(item)
                    : incrementServicesListItem(item);

                  handleAdd && !item?.hasComp && handleAdd(item);
                } else {
                  toast.error(tr['composition-cannot-include-itself']);
                }
              }}
              searchParams={categoriesSearchParams}
              autoExpandCategories={autoExpandCategories}
            />
          ) : categoriesBE.isLoading ? (
            <div className="list__body-empty">{tr['loading']}</div>
          ) : (
            <div className="list__body-empty">{tr['empty']}</div>
          )}
        </div>
      </S.ServiceList>

      {isMobile && (
        <Overlay isVisible={isShowing && !!selectedService} hide={toggle}>
          <div className="modal-wrapper">
            <DialogBox
              close={toggle}
              headerText={tr['add'] + ' ' + tr['service/accusative']}
              buttons={[
                {
                  title: tr['save'],
                  action: () => saveToSelected(selectedService),
                },
              ]}
            >
              <S.ServiceModal>
                {selectedService && (
                  <SetListItem
                    id={selectedService.id}
                    name={selectedService.name}
                    durationInMinutes={selectedService.baseDurationInMinutes}
                    price={selectedService.avgUnitPriceWithVat}
                    count={selectedService.compServiceCount}
                    updateQuantity={(id, count) => {
                      setSelectedService((prev) => {
                        return { ...prev, compServiceCount: +count };
                      });
                    }}
                    hasComp={selectedService.hasComp}
                    {...(selectedService.unitId !== undefined &&
                      selectedService.unitVolume !== undefined && {
                        unit: {
                          id: selectedService.unitId,
                          volume: selectedService.unitVolume,
                        },
                      })}
                  />
                )}
              </S.ServiceModal>
            </DialogBox>
          </div>
        </Overlay>
      )}

      <ExplodeComposition
        selectedComp={selectedComp}
        add={(item) => {
          const addFunction = (service, showNotification = true) => {
            if (isMobile) {
              selectService(service, showNotification);
            } else {
              incrementServicesListItem(service);
            }
          };

          if (item.toSplit && item.composition.length) {
            item.composition.map((service, index) =>
              addFunction(service, index === 0),
            );
          } else {
            addFunction(item);
          }

          handleAdd && handleAdd(item);
        }}
      />
    </>
  );
};

export default ServiceList;
