import { useFormContext } from 'react-hook-form';

// style
import * as S from './AppointmentItemsVisibilitySettings.styled';

// icons
import { Cake, Comment, LetterNInCircle, Sms } from 'common/icons';

// components
import { Checkbox } from 'components/HookForm';
import AppointmentCard from 'components/AppointmentCard/AppointmentCard';

// hooks
import useTranslate from 'hooks/useTranslate';

export const AppointmentItemsVisibilitySettings = ({
  postDataPrefix = 'pc',
}) => {
  const tr = useTranslate().use().global;
  const { watch } = useFormContext();

  return (
    <S.AppointmentItemsVisibilitySettings>
      <label>{tr['calendar-order-information']}</label>
      <div className="wrapper">
        <div className="checkbox-list">
          <Checkbox
            name={`${postDataPrefix}.calendar.showCustomerName`}
            label={tr['client-name/accusative']}
          />
          <Checkbox
            name={`${postDataPrefix}.calendar.showCustomerLastName`}
            label={tr['client-last-name/accusative']}
          />
          <Checkbox
            name={`${postDataPrefix}.calendar.showCustomerPhone`}
            label={tr['phone2']}
          />
          <Checkbox
            name={`${postDataPrefix}.calendar.showServices`}
            label={tr['service-name/accusative']}
          />
          <Checkbox
            name={`${postDataPrefix}.calendar.showIconNewCustomer`}
            label={tr['new-client']}
            icon={<LetterNInCircle />}
          />
          <Checkbox
            name={`${postDataPrefix}.calendar.showIconSms`}
            label={tr['generating-sms']}
            icon={<Sms />}
          />
          <Checkbox
            name={`${postDataPrefix}.calendar.showIconNote`}
            label={tr['order-has-note']}
            icon={<Comment />}
          />
          <Checkbox
            name={`${postDataPrefix}.calendar.showIconBirthday`}
            label={tr['today-birthday']}
            icon={<Cake />}
          />
        </div>
        <div className="appointment-card">
          <AppointmentCard
            displayData={{
              services: [
                {
                  serviceName: tr['service-name'] + ' 1',
                },
                {
                  serviceName: tr['service-name'] + ' 2',
                },
              ],
            }}
            displaySettings={[
              {
                id: 'name',
                value: watch(`${postDataPrefix}.calendar.showCustomerName`),
              },
              {
                id: 'lastName',
                value: watch(`${postDataPrefix}.calendar.showCustomerLastName`),
              },
              {
                id: 'phone',
                value: watch(`${postDataPrefix}.calendar.showCustomerPhone`),
              },
              {
                id: 'serviceName',
                value: watch(`${postDataPrefix}.calendar.showServices`),
              },
              {
                id: 'newClient',
                value: watch(`${postDataPrefix}.calendar.showIconNewCustomer`),
              },
              {
                id: 'sms',
                value: watch(`${postDataPrefix}.calendar.showIconSms`),
              },
              {
                id: 'note',
                value: watch(`${postDataPrefix}.calendar.showIconNote`),
              },
              {
                id: 'birthday',
                value: watch(`${postDataPrefix}.calendar.showIconBirthday`),
              },
            ]}
          />
        </div>
      </div>
    </S.AppointmentItemsVisibilitySettings>
  );
};
