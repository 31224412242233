import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

// style
import * as S from './AuthProviders.styled';

// images
import GoogleLogo from 'common/images/GoogleLogo';

// context
import { UserContext } from 'context/UserContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { toSentenceCase } from 'common/helpers/stringOperations';
export const getAppUserCredentials = async (key, companyId) => {
  if (companyId) {
    return await axios.get(
      `${process.env.REACT_APP_API}auth/login?companyId=${companyId}`,
      { headers: { Authorization: 'Bearer ' + key } },
    );
  } else {
    const requestBody = new URLSearchParams();
    requestBody.append('code', key.code);

    return axios.post(`${process.env.REACT_APP_API}auth/google`, requestBody, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XmlHttpRequest',
      },
    });
  }
};

export const AuthProviders = ({ setStage }) => {
  const APP_ROOT = process.env.REACT_APP_APP_ROOT;
  const tr = useTranslate().use().global;
  const navigate = useNavigate();

  const { loginUser, updateUser } = useContext(UserContext);

  const login = useGoogleLogin({
    // auth flow issues detailed at https://github.com/MomenSherif/react-oauth/issues/12
    // focus specifically on https://github.com/MomenSherif/react-oauth/issues/12#issuecomment-1131408898

    onSuccess: (response) => {
      // console.log({ response });

      getAppUserCredentials(response)
        .then((apiUserResponse) => {
          // console.log({ apiUserResponse });

          if (apiUserResponse.status === 200) loginUser(apiUserResponse.data);
          navigate(APP_ROOT + 'dashboard');
        })
        .catch((err) => {
          console.log(err);

          // TODO: if error code 404, start Register flow:
          if (err.response.status === 404) {
            updateUser(err.response.data);
            setStage('select-lng-auto');
          }
        });
    },

    onError: (error) => console.log('Login Failed:', error),
    // flow: 'implicit' // implicit is the default
    flow: 'auth-code',
    ux_mode: 'popup',
  });

  return (
    <S.AuthProviders>
      <button type="button" onClick={login}>
        <GoogleLogo />
        <span>{toSentenceCase(tr['sign-in-with'] + ' Google')}</span>
      </button>
    </S.AuthProviders>
  );
};
