import { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';

// style
import * as S from '../CategoryTree.styled';
import { colors } from 'common/colors/colors';

// icons
import { Arrow, TrashCan, VerticalEllipsis } from 'common/icons';

// components
import { ServiceItemButton } from '../../ServiceItemButton/ServiceItemButton';
import { Subgroup } from '../Subgroup/Subgroup';
import { Service } from '../Service/Service';

// contexts
import { MainContext } from 'pages/Main/Main';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useScrollIntoView } from 'hooks/useScrollIntoView';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useOnHoverContextMenu } from 'hooks/useOnHoverContextMenu';
import {
  useServicesDelete,
  useServicesGetGroupById,
} from 'services/serviceService';
import {
  emptyStatus,
  getParent,
  isCurrent,
  isNew,
  isParentEmpty,
  removeObjectById,
  updateTree,
} from 'common/helpers/serviceFunctions';

export const Group = ({
  group,
  setCategories,
  parentInactive,
  activeItem,
  setActiveItem,
  serviceAction,
  groupAction,
  searchParams,
  categoryName,
  setConfirmDeleteButtons,
  confirmDialogHandler,
  parentIsOpenHandler,
  sectors,
  averagePrices,
}) => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const { sectors: sectorsFromContext } = useContext(MainContext);
  const [isOpen, setIsOpen] = useState(); // initally undefined for no icon opening animation
  const isSpecialItem = (context) => context.id === 0;

  // handler for context menu, called on hover
  const {
    isHovered,
    setIsHovered,
    setIsContextMenuOpen,
    showContextMenuIcon,
    debounceHideContextMenuIcon,
  } = useOnHoverContextMenu();

  if (!sectors) {
    sectors = sectorsFromContext;
  }

  // data get handler
  const { data, isSuccess, refetch } = useServicesGetGroupById({
    id: group.id,
    queryParams: { enabled: false },
    searchParams: { includeEmpty: true, ...searchParams },
    ...(serviceAction && { customParams: { servicePicker: true } }),
  });

  // data delete handler
  const { mutate, isSuccess: isDeletionSuccess } = useServicesDelete('group');

  // delete active item from CategoryTree in case of successful removal from B/E
  useEffect(() => {
    if (isDeletionSuccess) {
      // remove current object
      removeObjectById(group.id, group.type, setCategories, setActiveItem);

      // update isEmpty/isOpen status
      setCategories((oldCategories) => {
        const newCategories = [...oldCategories];
        const parent = getParent(group, newCategories);

        if (isParentEmpty(parent)) {
          // update isEmpty status of parent
          parent.isEmpty = true;
          // update isOpen status of parent
          parentIsOpenHandler && parentIsOpenHandler(false);
        }

        return newCategories;
      });
    }
  }, [
    isDeletionSuccess,
    group.id,
    group.type,
    setActiveItem,
    setCategories,
    group,
    parentIsOpenHandler,
  ]);

  // insert group into categories (for Breadcrumbs)
  useEffect(() => {
    isSuccess &&
      updateTree &&
      updateTree(data?.id, 'group', data, null, setCategories);
  }, [isSuccess, data, setCategories]);

  const openGroupBranch = (e) => {
    e?.stopPropagation();
    if (isNew(activeItem) && isCurrent(group, activeItem)) return;

    if (!isOpen && !(group.subroups || group.services)) {
      console.log('fetching group contents...');
      refetch();
    }

    setIsOpen((old) => !old);
  };

  const isInactive = (context) =>
    (parentInactive || !context.isActive) && 'inactive';

  const getFunctionList = (action) => {
    let item = group;

    const setNewActiveItem = (context) =>
      setActiveItem &&
      setActiveItem({ id: 'new', type: context, selected: true });

    const addNewItemToServiceTree = (context) =>
      updateTree(
        item.id,
        item.type,
        {
          ...{
            id: 'new',
            type: context,
            name: tr['new-' + context],
            [item.type + 'Id']: item.id,
            isActive: true,
            ...(context === 'subgroup'
              ? {
                  isEmpty: true,
                  assignedToSectorIds: [...group.assignedToSectorIds],
                }
              : {
                  baseDurationInMinutes: 0,
                  sectors: sectors.map((sector) => {
                    return {
                      sectorId: sector.id,
                      isAssigned: group.assignedToSectorIds.includes(sector.id),
                      unitPriceWithVat: null,
                      vatPercent: null,
                    };
                  }),
                  ...(context === 'set' && { hasComp: true }),
                }),
          },
        },
        item,
        setCategories,
      );

    const createNewItem = async (context) => {
      console.log('create new ' + context);

      const doRest = () => {
        setIsHovered(false);
        if (activeItem?.id === 'new') setActiveItem(undefined);
        addNewItemToServiceTree(context);
        setNewActiveItem(context);

        action && action();
      };

      if (!isOpen) {
        refetch().then(({ data }) => {
          item = { ...item, ...data.group };
          setIsOpen((old) => !old);

          doRest();
        });
      } else {
        doRest();
      }
    };

    return [
      ...(isSpecialItem(item)
        ? []
        : [
            {
              id: 'new-subgroup',
              title: tr['new-subgroup'],
              action: () => createNewItem('subgroup'),
            },
          ]),
      {
        id: 'new-service',
        title: tr['new-service'],
        action: () => createNewItem('service'),
      },
      {
        id: 'new-set',
        title: tr['new-set'],
        action: () => createNewItem('set'),
      },
      ...(isSpecialItem(item)
        ? []
        : [
            {
              id: 'delete',
              title: tr['delete'],
              icon: <TrashCan bold />,
              color: 'red',
              action: () => {
                console.log('delete group');
                handleConfirmDialog(group);
                action && action();
              },
            },
          ]),
    ];
  };

  const showBranchDetails = () =>
    setActiveItem &&
    group.id !== 'new' &&
    setActiveItem({ id: group.id, type: group.type });

  const { ref } = useScrollIntoView({ auto: true }); // scroll into view currently selected item

  // confirm dialog handler
  const handleConfirmDialog = (context) => {
    setConfirmDeleteButtons((old) => [
      ...old.filter((button) => !['cancel', 'remove'].includes(button.id)),
      {
        ...old.find((button) => button.id === 'cancel'),
        ...{
          message: `${tr['confirm-message']} ${tr['remove']} ${
            tr[context.type + '/accusative']
          }?`,
        },
      },
      {
        ...old.find((button) => button.id === 'remove'),
        ...{
          action: () => {
            console.log(context.type + ' deletion confirmed');
            mutate({ id: context?.id });
            confirmDialogHandler.off();
          },
        },
      },
    ]);

    confirmDialogHandler.on();
  };

  // if categories reloaded, reload any possible currently open group contents
  useEffect(() => {
    isOpen &&
      !group.isEmpty &&
      !(group.subgroups || group.services) &&
      refetch();
  }, [group, isOpen, refetch]);

  return (
    <>
      <S.CategoryTreeItem
        className={classNames(
          group.type,
          isOpen !== undefined && (isOpen ? 'open' : 'closed'),
          isCurrent(group, activeItem),
          emptyStatus(group),
          isInactive(group),
        )}
        isNew={isNew(activeItem)}
        isEmpty={!!emptyStatus(group)}
        onClick={showBranchDetails}
        onMouseEnter={showContextMenuIcon}
        onMouseLeave={debounceHideContextMenuIcon}
        {...((isNew(group) || isCurrent(group, activeItem)) && { ref })} // required for scrolling into view
      >
        <div className="control-description-container">
          <div className="title-container">
            <div
              className="icon"
              {...(!group.isEmpty && { onClick: openGroupBranch })}
            >
              <Arrow black />
            </div>

            <span>{isSpecialItem(group) ? tr['ungrouped'] : group.name}</span>
          </div>
        </div>

        <div className="context-menu-icon-container">
          {!isSpecialItem(group) &&
            ((!isMobile && isHovered) ||
              (isMobile && isCurrent(group, activeItem))) &&
            !isNew(group) &&
            !serviceAction && (
              <ServiceItemButton
                id={group.type + '-' + group.id}
                icon={
                  <VerticalEllipsis
                    {...(isCurrent(group, activeItem) && {
                      color: colors.text_Primary,
                    })}
                  />
                }
                action={groupAction}
                parentId={'category-container'}
                items={getFunctionList}
                triggerCallback={setIsContextMenuOpen}
              />
            )}
        </div>
      </S.CategoryTreeItem>

      {isOpen &&
        group.subgroups?.map((subgroup) => (
          <Subgroup
            key={'subgroup' + subgroup.id}
            subgroup={subgroup}
            parentInactive={isInactive(group)}
            setCategories={setCategories}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            serviceAction={serviceAction}
            groupAction={groupAction}
            categoryName={categoryName}
            groupName={group.name}
            setConfirmDeleteButtons={setConfirmDeleteButtons}
            confirmDialogHandler={confirmDialogHandler}
            parentIsOpenHandler={setIsOpen}
            sectors={sectors}
            averagePrices={averagePrices}
          />
        ))}

      {isOpen &&
        group.services?.map((service) => (
          <Service
            key={'service' + service.id}
            service={service}
            parentInactive={isInactive(group)}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            serviceAction={serviceAction}
            style={{ marginLeft: '52px' }}
            categoryName={categoryName}
            groupName={group.name}
            setCategories={setCategories}
            setConfirmDeleteButtons={setConfirmDeleteButtons}
            confirmDialogHandler={confirmDialogHandler}
            parentIsOpenHandler={setIsOpen}
            averagePrices={averagePrices}
          />
        ))}
    </>
  );
};
