import { useEffect, useState } from 'react';
import classNames from 'classnames';

// style
import * as S from '../CategoryTree.styled';
import { colors } from 'common/colors/colors';

// icons
import { Arrow, TrashCan, VerticalEllipsis } from 'common/icons';

// components
import { ServiceItemButton } from '../../ServiceItemButton/ServiceItemButton';
import { Service } from '../Service/Service';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useScrollIntoView } from 'hooks/useScrollIntoView';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useOnHoverContextMenu } from 'hooks/useOnHoverContextMenu';
import { useServicesDelete } from 'services/serviceService';
import {
  emptyStatus,
  getParent,
  isCurrent,
  isNew,
  isParentEmpty,
  removeObjectById,
  updateTree,
} from 'common/helpers/serviceFunctions';

export const Subgroup = ({
  subgroup,
  parentInactive,
  activeItem,
  setActiveItem,
  serviceAction,
  groupAction,
  setCategories,
  categoryName,
  groupName,
  setConfirmDeleteButtons,
  confirmDialogHandler,
  parentIsOpenHandler,
  sectors,
  averagePrices,
}) => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');

  const [isOpen, setIsOpen] = useState(); // initally undefined for no icon opening animation

  // handler for context menu, called on hover
  const {
    isHovered,
    setIsHovered,
    setIsContextMenuOpen,
    showContextMenuIcon,
    debounceHideContextMenuIcon,
  } = useOnHoverContextMenu();

  const isInactive = (context) =>
    (parentInactive || !context.isActive) && 'inactive';

  // data delete handler
  const { mutate, isSuccess: isDeletionSuccess } =
    useServicesDelete('subgroup');

  // delete active item from CategoryTree in case of successful removal from B/E
  useEffect(() => {
    if (isDeletionSuccess) {
      // remove current object
      removeObjectById(
        subgroup.id,
        subgroup.type,
        setCategories,
        setActiveItem,
      );

      // update isEmpty/isOpen status
      setCategories((oldCategories) => {
        const newCategories = [...oldCategories];
        const parent = getParent(subgroup, newCategories);

        if (isParentEmpty(parent)) {
          // update isEmpty status of parent
          parent.isEmpty = true;
          // update isOpen status of parent
          parentIsOpenHandler && parentIsOpenHandler(false);
        }

        return newCategories;
      });
    }
  }, [
    isDeletionSuccess,
    subgroup.id,
    subgroup.type,
    setActiveItem,
    setCategories,
    subgroup,
    parentIsOpenHandler,
  ]);

  const getFunctionList = (action) => {
    const item = subgroup;

    const setNewActiveItem = (context) =>
      setActiveItem({ id: 'new', type: context, selected: true });

    const addNewItemToServiceTree = (context) =>
      updateTree(
        subgroup.id,
        subgroup.type,
        {
          // ...item,
          ...{
            id: 'new',
            type: context,
            name: tr['new-' + context],
            [subgroup.type + 'Id']: subgroup.id,
            isActive: true,
            sectors: sectors.map((sector) => {
              return {
                sectorId: sector.id,
                isAssigned: subgroup.assignedToSectorIds.includes(sector.id),
                unitPriceWithVat: null,
                vatPercent: null,
              };
            }),
            ...(context === 'set' && { hasComp: true }),
          },
        },
        item,
        setCategories,
      );

    const createNewItem = (context) => {
      console.log('create new ' + context);
      !isOpen && openBranch();
      setIsHovered(false);
      if (activeItem?.id === 'new') {
        setActiveItem(undefined);
      }
      addNewItemToServiceTree(context);
      setNewActiveItem(context);
      action && action();
    };

    return [
      {
        id: 'new-service',
        title: tr['new-service'],
        action: () => createNewItem('service'),
      },
      {
        id: 'new-set',
        title: tr['new-set'],
        action: () => createNewItem('set'),
      },
      {
        id: 'delete',
        title: tr['delete'],
        icon: <TrashCan bold />,
        color: 'red',
        action: () => {
          console.log('delete subgroup');
          handleConfirmDialog(subgroup);
          action && action();
        },
      },
    ];
  };

  const openBranch = (e) => {
    if (isNew(activeItem) && isCurrent(subgroup, activeItem)) return;
    e?.stopPropagation();
    setIsOpen((old) => !old);
  };

  const showBranchDetails = () =>
    setActiveItem &&
    subgroup.id !== 'new' &&
    setActiveItem({ id: subgroup.id, type: subgroup.type });

  const { ref } = useScrollIntoView({ auto: true }); // scroll into view currently selected item

  // confirm dialog handler
  const handleConfirmDialog = (context) => {
    setConfirmDeleteButtons((old) => [
      ...old.filter((button) => !['cancel', 'remove'].includes(button.id)),
      {
        ...old.find((button) => button.id === 'cancel'),
        ...{
          message: `${tr['confirm-message']} ${tr['remove']} ${
            tr[context.type + '/accusative']
          }?`,
        },
      },
      {
        ...old.find((button) => button.id === 'remove'),
        ...{
          action: () => {
            console.log(context.type + ' deletion confirmed');
            mutate({ id: context?.id });
            confirmDialogHandler.off();
          },
        },
      },
    ]);
    confirmDialogHandler.on();
  };

  return (
    <>
      <S.CategoryTreeItem
        className={classNames(
          subgroup.type,
          isOpen !== undefined && (isOpen ? 'open' : 'closed'),
          isCurrent(subgroup, activeItem),
          emptyStatus(subgroup),
          isInactive(subgroup),
        )}
        isNew={isNew(activeItem)}
        isEmpty={!!emptyStatus(subgroup)}
        onClick={showBranchDetails}
        onMouseEnter={showContextMenuIcon}
        onMouseLeave={debounceHideContextMenuIcon}
        {...((isNew(subgroup) || isCurrent(subgroup, activeItem)) && { ref })} // required for scrolling into view
      >
        <div className="control-description-container">
          <div className="title-container">
            <div
              className="icon"
              {...(!subgroup.isEmpty && { onClick: openBranch })}
            >
              <Arrow black />
            </div>

            <span>{subgroup?.name}</span>
          </div>
        </div>

        <div className="context-menu-icon-container">
          {((!isMobile && isHovered) ||
            (isMobile && isCurrent(subgroup, activeItem))) &&
            !isNew(subgroup) &&
            !serviceAction && (
              <ServiceItemButton
                id={subgroup.type + '-' + subgroup.id}
                icon={
                  <VerticalEllipsis
                    {...(isCurrent(subgroup, activeItem) && {
                      color: colors.text_Primary,
                    })}
                  />
                }
                action={groupAction}
                parentId={'category-container'}
                items={getFunctionList}
                triggerCallback={setIsContextMenuOpen}
              />
            )}
        </div>
      </S.CategoryTreeItem>

      {isOpen &&
        subgroup.services?.map((service) => (
          <Service
            key={'subservice' + service.id}
            service={service}
            parentInactive={isInactive(subgroup)}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            serviceAction={serviceAction}
            style={{ marginLeft: '78px' }}
            categoryName={categoryName}
            groupName={groupName}
            subgroupName={subgroup.name}
            setCategories={setCategories}
            setConfirmDeleteButtons={setConfirmDeleteButtons}
            confirmDialogHandler={confirmDialogHandler}
            parentIsOpenHandler={setIsOpen}
            averagePrices={averagePrices}
          />
        ))}
    </>
  );
};
