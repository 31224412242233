import { useEffect } from 'react';
import classNames from 'classnames';

// style
import * as S from './Sector.styled';
import { colors } from 'common/colors/colors';

// icons
import { TrashCan, VerticalEllipsis } from 'common/icons';

// components
import { ServiceItemButton } from 'components/Cards/Services/ServiceItemButton/ServiceItemButton';

// hooks
import { isNew } from 'common/helpers/serviceFunctions';
import { useScrollIntoView } from 'hooks/useScrollIntoView';
import { useOnHoverContextMenu } from 'hooks/useOnHoverContextMenu';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useTranslate } from 'hooks/useTranslate';
import { useSectorsDelete } from 'services/sectorService';

export const Sector = ({
  sector,
  activeItem,
  setActiveItem,
  setConfirmDeleteButtons,
  confirmDialogHandler,
}) => {
  const type = 'sector';
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');

  // handler for context menu, called on hover
  const {
    isHovered,
    setIsHovered,
    setIsContextMenuOpen,
    showContextMenuIcon,
    debounceHideContextMenuIcon,
  } = useOnHoverContextMenu();

  const { mutate, isSuccess } = useSectorsDelete({
    searchParams: { version: sector.version },
    queryParams: { invalidate: ['sectors'] },
  });

  // if deletion successful, reset activeItem (clear sector details)
  useEffect(() => {
    isSuccess && setActiveItem && setActiveItem(undefined);
  }, [isSuccess, setActiveItem]);

  const getFunctionList = (action) => {
    // confirm dialog handler
    const handleConfirmDialog = (context) => {
      setConfirmDeleteButtons((old) => [
        ...old.filter((button) => !['cancel', 'remove'].includes(button.id)),
        {
          ...old.find((button) => button.id === 'cancel'),
          ...{
            message: (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                }}
              >
                {tr['confirm-messsage-sector-delete']}

                <div>
                  {`${tr['confirm-message']} ${tr['remove']} ${tr[type + '/accusative']}?`}
                </div>
              </div>
            ),
          },
        },
        {
          ...old.find((button) => button.id === 'remove'),
          ...{
            action: () => {
              console.log(type + ' deletion confirmed');
              mutate({ id: context?.id });
              confirmDialogHandler.off();
            },
          },
        },
      ]);
      confirmDialogHandler.on();
    };

    return [
      {
        id: 'delete',
        title: tr['delete'],
        icon: <TrashCan bold />,
        color: 'red',
        action: () => {
          console.log(`delete ${type}`);
          handleConfirmDialog(sector);
          action && action();
        },
      },
    ];
  };

  const showDetails = () =>
    setActiveItem &&
    sector.id !== 'new' &&
    setActiveItem({ id: sector.id, type });

  const { ref } = useScrollIntoView({ auto: true }); // scroll into view currently selected item

  const isCurrent = (context, activeItem) =>
    activeItem && context.id === activeItem.id;

  const isInactive = (context) => !context.isActive;

  const getCurrentClass = (context, activeItem) =>
    isCurrent(context, activeItem) && 'active';

  const getInactiveClass = (context) => isInactive(context) && 'inactive';

  // log
  // useEffect(() => {
  //   console.log({ id: sector.id, isHovered });
  // }, [isHovered, sector]);

  return (
    <S.Sector
      className={classNames(
        type,
        getCurrentClass(sector, activeItem),
        getInactiveClass(sector),
      )}
      isNew={isNew(activeItem)}
      isInactive={isInactive(sector)}
      onClick={showDetails}
      onMouseEnter={showContextMenuIcon}
      onMouseLeave={debounceHideContextMenuIcon}
      {...((isNew(sector) || isCurrent(sector, activeItem)) && { ref })} // required for scrolling into view
    >
      <div className="control-description-container">
        <div className="title-container">
          {/* <div
            className="icon"
            {...(!category.isEmpty && { onClick: openBranch })}
          >
            <Arrow black />
          </div> */}

          <span>{sector.name}</span>
        </div>
      </div>

      <div className="context-menu-icon-container">
        {((!isMobile && isHovered) ||
          (isMobile && isCurrent(sector, activeItem))) &&
          !isNew(sector) && (
            <ServiceItemButton
              isSelected={isCurrent(sector, activeItem)}
              icon={
                <VerticalEllipsis
                  {...(isCurrent(sector, activeItem) && {
                    color: colors.text_Primary,
                  })}
                />
              }
              id={sector.type + '-' + sector.id}
              parentId={'category-container'}
              items={getFunctionList}
              triggerCallback={setIsContextMenuOpen}
            />
          )}
      </div>
    </S.Sector>
  );
};
