import { useState, useEffect, useContext, useRef } from 'react';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteAppointment,
  updateAppointment,
} from 'redux/actions/appointmentsAction';
import { addPendingOrder } from 'redux/actions/pendingOrdersAction';

// style
import * as S from './AppointmentsContainer.styled';

// components
import AppointmentWrapper from './AppointmentWrapper/AppointmentWrapper';

// contexts
import { ScheduleContext } from 'context/ScheduleContext';
import { MainContext } from 'pages/Main/Main';

// hooks
import { toast } from 'common/helpers/toast';
import useObserver from 'hooks/useObserver';
import useMediaQuery from 'hooks/useMediaQuery';
import { useModifyOrderDetailsHandler } from 'hooks/schedule/useModifyOrderDetailsHandler';
import {
  getColumnOrders,
  getOrderTimeIntervals,
  willOrderOverlap,
} from 'common/helpers/scheduleFunctions';
import { ORDER_COMPLETION_STATUS } from 'common/constants';
import {
  datesAreOnSameDay,
  formatToIsoWithoutMilliseconds,
  getDuration,
  getDurationInMinutes,
  toIsoString,
} from 'common/helpers/dateOperations';

const notOverlapingStatuses = [
  ORDER_COMPLETION_STATUS.WILL_NOT_ARRIVE,
  ORDER_COMPLETION_STATUS.NOT_ARRIVED,
];

const AppointmentsContainer = ({
  id,
  sectorId,
  headerMenu,
  appointmentsInRow,
  globalOrders,
  employeeId,
  columnDay,
  maxAppointmentsInRowState,
}) => {
  const setMaxAppointmentsInRow = maxAppointmentsInRowState[1];

  // Hooks
  const isMobile = useMediaQuery('(max-width: 960px)');
  const dispatch = useDispatch();
  const {
    draggingAppointment,
    cache,
    newOrderInfo,
    refetchOrders,
    appointmentsFilter,
    isLoading,
  } = useContext(ScheduleContext);
  const { sectorData } = useContext(MainContext);
  const [appointmentsArr, setAppointmentsArr] = useState([]);
  const [parentWidth, setParentWidth] = useState(null);
  const [appointmentWidth, setAppointmentWidth] = useState(null);
  const [appointments, setAppointments] = useState();
  const [isDataLoading, setIsDataLoading] = useState(true);

  const startHour = useSelector((state) => state.scheduleReducer.startHour);
  const endHour = useSelector((state) => state.scheduleReducer.endHour);
  const dateState = useSelector((state) => state.scheduleReducer.dateState);
  const cellDuration = useSelector(
    (state) => state.scheduleReducer.cellDuration,
  );
  const orders = useSelector((state) => state.appointments);

  const containerRef = useRef(null);
  const overlaping = useRef(false);

  let container = useObserver({
    ref: containerRef,
    debounceDelay: 100,
  });

  const modifyOrder = useModifyOrderDetailsHandler(cache, refetchOrders, {
    searchParams: {
      useVersioning: 1,
      allowOverlap: overlaping.current,
      serviceListMode: 'both',
    },
  });

  useEffect(() => {
    setParentWidth(
      containerRef.current.parentElement.getBoundingClientRect().width,
    );
    setAppointmentWidth(containerRef.current.getBoundingClientRect().width);
  }, [container]);

  useEffect(() => {
    setIsDataLoading(true);
  }, [dateState]);

  useEffect(() => {
    if (!isLoading) {
      setIsDataLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    setAppointmentsArr([]);
    setAppointments([]);

    if (isDataLoading) {
      return;
    }

    const currDay = toIsoString(columnDay).split('T')[0];

    if (orders?.employees?.length) {
      const found = orders.employees.find(
        (emp) => emp.employee.id === employeeId,
      );

      if (found) {
        const foundDay = found.days.filter((day) => day.day === currDay);

        if (foundDay[0]) {
          const filteredAppointments = foundDay[0].orders.filter((obj) =>
            appointmentsFilter.includes(obj.completionStatus.toLowerCase()),
          );
          setAppointments(filteredAppointments);
          const updatedArr = filteredAppointments.map((item) => {
            const positions = calculatePositions(item);

            return {
              ...item,
              ...positions,
            };
          });

          const newArr = updateWidthAndPostion(updatedArr);

          setAppointmentsArr(newArr);
        }
      }
    }
  }, [
    orders,
    appointmentsFilter,
    employeeId,
    columnDay,
    isDataLoading,
    isMobile,
  ]);

  // Variables
  const hourInterval = endHour - startHour;

  // Functions
  const calculatePositions = (appointment) => {
    if (!sectorData?.settings?.timezoneIanaId) {
      return;
    }

    const appointmentStartDateTime = DateTime.fromISO(appointment?.startTimeUtc)
      .setZone(sectorData.settings.timezoneIanaId)
      .toObject();
    const appointmentStartHour = appointmentStartDateTime.hour;

    const appointmentStartMinutes = appointmentStartDateTime.minute;

    // top position
    const diffFromStartingHour = appointmentStartHour - startHour;

    const topPosition = getHeightPercentage(
      diffFromStartingHour,
      appointmentStartMinutes,
      hourInterval,
    );

    // const lastInSplit = appointment?.splits.at(-1);

    // const endDate = addMinutes(
    //   new Date(appointment.startTimeUtc),
    //   lastInSplit.durationInMinutes
    // );

    const endDate = new Date(
      new Date(appointment.startTimeUtc).getTime() +
        appointment.durationInMinutes * 60000,
    );

    // height
    const duration =
      (endDate.getTime() - new Date(appointment.startTimeUtc).getTime()) /
      60000;

    const durationHours = Math.floor(duration / 60);
    const durationMinutes = Math.ceil(duration % 60);

    const height = getHeightPercentage(durationHours, durationMinutes);
    return { topPosition, height };
  };

  const getHeightPercentage = (hour, minutes) => {
    const minFraction = hour + minutes / 60;
    return (minFraction * 100) / hourInterval;
  };

  // const checkForRangeCollision = (startFirst, startLast, endFirst, endLast) => {
  //   return (
  //     (startFirst >= endFirst && startFirst < endLast) ||
  //     (endFirst > startFirst && endFirst < startLast) ||
  //     (startFirst === endFirst && startLast === endLast)
  //   );
  // };

  const updateWidthAndPostion = (arr) => {
    // @@@ METHOD 3
    const updatedArr = structuredClone(arr);

    // sort orders by time
    updatedArr.sort(
      (a, b) =>
        new Date(a.startTimeLocal).getTime() -
        new Date(b.startTimeLocal).getTime(),
    );

    const reccursiveArr = [];
    let rowIndex = 0;
    let columnIndex = 0;
    updatedArr.forEach((appointment, i) => {
      const startTime = new Date(appointment.startTimeLocal);
      const endTime = new Date(startTime);
      endTime.setMinutes(
        startTime.getMinutes() + appointment.durationInMinutes,
      );
      const previusAppointment = updatedArr[i - 1];

      if (previusAppointment) {
        // Check if overlaps on any appointment already included to current row
        let sameRow = false;
        reccursiveArr[rowIndex].forEach((includedAppointment) => {
          const includedAppointmentStartTime = new Date(
            includedAppointment.startTimeLocal,
          );
          const includedAppointmentEndTime = new Date(
            includedAppointmentStartTime,
          );
          includedAppointmentEndTime.setMinutes(
            includedAppointmentStartTime.getMinutes() +
              includedAppointment.durationInMinutes,
          );

          if (
            !sameRow &&
            startTime.getTime() < includedAppointmentEndTime.getTime()
          ) {
            columnIndex++;
            sameRow = true;
          }
        });

        if (!sameRow) {
          rowIndex++;
          columnIndex = 0;
        }
      }

      if (!reccursiveArr[rowIndex]) {
        reccursiveArr[rowIndex] = [];
      }

      reccursiveArr[rowIndex][columnIndex] = appointment;
    });

    // sort each row by time, than by duration
    reccursiveArr.forEach((row, i) => {
      reccursiveArr[i] = row.sort((a, b) => {
        if (
          new Date(a.startTimeLocal).getTime() ===
          new Date(b.startTimeLocal).getTime()
        ) {
          return b.durationInMinutes - a.durationInMinutes;
        } else {
          return (
            new Date(a.startTimeLocal).getTime() -
            new Date(b.startTimeLocal).getTime()
          );
        }
      });
    });

    // apply widths and positions for each appointment
    let maxAppointmentsInRow = 0;
    reccursiveArr.forEach((row) => {
      if (maxAppointmentsInRow < row.length) {
        maxAppointmentsInRow = row.length;
      }
      row.forEach((appointment, i) => {
        updatedArr.forEach((actualAppointment, index) => {
          if (actualAppointment.id === appointment.id) {
            updatedArr[index].width = 100 / row.length;
            updatedArr[index].left = updatedArr[index].width * i;
            // updatedArr[index].contentVisible = row.length < 9;
          }
        });
      });
    });

    setMaxAppointmentsInRow((prev) => {
      if (isMobile) {
        return 0;
      }

      if (maxAppointmentsInRow > prev) {
        return maxAppointmentsInRow;
      }

      return prev;
    });

    return updatedArr;

    // @@@ METHOD 2
    // const reccursiveArr = [];
    // let rowIndex = 0;
    // let columnIndex = 0;
    // arr.forEach((appointment, i) => {
    //   const startTime = new Date(appointment.startTimeLocal);
    //   const endTime = new Date(startTime);
    //   endTime.setMinutes(
    //     startTime.getMinutes() + appointment.durationInMinutes,
    //   );
    //   const previusAppointment = arr[i - 1];

    //   if (previusAppointment) {
    //     // Check if overlaps on any appointment already included to current row
    //     let sameRow = false;
    //     reccursiveArr[rowIndex].forEach((includedAppointment) => {
    //       const includedAppointmentStartTime = new Date(
    //         includedAppointment.startTimeLocal,
    //       );
    //       const includedAppointmentEndTime = new Date(
    //         includedAppointmentStartTime,
    //       );
    //       includedAppointmentEndTime.setMinutes(
    //         includedAppointmentStartTime.getMinutes() +
    //           includedAppointment.durationInMinutes,
    //       );

    //       // console.log(appointment.id);
    //       if (
    //         !sameRow &&
    //         startTime.getTime() < includedAppointmentEndTime.getTime() //&&
    //         // endTime.getTime() !== includedAppointmentStartTime.getTime()
    //       ) {
    //         columnIndex++;
    //         sameRow = true;
    //       }
    //     });

    //     if (!sameRow) {
    //       rowIndex++;
    //       columnIndex = 0;
    //     }
    //   }

    //   if (!reccursiveArr[rowIndex]) {
    //     reccursiveArr[rowIndex] = [];
    //   }

    //   reccursiveArr[rowIndex][columnIndex] = appointment;
    // });

    // // sort each row by duration
    // reccursiveArr.forEach((row, i) => {
    //   reccursiveArr[i] = row.sort(
    //     (a, b) => b.durationInMinutes - a.durationInMinutes,
    //   );
    // });

    // // apply widths and positions for each appointment
    // reccursiveArr.forEach((row, i) => {
    //   row.forEach((appointment, j) => {
    //     arr.forEach((actualAppointment, index) => {
    //       if (actualAppointment.id === appointment.id) {
    //         arr[index].width = 100 / row.length;
    //         arr[index].left = arr[index].width * j;
    //       }
    //     });
    //   });
    // });

    // return arr;

    // @@@ METHOD 1
    // const itemsInRows = [];
    // let rowIndex = 0;
    // let columnIndex = 0;

    // arr.forEach((appointment, i) => {
    //   const startTime = new Date(appointment.startTimeLocal);
    //   const endTime = new Date(startTime);
    //   endTime.setMinutes(
    //     startTime.getMinutes() + appointment.durationInMinutes,
    //   );

    //   const previusAppointment = arr[i - 1];
    //   if (previusAppointment) {
    //     const prevAppointmentStartTime = new Date(
    //       previusAppointment.startTimeLocal,
    //     );
    //     const prevAppointmentEndTime = new Date(prevAppointmentStartTime);
    //     prevAppointmentEndTime.setMinutes(
    //       prevAppointmentStartTime.getMinutes() +
    //         previusAppointment.durationInMinutes,
    //     );

    //     if (prevAppointmentEndTime.getTime() < startTime.getTime()) {
    //       rowIndex++;
    //       columnIndex = 0;
    //     } else {
    //       columnIndex++;
    //     }
    //   }
    //   itemsInRows[rowIndex] = columnIndex + 1;

    //   appointment.rowIndex = rowIndex;
    //   appointment.columnIndex = columnIndex;
    // });

    // arr.forEach((appointment, i) => {
    //   appointment.width = 100 / itemsInRows[appointment.rowIndex];
    //   appointment.left = appointment.width * appointment.columnIndex;
    // });

    // return arr;

    // @@@ METHOD OLD
    // const array2d = reccursive([...arr]);

    // appointmentsInRow.setState(array2d.length);

    // return setWidthAndPosition(array2d);
  };

  // const removingOverlaping = (arr) => {
  //   arr.sort((a, b) => {
  //     const aStart = new Date(a.startTimeUtc);
  //     const bStart = new Date(b.startTimeUtc);

  //     return aStart.getTime() - bStart.getTime();
  //   });

  //   let j = 0;
  //   let removeList = [];

  //   for (let i = 1; i < arr.length; i++) {
  //     const next = arr[i];
  //     const curr = arr[j];

  //     const nextStart = new Date(next.startTimeUtc);
  //     const nextEnd = new Date(
  //       new Date(nextStart).getTime() + next.durationInMinutes * 60000,
  //     );

  //     const currEnd = new Date(
  //       new Date(curr.startTimeUtc).getTime() + curr.durationInMinutes * 60000,
  //     );

  //     let next_start_index = nextStart.getTime();
  //     let next_end_index = nextEnd.getTime();
  //     let current_end = currEnd.getTime();

  //     if (current_end > next_start_index) {
  //       removeList.push(arr[i]);

  //       if (next_end_index < current_end) {
  //         j = j++;
  //       }
  //     } else {
  //       j = i;
  //     }
  //   }

  //   if (removeList.length) {
  //     removeList.forEach((element) => {
  //       const elementIndex = arr.findIndex((item) => item.id === element.id);
  //       arr.splice(elementIndex, 1);
  //     });
  //   }

  //   return arr;
  // };

  // const reccursive = (arr, loop = 1, d2 = []) => {
  //   const newArr = removingOverlaping([...arr]);

  //   if (newArr.length) {
  //     d2.push(newArr);
  //   }

  //   const notDeletedYet = arr.filter((item, i) => !newArr.includes(item));

  //   if (notDeletedYet.length) {
  //     reccursive(notDeletedYet, (loop = loop + 1), d2);
  //   }

  //   return d2;
  // };

  // NOTE: takes 2d array
  // const setWidthAndPosition = (arr) => {
  //   const formatedArr = [];

  //   for (let i = 0; i < arr.length; i++) {
  //     for (let j = 0; j < arr[i].length; j++) {
  //       const currentElement = arr[i][j];

  //       const aStartTime = new Date(currentElement.startTimeUtc).getTime();

  //       const aEndTime = new Date(
  //         new Date(aStartTime).getTime() +
  //           currentElement.durationInMinutes * 60000,
  //       ).getTime();

  //       let freeSpaceCounter = 1;

  //       for (let k = 0; k < arr.length; k++) {
  //         if (i >= k) continue;

  //         const isCollision = arr[k].find((item) => {
  //           const bStartTime = new Date(item.startTimeUtc).getTime();
  //           const bEndTime = new Date(
  //             new Date(bStartTime).getTime() + item.durationInMinutes * 60000,
  //           ).getTime();

  //           return checkForRangeCollision(
  //             aStartTime,
  //             aEndTime,
  //             bStartTime,
  //             bEndTime,
  //           );
  //         });

  //         if (!isCollision) {
  //           freeSpaceCounter++;
  //         } else break;
  //       }

  //       formatedArr.push({
  //         ...currentElement,
  //         width: 100 * (freeSpaceCounter / arr.length),
  //         left: (100 / arr.length) * i,
  //       });
  //     }
  //   }

  //   return formatedArr;
  // };

  const updateAppointmentPosition = (order, top, data) => {
    const employeeId = data?.split('--')[0];
    const columnDate = new Date(data?.split('--')[1] || order.startTimeUtc);

    const foundStart = new Date(order.startTimeUtc);

    const startDate = !isNaN(top)
      ? new Date(getStartDateByPosition(top, foundStart))
      : foundStart;

    const updatedOrder = structuredClone(order);

    const sameDate = datesAreOnSameDay(foundStart, new Date(columnDate));

    sameDate &&
      (updatedOrder.startTimeUtc = DateTime.now()
        .setZone(sectorData.settings.timezoneIanaId)
        .set({
          year: startDate.getFullYear(),
          month: startDate.getMonth() + 1,
          day: startDate.getDate(),
          hours: startDate.getHours(),
          minutes: startDate.getMinutes(),
          millisecond: 0,
        })
        .toUTC()
        .toISO({ suppressMilliseconds: true }));

    columnDate.setHours(startDate.getHours(), startDate.getMinutes(), 0, 0);

    const sameEmployee = employeeId === order.employeeId;
    const sameTime =
      new Date(order.startTimeUtc).getTime() === columnDate.getTime();

    if (sameEmployee && sameTime) return; // do not update. its the same
    const tp = calculatePositions(updatedOrder);
    updatedOrder.topPosition = tp.topPosition;

    const newColumn = {
      ...(updatedOrder.employeeId !== employeeId && { employeeId }),
      ...(!sameDate && {
        date: columnDate,
      }),
    };

    const newIntervals = getOrderTimeIntervals(
      updatedOrder,
      updatedOrder.durationInMinutes,
    );

    const updatedSplits = updatedOrder.splits.map((split, i) => {
      return { ...split, ...newIntervals[i] };
    });

    updatedOrder.splits = updatedSplits;

    dispatch(
      updateAppointment(
        updatedOrder,
        newColumn,
        sectorData.settings.timezoneIanaId,
      ),
    );
    let isOverlaping;
    const excludeOverlaping = notOverlapingStatuses.includes(
      updatedOrder.completionStatus,
    );

    if (excludeOverlaping) {
      isOverlaping = false;
    } else {
      let columnOrders = getColumnOrders(
        globalOrders,
        employeeId,
        formatToIsoWithoutMilliseconds(new Date(columnDate)).split('T')[0],
      );

      columnOrders = columnOrders.filter(
        (order) =>
          order.id !== updatedOrder.id &&
          !notOverlapingStatuses.includes(order.completionStatus),
      );

      isOverlaping = willOrderOverlap(columnOrders, newIntervals);
    }

    overlaping.current = isOverlaping;
    sendModifiedBody('inScheduler', order, updatedOrder, newColumn.employeeId);
  };

  const updateAppointmentHeight = (order, height, top) => {
    if (!height) return;

    const foundStart = new Date(order.startTimeUtc);

    const startDate = !isNaN(top)
      ? new Date(getStartDateByPosition(top, foundStart))
      : foundStart;

    const endDate = new Date(getEndDateByHeight(height, startDate));
    const duration = getDurationInMinutes(startDate, endDate);

    const sameDuration = order.durationInMinutes === duration;

    if (sameDuration) return;

    const updatedOrder = structuredClone(order);

    updatedOrder.startTimeUtc = DateTime.now()
      .setZone(sectorData.settings.timezoneIanaId)
      .set({
        year: startDate.getFullYear(),
        month: startDate.getMonth() + 1,
        day: startDate.getDate(),
        hours: startDate.getHours(),
        minutes: startDate.getMinutes(),
        millisecond: 0,
      })
      .toUTC()
      .toISO({ suppressMilliseconds: true });
    updatedOrder.durationInMinutes = duration;

    const newIntervals = getOrderTimeIntervals(
      updatedOrder,
      updatedOrder.durationInMinutes,
    );

    const updatedSplits = updatedOrder.splits.map((split, i) => {
      return { ...split, ...newIntervals[i] };
    });

    updatedOrder.splits = updatedSplits;

    dispatch(
      updateAppointment(updatedOrder, null, sectorData.settings.timezoneIanaId),
    );

    let isOverlaping;
    const excludeOverlaping = notOverlapingStatuses.includes(
      updatedOrder.completionStatus,
    );

    if (excludeOverlaping) {
      isOverlaping = false;
    } else {
      let columnOrders = getColumnOrders(
        globalOrders,
        updatedOrder.employeeId,
        formatToIsoWithoutMilliseconds(
          new Date(updatedOrder.startTimeUtc),
        ).split('T')[0],
      );

      columnOrders = columnOrders.filter(
        (order) =>
          order.id !== updatedOrder.id &&
          !notOverlapingStatuses.includes(order.completionStatus),
      );

      isOverlaping = willOrderOverlap(columnOrders, newIntervals);
    }

    overlaping.current = isOverlaping;
    sendModifiedBody('inScheduler', order, updatedOrder);
  };

  const updateToPendingOrders = (order) => {
    const updatedOrder = structuredClone(order);
    updatedOrder.employeeId = null;

    dispatch(deleteAppointment(order.id));
    dispatch(addPendingOrder(updatedOrder));

    sendModifiedBody('fromSchedulerToPending', order, updatedOrder);
  };

  const getStartDateByPosition = (top, date) => {
    const fixedTop = top < 0 ? 0 : top;

    const duration = (hourInterval * fixedTop) / 100;

    const startDate = new Date(date);
    startDate.setHours(startHour, 0, 0, 0);
    startDate.setSeconds(+duration.toFixed(2) * 60 * 60);

    return startDate.toISOString();
  };

  const getEndDateByHeight = (height, startDate) => {
    if (!height) return null;

    const duration = (hourInterval * height) / 100;

    const endDate = new Date(startDate);

    endDate.setHours(endDate.getHours(), endDate.getMinutes(), 0, 0);
    endDate.setSeconds(+duration.toFixed(2) * 60 * 60);

    return endDate.toISOString();
  };

  const getHoverTime = (topPosition) => {
    const top = Math.round(topPosition / 20);

    const x = (cellDuration / 60) * top + +startHour;

    const hours = Math.floor(x);
    let minutes = Math.round((x - hours) * 60);

    if (minutes === 0) {
      minutes = '00';
    }

    return `${hours}:${minutes}`;
  };

  const getTypeData = (data) => {
    if (data.category !== 'excuse') return false;

    const start = data.orders[0].startDate;
    const end = new Date(
      new Date(start).getTime() + data.orders.at(-1).duration,
    ).toISOString();

    const duration = getDuration(start, end);

    return { title: data.title, duration, start, excuseId: data.excuseId };
  };

  // const updateSingleAppointmentKey = (id, key, newValue) => {
  //   const found = appointments.find((obj) => obj.id === id);

  //   if (found[key] === newValue) return;
  //   cache.setState([]);
  //   toast.success(tr['order-updated-successfully']);

  //   dispatch(updateAppointmentKey({ id: id, key, value: newValue }));
  // };

  // const deleteService = (id) => {
  //   cache.setState([]);
  //   toast.success(tr['order-deleted-successfully']);

  //   dispatch(deleteAppointment({ id }));
  // };

  // const createAppointmentCopy = (id) => {
  //   // TODO: update with be
  //   cache.setState([]);
  //   toast.success(tr['order-copy-created-successfully']);

  //   dispatch(copyAppointment(id));
  // };

  const sendModifiedBody = (type, order, updatedOrder, newEmployee) => {
    const modifyBody = {
      id: updatedOrder.id,
      version: updatedOrder.version,
      startTimeUtc: updatedOrder.startTimeUtc,
      employeeId: newEmployee || updatedOrder.employeeId,
      durationInMinutes: updatedOrder.durationInMinutes,
    };

    cache.setState((prev) => [
      ...prev,
      {
        type,
        old: order,
        new: { ...updatedOrder, ...modifyBody },
      },
    ]);

    modifyOrder.mutate(modifyBody);
  };

  return (
    <>
      <S.AppointmentsContainer ref={containerRef} data-id={id} id="collumn">
        {appointmentsArr.map((item) => (
          <AppointmentWrapper
            order={item}
            key={item.id}
            selected={newOrderInfo?.orderId === item.id}
            multiBooking={{ width: item.width, left: item.left }}
            parentHeight={containerRef.current.offsetHeight}
            widths={{ parentWidth, appointmentWidth }}
            updateAppointmentPosition={updateAppointmentPosition}
            updateAppointmentHeight={updateAppointmentHeight}
            updateToPendingOrders={updateToPendingOrders}
            dragging={draggingAppointment}
            getHoverTime={getHoverTime}
            timeOff={getTypeData(item)}
            globalOrders={globalOrders}
            functions={{
              raiseHead: headerMenu.setState,
              // updateKey: updateSingleAppointmentKey,
              // delete: deleteService,
              // createCopy: createAppointmentCopy,
            }}
            hourInterval={hourInterval}
            startHour={startHour}
            notOverlapingStatuses={notOverlapingStatuses}
          />
        ))}
      </S.AppointmentsContainer>
    </>
  );
};

export default AppointmentsContainer;
