import { useLocation } from 'react-router';

// style
import * as S from './PageHeader.styled';
import useTranslate from 'hooks/useTranslate';

// components
import TheButton from 'components/Buttons/TheButton';

export const PageHeader = ({
  index,
  children,
  controls,
  title,
  subtitle,
  buttons,
  ...other
}) => {
  const location = useLocation().pathname.split('/');

  const tr = {
    global: useTranslate().use().global,
    main: useTranslate().use().main,
  };

  return (
    <S.PageHeader {...other}>
      <div className="header">
        <span>
          {title || tr.main[location[location.length - (index || 2)]]}
          &nbsp;|&nbsp;
        </span>
        <div>
          {subtitle || tr.main[location[location.length - (index || 1)]]}
        </div>
        {controls}
        {buttons && (
          <div className="buttons">
            {buttons?.map(
              (button, index) =>
                button.show && <TheButton key={index} {...button} />,
            )}
          </div>
        )}
      </div>
      {children}
    </S.PageHeader>
  );
};
