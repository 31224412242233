import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
  useContext,
  cloneElement,
} from 'react';
import { createPortal } from 'react-dom';
import ClickAwayListener from 'react-click-away-listener';
import { useHover, useLayer, useMousePositionAsTrigger } from 'react-laag';

// style
import * as S from './ContextTree.styled';

// icons
import { Arrow } from 'common/icons';

// context
import { ScheduleContext } from 'context/ScheduleContext';

// topWrapper - element in which layer works and ContextTree lives
// container - a base clickable element from which ContextTree spreads

const baseOptions = {
  overflowContainer: false,
  auto: true,
  snap: true,
  placement: 'right-start',
  triggerOffset: 8,
  containerOffset: 0,
  arrowOffset: 8,
};

const renderItems = (items, selected, close, level = 0) => {
  // for each item...
  return items.map((item) => {
    if (item.items) {
      return (
        <NestedMenuItem
          key={item.id}
          item={item}
          selected={selected}
          level={level}
          closeAfter={close}
        />
      );
    }

    if (item.input) {
      return (
        <textarea
          onClick={(e) => e.stopPropagation()}
          key={item.id}
          onKeyPress={(e) => {
            item.onKeyPress(e);

            if (e.key === 'Enter') {
              selected.setState([]);

              !item.stayOpen && close();
            }
          }}
          placeholder="Įrašykite kitą priežastį..."
        />
      );
    }

    // return just a 'plain' menu-item
    return <PlainItem key={item.id} item={item} selected={selected} />;
  });
};

const NestedMenuItem = ({ item, selected, level, closeAfter }) => {
  // We use `useHover()` to determine whether we should show the nested menu.
  // Notice how we're configuring a small delay on leave.
  const [isOpen, hoverProps, close] = useHover({
    delayEnter: 100,
    delayLeave: 100,
    // hideOnScroll: true,
  });
  const [textWidth, setTextWidth] = useState(0);

  // const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   setOpen(() => selected.state[level] === item.id);
  // }, [selected.state]);

  const { renderLayer, triggerProps, layerProps } = useLayer({
    ...baseOptions, // the base-options we defined earlier
    // isOpen: isOpen && open, // tell whether the user is hovering this item
    isOpen, // tell whether the user is hovering this item

    // this is an important one: when the root-menu closes, we want all nested
    // menu's to close as well. Therefore, we can utilize this `onParentClose` props
    // to instruct `useHover` in this case to force-close possible nested menu's
    onParentClose: close,
    hideOnScroll: true,
    // onOutsideClick: close,
  });

  // Notice how we're reusing `renderItems` -> recursion :)
  // Also we're not only providing `hoverProps` to the menu-item (trigger), but also
  // the menu. Maybe this seems weird at first, but it allows us to have multiple menus open
  // at the same time

  const handleClick = (e) => {
    e.stopPropagation();

    if (item?.items) {
      selected.setState((prev) => {
        const newArr = [...prev];
        newArr[level] = newArr[level] === item.id ? null : item.id;

        // all the following items are set to null
        for (let x = level + 1; x < newArr.length; x++) {
          newArr[x] = null;
        }

        return newArr;
      });
    } else {
      item.onClick();
    }
  };

  useEffect(() => {
    if (!item.items) return;
    selected.getTextWidth(item.items, setTextWidth);
  }, [item.items, selected, item]);

  // react-laag glich bugfix
  useEffect(() => {
    layerProps.style.left = -1000;
  }, []);

  return (
    <>
      <S.NestedMenuItem
        {...hoverProps}
        {...triggerProps}
        // selected={open}
        selected={isOpen}
        className="menu-item"
        onClick={handleClick}
      >
        <>
          <span className="nestedMenu_item">
            {item?.icon && (
              <span className="nestedMenu_item-icon">{item.icon}</span>
            )}
            <span className="nestedMenu_item-label">{item.title}</span>
          </span>
          <span className="nestedMenu_arrow">
            <Arrow height={12} black />
          </span>
        </>
      </S.NestedMenuItem>

      {isOpen &&
        renderLayer(
          <S.TreeMenu
            {...layerProps}
            {...hoverProps}
            textWidth={textWidth}
            fixedWidth={selected?.fixedWidth}
            className="menu"
          >
            {renderItems(item.items, selected, closeAfter, level + 1)}
          </S.TreeMenu>,
        )}
    </>
  );
};

// types
// schedule-body-item

export const ContextTree = ({
  items,
  containerId,
  parentId,
  open = true,
  preferedSide = 'right',
  children,
  openOnClick,
  openOnDoubleClick,
  openOnContext,
  fixedWidth,
  content,
  overflow = false,
  setCardSelected,
  type = null,
}) => {
  const updatedOptions = {
    ...baseOptions,
    container: containerId,
    overflowContainer: overflow,
  };
  const { scheduleScrollEvents } = useContext(ScheduleContext);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [isOpen, setIsOpen] = useState(false);
  const [openMenuTrigger, setOpenMenuTrigger] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [textWidth, setTextWidth] = useState(0);

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = '900 14px Roboto';

  // Hooks
  const {
    hasMousePosition, // did we get a mouse-position from the event-handler
    resetMousePosition, // reset the mouse-position to `null`, essentially closing the menu
    handleMouseEvent, // event-handler we will use below
    trigger, // information regarding positioning we can provide to `useLayer`
  } = useMousePositionAsTrigger();

  const { renderLayer, layerProps } = useLayer({
    isOpen: hasMousePosition,
    onParentClose: resetMousePosition,
    onOutsideClick: () => {
      resetMousePosition();
      setSelectedItem([]);
      close();
    },

    trigger,
    ...updatedOptions, // shared common options we defined earlier
  });

  const getTextWidth = useCallback(
    (items, setter) => {
      if (!items) return;

      const array = typeof items === 'function' ? items() : items;

      let newValue = 0;

      array.forEach((item) => {
        let width = context.measureText(item.title).width;
        width = Math.ceil(width) + (item.statusColor || item.icon ? 20 : 0);

        newValue = newValue > width ? newValue : width;
      });

      setter(newValue);
    },
    [context],
  );

  useEffect(() => {
    if (!items) return;
    getTextWidth(items, setTextWidth);
  }, [items, getTextWidth]);

  const close = useCallback(() => {
    resetMousePosition();
    setIsOpen(false);

    if (setCardSelected) {
      setCardSelected(false);
    }
  }, [resetMousePosition, setCardSelected]);

  const openMenu = useCallback(
    (e) => {
      // Resizes ContextTree on window resize
      removeEventListener('resize', updateContextMenuPosition);
      addEventListener('resize', updateContextMenuPosition);

      // Closes ContextTree on topWrapper sroll
      const topWrapper = document.getElementById(parentId);
      if (topWrapper?.scrollTop) {
        const currentTopWrapperScrollCoords = {
          x: topWrapper.scrollLeft,
          y: topWrapper.scrollTop,
        };

        scheduleScrollEvents?.set({
          ...scheduleScrollEvents.state,
          closeContextTreeOnScroll: () => {
            let scrollDistance = {
              x: currentTopWrapperScrollCoords.x - topWrapper.scrollLeft,
              y: currentTopWrapperScrollCoords.y - topWrapper.scrollTop,
            };

            if (scrollDistance.x < 0) {
              scrollDistance.x *= -1;
            }
            if (scrollDistance.y < 0) {
              scrollDistance.y *= -1;
            }

            if (scrollDistance.x > 20 || scrollDistance.y > 20) {
              close();

              if (scheduleScrollEvents?.state?.closeContextTreeOnScroll) {
                delete scheduleScrollEvents.state.closeContextTreeOnScroll;
                scheduleScrollEvents.set(scheduleScrollEvents.state);
              }
            }
          },
        });
      }

      setIsOpen(true);
      setOpenMenuTrigger((prev) => !prev);
      if (setCardSelected) {
        setCardSelected(true);
      }

      handleMouseEvent(e);
    },
    [close, handleMouseEvent, parentId, scheduleScrollEvents, setCardSelected],
  );

  const updateContextMenuPosition = useCallback(() => {
    const offset = 1;

    setPosition((prev) => {
      const containerRect =
        document.getElementById(containerId)?.getBoundingClientRect() || null;

      const contextWrapper = document.querySelector('.layer-' + containerId);
      if (contextWrapper) {
        contextWrapper.style.overflowY = '';
        contextWrapper.style.maxHeight = '';
      }
      let contextWrapperRect = contextWrapper?.getBoundingClientRect() || null;

      const topWrapperRect =
        (parentId &&
          document.getElementById(parentId)?.getBoundingClientRect()) ||
        null;

      const topElementRect =
        (
          document.querySelector('.column-date') ||
          document.querySelector('.column-head')
        )?.getBoundingClientRect() || null;

      // Make context tree scrollable and ajust its height to wrapper
      if (
        topWrapperRect?.height - topElementRect?.height <
        contextWrapperRect?.height
      ) {
        contextWrapper.style.maxHeight =
          topWrapperRect.height - topElementRect.height + 'px';
        contextWrapper.style.overflowY = 'scroll';
      }

      contextWrapperRect = contextWrapper?.getBoundingClientRect() || null;

      const bodyRect = document.body.getBoundingClientRect();

      let supposedRectBoundingRect = {};

      if (containerRect && contextWrapperRect && bodyRect) {
        prev.left = containerRect.right + offset;
        prev.top = containerRect.top;

        supposedRectBoundingRect = {
          left: prev.left,
          right: prev.left + contextWrapperRect.width,
          top: prev.top,
          bottom: prev.top + contextWrapperRect.height,
        };
      }

      // If prefered side is left, or ContextTree overlaps on screen right
      if (
        containerRect &&
        contextWrapperRect &&
        (preferedSide === 'left' ||
          supposedRectBoundingRect?.right > bodyRect?.right)
      ) {
        prev.left = containerRect.left - contextWrapperRect.width - offset;
        supposedRectBoundingRect.left = prev.left;
        supposedRectBoundingRect.right = prev.left + contextWrapperRect.width;
      }

      if (type === 'schedule-body-item') {
        // ContextTree overlaps on schedule right
        if (
          containerRect &&
          contextWrapperRect &&
          topWrapperRect &&
          supposedRectBoundingRect &&
          supposedRectBoundingRect.right > topWrapperRect.right
        ) {
          prev.left = containerRect.left - contextWrapperRect.width - offset;
          supposedRectBoundingRect.left = prev.left;
          supposedRectBoundingRect.right = prev.left + contextWrapperRect.width;
        }

        // ContextTree overlaps on schedule bottom
        if (
          containerRect &&
          bodyRect &&
          contextWrapperRect &&
          supposedRectBoundingRect.bottom > bodyRect.bottom
        ) {
          prev.top =
            containerRect.top -
            (supposedRectBoundingRect.bottom - bodyRect.bottom) -
            offset;

          // if (topWrapperRect?.height < contextWrapperRect?.height) {
          // supposedRectBoundingRect.top = prev.top;
          // }
          supposedRectBoundingRect.bottom =
            prev.top + contextWrapperRect.height;
        }

        // ContextTree overlaps on schedule top
        if (
          topElementRect &&
          contextWrapperRect &&
          topElementRect.bottom > supposedRectBoundingRect.top
        ) {
          prev.top =
            containerRect.top +
            (topElementRect.bottom - supposedRectBoundingRect.top) +
            offset;

          supposedRectBoundingRect.top = prev.top;
          supposedRectBoundingRect.bottom =
            prev.top + contextWrapperRect.height;
        }
      }

      return { ...prev };
    });
  }, []);

  useLayoutEffect(() => {
    if (isOpen) {
      updateContextMenuPosition();
    }
  }, [openMenuTrigger, containerId, isOpen, parentId, preferedSide, type]);

  return (
    <ClickAwayListener
      mouseEvent={items && items().length ? 'mousedown' : 'mouseup'}
      onClickAway={close}
    >
      <S.ContextTree
        onDoubleClick={openOnDoubleClick && openMenu}
        onContextMenu={openOnContext && openMenu}
        onClick={openOnClick && openMenu}
        id="context-tree"
      >
        {hasMousePosition &&
          open &&
          renderLayer(
            createPortal(
              <S.TreeMenu
                override={!overflow}
                hasOverflow={!!overflow && containerId}
                offset={offset}
                top={position?.top}
                left={position?.left}
                textWidth={textWidth}
                fixedWidth={fixedWidth}
                className={`menu layer-${containerId}`}
                preferedSide={preferedSide}
                {...layerProps}
                onClick={(e) => e.stopPropagation()}
              >
                {items
                  ? renderItems(
                      items(resetMousePosition),
                      {
                        state: selectedItem,
                        setState: setSelectedItem,
                        getTextWidth: getTextWidth,
                        fixedWidth,
                      },
                      resetMousePosition,
                    )
                  : content && content}
              </S.TreeMenu>,
              document.getElementById('portal'),
            ),
          )}

        {children}
      </S.ContextTree>
    </ClickAwayListener>
  );
};

const PlainItem = ({ item, selected }) => {
  const { id, title, stayOpen, color, icon, action, isDisabled, statusColor } =
    item;
  const [bold, setBold] = useState();

  return (
    <S.PlainItem
      key={id}
      color={color}
      className="menu-item"
      onClick={(e) => {
        if (isDisabled) return;

        e.stopPropagation();
        selected.setState([]);
        action();
        !stayOpen && close();
      }}
      onMouseEnter={() => !isDisabled && setBold(true)}
      onMouseLeave={() => !isDisabled && setBold(false)}
      disabled={isDisabled}
    >
      {statusColor ? (
        <div
          className="status-color"
          style={{ backgroundColor: statusColor }}
        />
      ) : (
        icon && (
          <div className="item-icon">
            {bold ? cloneElement(icon, { bold: true }) : icon}
          </div>
        )
      )}
      <span>{title}</span>
    </S.PlainItem>
  );
};

export default ContextTree;
