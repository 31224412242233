import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const SectorsManagement = styled.div`
  display: flex;

  @media screen and (max-width: 960px) {
    /* margin-inline: -10px; */
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  float: left;
  gap: 20px;
  height: calc(100vh - 276.5px);
  overflow: hidden;

  @media screen and (max-width: 960px) {
    height: revert;
    overflow: revert;
    width: 100%;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: 959px) {
    &:first-of-type {
      border-right: 1px solid ${colors.table_borders_Primary};
      max-width: 40%;
      min-width: 350px;
      padding-right: 40px;
    }
  }

  &:last-of-type:not(:only-child) {
    flex: 1 1 auto;
    padding-left: 40px;
    transition: width 1s;

    @media screen and (min-width: 959px) {
      overflow-y: auto;
    }
  }
`;
