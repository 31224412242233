import styled from 'styled-components/macro';
import { PageHeader } from 'components/PageHeader/PageHeader.styled';
import { Container, MainLayout } from 'layouts/MainLayout/MainLayout.styled';

export const Settings = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  width: 100%;

  @media screen and (max-width: 960px) {
    height: calc(100vh - 54px);
  }

  > *:not(.footer) {
    flex: 1 0 auto;
  }

  @media screen and (max-width: 960px) {
    ${MainLayout} ${Container} {
      margin: 10px 10px 80px;

      ${PageHeader} > .header {
        margin: 10px;
      }
    }
  }
`;
