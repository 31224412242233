import { useCallback, useEffect, useMemo, useState } from 'react';

// style
import * as S from './SectorsManagement.styled';

// icons
import { ArrowRevert, Check, Pencil } from 'common/icons';

// components
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper';
import { ContentLayout } from 'components/ContentLayout/ContentLayout';
import { DialogLayout } from 'components/DialogLayout/DialogLayout';
import { SectorList } from './partials/SectorList/SectorList';
import { SectorDetails } from './partials/SectorDetails/SectorDetails';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useSectorsGetAll } from 'services/sectorService';

export const SectorsManagement = () => {
  const isMobile = useMediaQuery('(max-width: 960px)');

  const [sectors, setSectors] = useState([]);
  const [activeItem, setActiveItemOriginal] = useState();

  // create wrapped state setter for activeItem (in order to remove temporary
  // items if operation was cancelled - e.g. another item is selected)
  const setActiveItem = useCallback(
    (update) => {
      setActiveItemOriginal((prevState) => {
        // determine the nextState
        const nextState =
          typeof update === 'function' ? update(prevState) : update;

        // if last state was "new" -> cleanup the new item
        if (prevState?.id === 'new' && !nextState?.selected) {
          setSectors((old) =>
            old.filter((sector) => sector.id !== prevState.id),
          );
        }

        if (nextState)
          if (isMobile) {
            if (
              prevState?.id === nextState?.id &&
              prevState?.type === nextState?.type &&
              !prevState?.selected
            ) {
              // console.log('repetitive click');
              nextState.selected = true;
            }
          } else nextState.selected = true;

        // preserve the existing functionality
        return nextState;
      });
    },
    [isMobile],
  );

  const getItem = useMemo(
    () => sectors?.find((sector) => sector.id === activeItem?.id),
    [sectors, activeItem?.id],
  );

  // get sector data from B/E
  const {
    data: db,
    isSuccess,
    isFetched,
  } = useSectorsGetAll({
    // queryParams: { enabled: !sectorDb },
  });

  useEffect(() => {
    isFetched && isSuccess && setSectors(db);
  }, [isSuccess, isFetched, db]);

  const Wrapper = (children) => {
    const presetButtons = [
      {
        id: 'edit',
        icon: <Pencil bold />,
        borderless: true,
        show: false,
      },
      {
        id: 'cancel',
        icon: <ArrowRevert bold />,
        // secondary: true,
        outline: true,
        show: false,
      },
      {
        id: 'save',
        icon: <Check bold />,
        borderless: true,
        show: false,
      },
    ];

    const name = sectors?.find((sector) => sector.id === activeItem.id)?.name;

    return (
      <ContentLayout hideFab>
        <DialogLayout
          headerText={name}
          getBack={() =>
            setActiveItem(
              isMobile
                ? { id: activeItem.id, type: activeItem.type }
                : undefined,
            )
          }
          buttons={presetButtons}
          modal
        >
          {children}
        </DialogLayout>
      </ContentLayout>
    );
  };

  return (
    <S.SectorsManagement>
      <S.Container id="category-container">
        <SectorList
          sectors={sectors}
          setSectors={setSectors}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
        />
      </S.Container>

      {activeItem?.selected && (
        <S.Container id="details-container">
          <ConditionalWrapper condition={isMobile} wrapper={Wrapper}>
            <SectorDetails
              activeItem={activeItem}
              setActiveItem={setActiveItem}
              sector={getItem}
              treeData={sectors}
              updateTree={setSectors}
            />
          </ConditionalWrapper>
        </S.Container>
      )}
    </S.SectorsManagement>
  );
};

export default SectorsManagement;
