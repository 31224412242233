import { useState, useEffect, useRef, useContext } from 'react';

// style
import * as S from './PendingOrderCard.styled';

// components
import ContextTree from 'components/ContextTree/ContextTree';
import Avatar from 'components/Avatar/Avatar';
import Overlay from 'components/Overlay/Overlay';
import AppointmentDetails from 'components/AppointmentCard/AppointmentsDetails/AppointmentDetails';

// contexts
import { UserContext } from 'context/UserContext';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useStatusList } from 'hooks/useStatusList';
import { useObserver } from 'hooks/useObserver';
import useAppointmentCard from 'hooks/schedule/useAppointmentCard';
import useTranslate from 'hooks/useTranslate';
import { getDurationFromMinutes } from 'common/helpers/dateOperations';
import { fillImgPath } from 'common/helpers/imagesFunctions';

const PendingOrderCard = ({
  data,
  drag,
  id,
  index,
  hover,
  allowHover,
  width,
  isDragging,
  appointmentCardRefs,
}) => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const { locale } = useContext(UserContext);
  const currStatus = useStatusList(data.completionStatus.toLowerCase());
  const containerRef = useRef(null);
  let container = useObserver({
    ref: containerRef,
    debounceDelay: 10,
  });
  const [hoverTime, setHoverTime] = useState(hover);
  const [selected, setSelected] = useState(false);
  const [displayContextMenu, setDisplayContextMenu] = useState(false);
  const [displayDetails, setDisplayDetails] = useState(false);
  const [top, setTop] = useState(0);

  useEffect(() => {
    let topPos = 0;

    if (appointmentCardRefs) {
      Object.entries(appointmentCardRefs)?.forEach((item) => {
        const i = item[0];
        const element = item[1];

        if (i !== 'current' && i < index) {
          const elementRect = element.getBoundingClientRect();

          topPos += elementRect.height + 10;
        }
      });
    }

    setTop(topPos);
  }, []);

  const handleClickAway = (e) => {
    if (isMobile) return;

    // functions.raiseHead(false);
    setDisplayContextMenu(false);
    // setFocusing(0);
    // setDraggable(false);
  };

  const {
    getBalanceActions,
    getStatusActions,
    getAppointemtFunctions,
    getFunctionList,
    isModalShowing,
    modalToggle,
    modalContent,
  } = useAppointmentCard({
    isPending: true,
    appointment: data,
    handleClickAway,
    setDisplayDetails,
  });

  useEffect(() => {
    setHoverTime(hover);
  }, [hover]);

  const handleClick = () => {
    if (!isMobile) return;

    setDisplayDetails(true);
  };

  const getTimeStamp = (utc) => {
    const utcDate = new Date(utc);

    return utcDate.toLocaleTimeString(locale, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  };

  // comp
  const TooltipWrapper = (
    <S.Tooltip onMouseDown={(e) => e.stopPropagation()}>
      <AppointmentDetails
        order={data}
        statusItems={getStatusActions}
        functionItems={getFunctionList}
        balanceItems={getBalanceActions}
      />
    </S.Tooltip>
  );

  const handleContextMenu = (e) => {
    e.preventDefault();

    // functions.raiseHead(true);
    setDisplayContextMenu(true);
    // setFocusing(1);
    // setDraggable(false);
  };

  return (
    <S.PendingOrderCard
      id={`pending-${id}`}
      onMouseDown={(e) =>
        !isMobile &&
        e.button !== 2 &&
        drag &&
        drag(
          e,
          index,
          id,
          setHoverTime,
          containerRef.current,
          getDurationFromMinutes(data.durationInMinutes),
          data,
        )
      }
      onClick={handleClick}
      data-index={index}
      ref={(el) => {
        if (el) {
          containerRef.current = el;

          if (appointmentCardRefs) {
            appointmentCardRefs[index] = el;
          }
        }
      }}
      index={index}
      width={width}
      height={container.height}
      color={currStatus?.background}
      selected={selected || isDragging}
      onContextMenu={handleContextMenu}
      top={top}
    >
      <div className="pendingOrder_info">
        <span className="pendingOrder_info-name">{`${
          data.customer?.name || 'Vardenis'
        } ${data.customer?.lastName || 'Pavardenis'}`}</span>

        <span className="pendingOrder_info-duration">
          {tr['duration']}: {getDurationFromMinutes(data.durationInMinutes)}
        </span>

        <span className="pendingOrder_info-timeStamp">
          {getTimeStamp(data.lastUpdateTimeUtc)}
        </span>
      </div>
      <div className="pendingOrder_professionals">
        {data.wantedEmployees?.map((item, i) => (
          <div
            className="pendingOrder_professionals-head"
            key={i}
            data-tooltip-id="tooltip"
            data-tooltip-html={
              item?.firstName || item?.lastName
                ? `${item?.firstName} ${item?.lastName}`
                : `\u2013`
            }
          >
            <Avatar
              imagePath={fillImgPath(item.photoUrl)}
              fullName={item?.firstName + ' ' + item?.lastName}
            />
          </div>
        ))}
      </div>
      {hoverTime && (
        <div className="hover-time">
          <span>{hoverTime}</span>
        </div>
      )}

      {!isMobile && (
        <ContextTree
          key={id}
          parentId={'schedule'}
          containerId={`pending-${id}`}
          openOnContext
          fixedWidth={300}
          content={TooltipWrapper}
          setCardSelected={setSelected}
          type="schedule-body-item"
          open={displayContextMenu}
        />
      )}

      {isMobile && displayDetails && (
        <AppointmentDetails
          close={() => setDisplayDetails(false)}
          functions={getAppointemtFunctions()}
          order={data}
        />
      )}
      <Overlay isVisible={isModalShowing} hide={modalToggle}>
        {modalContent}
      </Overlay>
    </S.PendingOrderCard>
  );
};

export default PendingOrderCard;
