import { useState, useEffect, useCallback } from 'react';

// style
import * as S from './HeaderByType.styled';

// icons
import { Cube, SheetsSVG, SheetSVG } from 'common/icons';

export const HeaderByType = ({ id, type, name, readOnly }) => {
  // Hooks
  // -- state
  // --- local
  const [icon, setIcon] = useState(null);
  const [title, setTitle] = useState('');

  // Functions
  const getIcon = useCallback(() => {
    switch (type) {
      case 'category':
      case 'group':
      case 'subgroup':
        return <SheetsSVG bold />;
      case 'service':
        return <SheetSVG bold />;
      case 'set':
        return <Cube bold />;
      default:
        return;
    }
  }, [type]);

  const getTitle = useCallback(() => {
    return name;
  }, [name]);

  // -- side effect
  useEffect(() => {
    setIcon(getIcon);
    setTitle(getTitle);
  }, [id, name, getTitle, getIcon]);

  return (
    <S.HeaderByType readOnly={readOnly}>
      <div className="headerByType__title-wrapper">
        {icon && <div className="headerByType__icon-container">{icon}</div>}
        <h2>{title}</h2>
      </div>
    </S.HeaderByType>
  );
};

export default HeaderByType;
