import { useCallback, useContext, useEffect, useState } from 'react';

// style
import * as S from './SectorDetails.styled';

// components
import { Loader } from 'components/Loader/Loader';
import { Overlay } from 'components/Overlay/Overlay';
import { GeneralInfo } from 'components/Cards/Services/GeneralInfo/GeneralInfo';
import { SectorDataMinimal } from './partials/SectorDataMinimal/SectorDataMinimal';

// services
import { useSectorsGetAll, useSectorsUpdate } from 'services/sectorService';
import { useSystemGetTimezoneOptions } from 'services/systemService';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useFormState } from 'hooks/useFormState';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { UserContext } from 'context/UserContext';

export const SectorDetails = ({
  sector,
  activeItem,
  setActiveItem,
  updateTree,
  setButtons,
}) => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');

  // identifiers
  const isNew = activeItem.id === 'new';

  const {
    data: timezones,
    isSuccess,
    getTimezoneById,
  } = useSystemGetTimezoneOptions({
    labelFormat: ['displayName'],
  });

  // get sectors list sorted by employee preference
  const { employeeIds } = useContext(UserContext);
  const { data: sectors } = useSectorsGetAll({ searchParams: { employeeIds } });

  // data save handler
  const {
    isSuccess: isMutationSuccess,
    isError: isMutationError,
    error: mutationError,
    isLoading: isMutationLoading,
    data: db,
    mutate,
  } = useSectorsUpdate({
    id: activeItem.id,
    // searchParams: { useVersioning: true },
    // messages: {
    //   error: {
    //     byErrorCode: [
    //       {
    //         code: 'Sectors_SettingsRequired',
    //         // message: tr['sector-settings-is-required'],
    //         message: 'nu va čia overrridinau',
    //       },
    //     ],
    //   },
    // },
    queryParams: { invalidate: ['sectors'] },
  });

  // state
  const [disabled, setDisabled] = useState(!isNew);
  const [changed, setChanged] = useState(false);

  // configure required fields
  const setupFields = useCallback(
    () => [
      // minimal data set:
      {
        id: 'name',
        level: 1,
        type: 'search',
        default: sector?.name,
      },
      {
        id: 'isActive',
        level: 1,
        type: 'switch',
        options: [
          { value: true, label: tr['active'] },
          { value: false, label: tr['inactive'] },
        ],
        default: sector?.isActive
          ? { value: true, label: tr['active'] }
          : { value: false, label: tr['inactive'] },
      },
      {
        id: 'timezoneId',
        level: 1,
        type: 'select',
        options: timezones,
        ...(isNew
          ? sectors?.length && {
              default: getTimezoneById(sectors[0].settings?.timezoneId),
            }
          : timezones &&
            sector?.settings?.timezoneId && {
              default: getTimezoneById(sector.settings.timezoneId),
            }),
      },
      // the rest of settings
      ...(!isNew
        ? [
            {
              id: 'calendarTimeFrom',
              type: 'search',
              default: sector?.calendarTimeFrom,
            },
            {
              id: 'calendarTimeTill',
              type: 'search',
              default: sector?.calendarTimeTill,
            },
            {
              id: 'culture',
              level: 1,
              type: 'select',
              default: sector?.settings?.culture,
            },
            {
              id: 'dataLanguage',
              level: 1,
              type: 'select',
              default: sector?.settings?.dataLanguage,
            },
            {
              id: 'currencySymbol',
              level: 1,
              type: 'select',
              default: sector?.settings?.currencySymbol,
            },
            {
              id: 'firstDayOfWeek',
              level: 1,
              type: 'select',
              default: sector?.settings?.firstDayOfWeek,
            },
            {
              id: 'vatList',
              level: 1,
              type: 'select',
              default: sector?.settings?.vatList,
            },
            {
              id: 'vatDefault',
              level: 1,
              type: 'select',
              default: sector?.settings?.vatDefault,
            },
          ]
        : []),
    ],
    [sector, tr, isNew, timezones, getTimezoneById, sectors],
  );

  // create form state
  const { state, options, resetState, resetOptions } =
    useFormState(setupFields());

  useEffect(() => {
    if (isSuccess) {
      resetState(setupFields());
      resetOptions(setupFields());
    }
  }, [isSuccess, setupFields, resetState, resetOptions, timezones]);

  // checks for changes in state vs original data object
  const checkIfChanged = useCallback(
    (original, current) =>
      Object.entries(original).some((entry) => {
        switch (true) {
          // ignore fields
          case ['id', 'version', 'timezoneIanaId', 'vatList'].includes(
            entry[0],
          ):
            return false;

          // check deeper (children) props
          case ['settings'].includes(entry[0]):
            return checkIfChanged(entry[1], current);

          // check everything else
          default:
            return entry[1] !== current?.[entry[0]]?.value?.value;
        }
      }),
    [],
  );

  // check if there are actual changes in state and set identifier accordingly
  useEffect(() => {
    if (!disabled) {
      setChanged(checkIfChanged(sector, state));
    }
  }, [state, sector, isNew, disabled, activeItem, checkIfChanged]);

  // if mutation was a success, update service
  useEffect(() => {
    if (isMutationSuccess) {
      const updatedObject = db.data.sector;

      // console.log({ updatedObject });

      // update item
      updateTree((old) =>
        old.map((item) => (item.id === 'new' ? updatedObject : item)),
      );

      // close details' viewport if mobile res
      setActiveItem(
        isMobile ? undefined : { id: updatedObject?.id, type: activeItem.type },
      );

      // disable Edit mode and reset change status only if saved to B/E
      setDisabled(true);
      setChanged(false);
    }
  }, [
    isMutationSuccess,
    db,
    isMobile,
    updateTree,
    setActiveItem,
    activeItem.type,
  ]);

  useEffect(() => {
    if (isMutationError) {
      if (mutationError.response.status === 412) {
        setDisabled(true);
        setChanged(false);
      }
      console.log({ mutationError });
    }
  }, [isMutationError, mutationError]);

  const exportObject = useCallback(() => {
    const processedObj = structuredClone(sector);

    processedObj.name = state['name']?.value?.value;
    processedObj.isActive = state['isActive']?.value?.value;
    processedObj.settings = {
      timezoneId: state['timezoneId']?.value?.value,
      ...(!isNew && {
        culture: state['culture']?.value?.value,
        currencySymbol: state['currencySymbol']?.value?.value,
        dataLanguage: state['dataLanguage']?.value?.value,
        firstDayOfWeek: state['firstDayOfWeek']?.value?.value,
        // TODO: vatList and vatDefault update logic
      }),
    };

    if (processedObj.id === 'new') {
      delete processedObj.id;
    }

    return processedObj;
  }, [sector, state, isNew]);

  const confirmChanges = useCallback(() => {
    const processedObject = exportObject();
    processedObject && mutate(processedObject);
  }, [exportObject, mutate]);

  const resetChanges = useCallback(() => {
    resetState(setupFields());
    setDisabled(!isNew);
    setChanged(false);
  }, [resetState, setupFields, isNew]);

  // |- BUTTONS ->

  // 'edit' button show/hide logic
  useEffect(() => {
    if (setButtons) {
      setButtons({
        type: 'update',
        id: 'edit',
        value: { action: () => setDisabled(false) },
      });
      setButtons({
        type: 'update',
        id: 'cancel',
        value: { action: resetChanges },
      });
      setButtons({
        type: 'update',
        id: 'save',
        value: { action: confirmChanges },
      });
    }
  }, [setButtons, state, confirmChanges, resetChanges]);

  // 'edit' button show/hide logic
  useEffect(() => {
    if (setButtons) {
      setButtons({ type: 'show', id: 'edit', value: !isNew && disabled });
    }
  }, [setButtons, disabled, isNew]);

  // 'save'/'cancel' button show/hide logic
  useEffect(() => {
    if (setButtons) {
      setButtons({ type: 'show', id: 'cancel', value: changed });
      setButtons({ type: 'show', id: 'save', value: isNew ? true : changed });
    }
  }, [setButtons, changed, isNew]);

  // <- BUTTONS -|

  // reset state values if another group was selected
  useEffect(() => {
    isNew && resetChanges();
  }, [activeItem, isNew, resetChanges]);

  // logs
  // useEffect(() => console.log({ state, sector }), [state, sector]);

  return (
    <S.SectorDetails>
      <S.Container>
        <GeneralInfo
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          state={state?.['name']}
          disabled={{ state: disabled, setState: setDisabled }}
          changed={changed}
          confirmChanges={confirmChanges}
          resetChanges={resetChanges}
        />

        <SectorDataMinimal
          state={state}
          options={options}
          disabled={disabled}
          isNew={isNew}
        />

        <Overlay isVisible={isMutationLoading}>
          <Loader />
        </Overlay>
      </S.Container>
    </S.SectorDetails>
  );
};
