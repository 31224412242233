import styled, { css } from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const AppointmentCard = styled.div`
  background-color: ${(props) =>
    props.timeOff
      ? colors.bg_grey_2
      : props.statusColor
        ? props.statusColor
        : colors.ordered};
  color: ${colors.bg_Primary};
  transition: background-color 300ms ease-in-out;
  width: 100%;
  min-height: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 1px;
  font-family: ${fontFamilies.family_Secondary};
  display: flex;
  flex-direction: column;
  cursor: ${(props) => props.hoverDisplay && 'pointer'};
  position: relative;
  /* min-width: 20px; */
  overflow: hidden;

  &.warning {
    background-color: yellow;
  }

  &.danger {
    background-color: red;
  }

  @media screen and (min-width: 961px) {
    :hover {
      .service-card-details__wrapper {
        display: block;
      }
    }
  }

  .service-card__content {
    flex: ${({ isOneCellSize }) => (isOneCellSize ? 'none' : 1)};
  }

  .service-card__name-wrapper {
    font-size: 12px;
    line-height: 14px;
    font-weight: 900;
    min-height: 16px;

    display: ${({ isOneCellSize }) => isOneCellSize && 'none'};
  }

  .service-card__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .service-card__cabinet {
    padding: 0 10px;
    background-color: ${colors.bg_Button_Primary};
    width: fit-content;
    border-radius: 8px;
    color: white;
    height: 16px;
    font-size: 12px;
    font-weight: 900;

    float: right;
  }

  .service-card__list-wrapper {
    margin-bottom: 3px;
    display: ${(props) =>
      (props.serviceListHeight < 12 || props.containerHeight < 45) && 'none'};
    height: ${(props) =>
      props.serviceListHeight > 0 ? props.serviceListHeight : 0}px;

    overflow: hidden;

    &.services-visible {
      border-left: 1px solid ${colors.bg_Primary};
    }
  }

  .service-card__list {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 3px;
    margin-bottom: 5px;

    li {
      font-size: 11px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .service-card__phone-wrapper {
    font-size: 11px;
    display: ${(props) => props.containerHeight < 40 && 'none'};
  }

  .service-card__icons-list {
    display: flex;
    gap: 2px;
    flex-wrap: wrap;

    .icon-wrapper {
      z-index: 1;
      height: 15px;
      display: flex;
      align-items: center;

      &.full-name {
        display: ${({ isOneCellSize }) => (isOneCellSize ? 'block' : 'none')};
      }

      :hover {
        scale: 1.2;
      }

      svg {
        width: 12px;
        height: 12px;
        margin-right: 2px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .service-card-details__wrapper {
    display: none;
  }

  ${(props) =>
    props.width < 70 &&
    css`
      .service-card__list-wrapper {
        display: none;
      }
    `}

  ${({ width, isOneCellSize }) =>
    (width < 30 || isOneCellSize) &&
    css`
      .service-card__name-wrapper {
        display: none;
      }
    `}
`;

export const TimeOffContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  font-size: 12px;
  font-weight: 600;

  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    text-align: center;
  }
`;
